import React from 'react'

const SvgComputer = props => (
  <svg viewBox='0 0 150 100' {...props}>
    <path
      d='M16 84.67V15c0-2.94 2.39-5.33 5.33-5.33h108.34c2.94 0 5.33 2.39 5.33 5.33v69.67'
      fill='none'
      stroke='#334966'
      strokeWidth={4}
      strokeLinecap='square'
      strokeLinejoin='bevel'
      strokeMiterlimit={10}
    />
    <path
      d='M133 84.67v-7H17v7H0v3.55c0 .8.65 1.45 1.45 1.45h147.09c.8 0 1.45-.65 1.45-1.45v-3.55H133zm-39 1H58v-4h36v4z'
      fill='#334966'
    />
  </svg>
)

export default SvgComputer
