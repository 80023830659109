import React from 'react'

const SvgBanner = props => (
  <svg
    id='banner_svg__Layer_1'
    x={0}
    y={0}
    viewBox='0 0 440 360'
    xmlSpace='preserve'
    enableBackground='new 0 0 440 360'
    {...props}
  >
    <style>{'.banner_svg__st16{fill:#f3f6ff}.banner_svg__st84{fill:#012860}'}</style>
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter'
        filterUnits='userSpaceOnUse'
        x={92.87}
        y={221}
        width={104.69}
        height={60.46}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={92.87} y={221} width={104.69} height={60.46} id='banner_svg__SVGID_1_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_1_'
            filterUnits='userSpaceOnUse'
            x={92.87}
            y={221}
            width={104.69}
            height={60.46}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_2_'
          gradientUnits='userSpaceOnUse'
          x1={92.867}
          y1={251.23}
          x2={197.555}
          y2={251.23}
        >
          <stop offset={0} stopColor='#fff' />
          <stop offset={0.124} stopColor='#f6f6f6' />
          <stop offset={0.326} stopColor='#ddd' />
          <stop offset={0.582} stopColor='#b6b6b6' />
          <stop offset={0.878} stopColor='#7f7f7f' />
          <stop offset={1} stopColor='#666' />
        </linearGradient>
        <path
          d='M94.81 253.9l45.73 26.43c2.58 1.49 6.78 1.49 9.36 0l45.73-26.43c2.58-1.49 2.58-3.92 0-5.41l-45.69-26.38c-2.59-1.49-6.78-1.49-9.36 0L94.81 248.5c-2.59 1.49-2.59 3.91 0 5.4z'
          mask='url(#banner_svg__SVGID_1_)'
          fill='url(#banner_svg__SVGID_2_)'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_3_'
      gradientUnits='userSpaceOnUse'
      x1={92.867}
      y1={251.23}
      x2={197.555}
      y2={251.23}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={0.517} stopColor='#f2f6ff' />
      <stop offset={1} stopColor='#fff' />
    </linearGradient>
    <path
      d='M94.81 253.9l45.73 26.43c2.58 1.49 6.78 1.49 9.36 0l45.73-26.43c2.58-1.49 2.58-3.92 0-5.41l-45.69-26.38c-2.59-1.49-6.78-1.49-9.36 0L94.81 248.5c-2.59 1.49-2.59 3.91 0 5.4z'
      fill='url(#banner_svg__SVGID_3_)'
    />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_2_'
        filterUnits='userSpaceOnUse'
        x={214.22}
        y={134.36}
        width={161.89}
        height={93.5}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={214.22} y={134.36} width={161.89} height={93.5} id='banner_svg__SVGID_4_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_2_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_3_'
            filterUnits='userSpaceOnUse'
            x={214.22}
            y={134.36}
            width={161.89}
            height={93.5}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_5_'
          gradientUnits='userSpaceOnUse'
          x1={214.22}
          y1={181.111}
          x2={376.114}
          y2={181.111}
        >
          <stop offset={0} stopColor='#666' />
          <stop offset={0.122} stopColor='#7f7f7f' />
          <stop offset={0.418} stopColor='#b6b6b6' />
          <stop offset={0.674} stopColor='#ddd' />
          <stop offset={0.876} stopColor='#f6f6f6' />
          <stop offset={1} stopColor='#fff' />
        </linearGradient>
        <path
          d='M217.22 185.25l70.71 40.88c4 2.31 10.48 2.31 14.48 0l70.71-40.87c4-2.31 4-6.06 0-8.36l-70.65-40.79c-4-2.31-10.48-2.31-14.48 0l-70.76 40.8c-4.01 2.28-4.01 6.03-.01 8.34z'
          mask='url(#banner_svg__SVGID_4_)'
          fill='url(#banner_svg__SVGID_5_)'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_6_'
      gradientUnits='userSpaceOnUse'
      x1={214.22}
      y1={181.111}
      x2={376.114}
      y2={181.111}
    >
      <stop offset={0} stopColor='#fff' />
      <stop offset={0.483} stopColor='#f2f6ff' />
      <stop offset={1} stopColor='#e9efff' />
    </linearGradient>
    <path
      d='M217.22 185.25l70.71 40.88c4 2.31 10.48 2.31 14.48 0l70.71-40.87c4-2.31 4-6.06 0-8.36l-70.65-40.79c-4-2.31-10.48-2.31-14.48 0l-70.76 40.8c-4.01 2.28-4.01 6.03-.01 8.34z'
      fill='url(#banner_svg__SVGID_6_)'
    />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_4_'
        filterUnits='userSpaceOnUse'
        x={110}
        y={142.92}
        width={108.83}
        height={58}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={110} y={142.92} width={108.83} height={58} id='banner_svg__SVGID_7_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_4_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_5_'
            filterUnits='userSpaceOnUse'
            x={110}
            y={142.92}
            width={108.83}
            height={58}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_8_'
          gradientUnits='userSpaceOnUse'
          x1={109.997}
          y1={171.916}
          x2={218.827}
          y2={171.916}
        >
          <stop offset={0} stopColor='#666' />
          <stop offset={0.122} stopColor='#7f7f7f' />
          <stop offset={0.418} stopColor='#b6b6b6' />
          <stop offset={0.674} stopColor='#ddd' />
          <stop offset={0.876} stopColor='#f6f6f6' />
          <stop offset={1} stopColor='#fff' />
        </linearGradient>
        <path
          d='M112.01 174.48l47.53 25.36c2.69 1.43 7.04 1.43 9.73 0l47.53-25.35c2.69-1.43 2.69-3.76 0-5.19L169.31 144c-2.69-1.43-7.05-1.43-9.74 0L112 169.31c-2.67 1.42-2.68 3.74.01 5.17z'
          mask='url(#banner_svg__SVGID_7_)'
          fill='url(#banner_svg__SVGID_8_)'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_9_'
      gradientUnits='userSpaceOnUse'
      x1={109.997}
      y1={171.916}
      x2={218.827}
      y2={171.916}
    >
      <stop offset={0} stopColor='#fff' />
      <stop offset={0.483} stopColor='#f2f6ff' />
      <stop offset={1} stopColor='#e9efff' />
    </linearGradient>
    <path
      d='M112.01 174.48l47.53 25.36c2.69 1.43 7.04 1.43 9.73 0l47.53-25.35c2.69-1.43 2.69-3.76 0-5.19L169.31 144c-2.69-1.43-7.05-1.43-9.74 0L112 169.31c-2.67 1.42-2.68 3.74.01 5.17z'
      fill='url(#banner_svg__SVGID_9_)'
    />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_6_'
        filterUnits='userSpaceOnUse'
        x={230.41}
        y={227.6}
        width={69.7}
        height={40.26}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={230.41} y={227.6} width={69.7} height={40.26} id='banner_svg__SVGID_10_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_6_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_7_'
            filterUnits='userSpaceOnUse'
            x={230.41}
            y={227.6}
            width={69.7}
            height={40.26}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_11_'
          gradientUnits='userSpaceOnUse'
          x1={230.415}
          y1={247.73}
          x2={300.119}
          y2={247.73}
        >
          <stop offset={0} stopColor='#666' />
          <stop offset={0.122} stopColor='#7f7f7f' />
          <stop offset={0.418} stopColor='#b6b6b6' />
          <stop offset={0.674} stopColor='#ddd' />
          <stop offset={0.876} stopColor='#f6f6f6' />
          <stop offset={1} stopColor='#fff' />
        </linearGradient>
        <path
          d='M231.7 249.51l30.45 17.6c1.72.99 4.51.99 6.23 0l30.45-17.6c1.72-.99 1.72-2.61 0-3.6l-30.42-17.56c-1.72-.99-4.51-.99-6.24 0l-30.47 17.56c-1.72.99-1.72 2.61 0 3.6z'
          mask='url(#banner_svg__SVGID_10_)'
          fill='url(#banner_svg__SVGID_11_)'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_12_'
      gradientUnits='userSpaceOnUse'
      x1={230.415}
      y1={247.73}
      x2={300.119}
      y2={247.73}
    >
      <stop offset={0} stopColor='#fff' />
      <stop offset={0.483} stopColor='#f2f6ff' />
      <stop offset={1} stopColor='#e9efff' />
    </linearGradient>
    <path
      d='M231.7 249.51l30.45 17.6c1.72.99 4.51.99 6.23 0l30.45-17.6c1.72-.99 1.72-2.61 0-3.6l-30.42-17.56c-1.72-.99-4.51-.99-6.24 0l-30.47 17.56c-1.72.99-1.72 2.61 0 3.6z'
      fill='url(#banner_svg__SVGID_12_)'
    />
    <path
      className='banner_svg__st16'
      d='M165.69 48.75l-15.47 15.47c-12.25-16-36.98-12.87-55.92 7.35-17.88 19.1-24.35 46.75-15.84 64.4L63 151.44c-.13-.24-.25-.48-.37-.72-.12-.25-.24-.49-.36-.74-.36-.74-.69-1.49-1-2.25-.21-.51-.41-1.02-.6-1.54-.1-.26-.19-.52-.29-.78l-.27-.78c-.13-.36-.25-.72-.36-1.08-.08-.25-.16-.51-.23-.76-.06-.19-.11-.38-.16-.57-.08-.26-.15-.53-.22-.79-.01-.02-.01-.04-.02-.06-.06-.26-.13-.53-.2-.79-.29-1.14-.54-2.3-.76-3.47-.05-.24-.09-.47-.13-.71l-.18-1.07c-.08-.45-.14-.9-.2-1.37-.05-.34-.09-.68-.13-1.02l-.09-.75a69.377 69.377 0 01-.3-3.99c-.01-.09-.01-.19-.01-.28-.02-.46-.04-.91-.05-1.37-.01-.25-.01-.5-.01-.75-.27-22.33 9.66-48.09 27.94-67.61 14.07-15.03 30.38-23.45 45.25-24.85 2.73-.26 5.42-.28 8.03-.06.81.06 1.62.16 2.41.27 1.2.17 2.38.39 3.53.67 1.58.37 3.12.84 4.62 1.41l.03.01.13.05c6.53 2.51 12.24 6.84 16.69 13.06z'
    />
    <path
      d='M82.21 169.49l-.53-.22c-.29-.12-.58-.25-.88-.39l1.41.61z'
      fill='none'
      stroke='#000'
      strokeMiterlimit={10}
    />
    <linearGradient
      id='banner_svg__SVGID_13_'
      gradientUnits='userSpaceOnUse'
      x1={45.512}
      y1={89.81}
      x2={148.875}
      y2={89.81}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M148.88 35.64c-1.5-.57-3.04-1.04-4.62-1.41-1.16-.28-2.33-.5-3.53-.67-.79-.11-1.6-.2-2.41-.27-2.61-.22-5.3-.2-8.03.06-14.87 1.41-31.18 9.82-45.25 24.85-18.28 19.52-28.21 45.29-27.94 67.61 0 .25.01.5.01.75.01.46.03.91.05 1.37 0 .09.01.19.01.28l.06 1.09c.06.97.14 1.94.24 2.9.03.25.05.5.09.75.04.34.08.68.13 1.02.06.46.12.92.2 1.37.05.36.11.71.18 1.07.04.24.08.48.13.71.22 1.17.47 2.33.76 3.47.06.26.13.53.2.79.01.02.01.04.02.06.07.26.15.53.22.79.05.19.11.37.16.57.08.25.15.51.23.76.11.36.23.72.36 1.08.08.26.18.53.27.78.09.26.19.53.29.78.19.52.39 1.03.6 1.54.31.76.64 1.51 1 2.25.11.25.23.5.36.74.12.25.24.48.37.72L49.09 141.2h-.01a53.844 53.844 0 01-2.47-9.01c-4.58-24.47 5.38-56.02 26.84-78.95 14.07-15.03 30.38-23.45 45.25-24.85 3.58-.34 7.08-.27 10.44.21 2.84.4 5.57 1.1 8.18 2.09l.13.05c.36.14.72.29 1.08.44l.37.16 9.98 4.3z'
      fill='url(#banner_svg__SVGID_13_)'
    />
    <linearGradient
      id='banner_svg__SVGID_14_'
      gradientUnits='userSpaceOnUse'
      x1={145.969}
      y1={67.954}
      x2={172.457}
      y2={67.954}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_14_)' d='M172.46 63.01l-16.02 16.02-10.47-6.09 16.41-16.06z' />
    <linearGradient
      id='banner_svg__SVGID_15_'
      gradientUnits='userSpaceOnUse'
      x1={78.272}
      y1={112.193}
      x2={157.13}
      y2={112.193}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M156.57 94.91c-1.23 9.13-4.68 18.66-10.1 27.4a74.23 74.23 0 01-8.87 11.59c-16.03 17.13-36.18 21.99-49.43 13.39l-9.91-5.57c63.39 6.85 67.7-68.78 67.7-68.78l10.47 6.09c.89 4.97.92 10.35.14 15.88z'
      fill='url(#banner_svg__SVGID_15_)'
    />
    <path
      className='banner_svg__st16'
      d='M174 91.55c-1.75 13.05-6.69 26.66-14.43 39.16-3.63 5.84-7.85 11.39-12.68 16.55-15.8 16.88-34.4 25.42-50.64 25.06h-.01c-4.94-.11-9.68-1.05-14.04-2.83l-1.41-.6c-1.62-.74-3.19-1.61-4.7-2.61-.48-.32-.96-.65-1.44-.99-.86-.62-1.69-1.29-2.5-2l16-16c13.24 8.6 33.39 3.74 49.43-13.39 3.38-3.61 6.33-7.49 8.87-11.59 5.42-8.74 8.87-18.27 10.1-27.4.78-5.53.75-10.91-.13-15.88l16.02-16.02c2.51 8.65 2.98 18.43 1.56 28.54z'
    />
    <linearGradient
      id='banner_svg__SVGID_16_'
      gradientUnits='userSpaceOnUse'
      x1={61.876}
      y1={152.504}
      x2={88.177}
      y2={152.504}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path d='M88.18 147.29l-16 16-10.28-6.4-.01-.01 16.4-15.17 9.89 5.58z' fill='url(#banner_svg__SVGID_16_)' />
    <linearGradient
      id='banner_svg__SVGID_17_'
      gradientUnits='userSpaceOnUse'
      x1={61.888}
      y1={164.641}
      x2={96.25}
      y2={164.641}
    >
      <stop offset={0} stopColor='#005eff' />
      <stop offset={1} stopColor='#6aaeff' />
    </linearGradient>
    <path
      d='M96.25 172.32c-19.68 1.19-33.94-14.94-34.36-15.43l10.28 6.4c.81.71 1.64 1.37 2.5 2 .47.34.95.68 1.44.99 1.51 1 3.08 1.87 4.7 2.61.29.13.58.26.88.39l.53.22c4.35 1.77 9.09 2.71 14.03 2.82z'
      fill='url(#banner_svg__SVGID_17_)'
    />
    <linearGradient
      id='banner_svg__SVGID_18_'
      gradientUnits='userSpaceOnUse'
      x1={83.968}
      y1={99.801}
      x2={138.813}
      y2={99.801}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M84.55 126.53c-.05 0-.11-.01-.16-.02a.581.581 0 01-.4-.72l10.1-34.99 19.46 28.08 9.91-45.8 9.8 28.28 4.41-17.81c.08-.31.39-.5.71-.43.31.08.5.39.43.71l-5.35 21.63-9.72-28.07-9.6 44.36-19.61-28.27-9.42 32.63c-.07.25-.3.42-.56.42z'
      fill='url(#banner_svg__SVGID_18_)'
    />
    <linearGradient
      id='banner_svg__SVGID_19_'
      gradientUnits='userSpaceOnUse'
      x1={81.694}
      y1={124.791}
      x2={87.41}
      y2={124.791}
    >
      <stop offset={0} stopColor='#fd8d1f' />
      <stop offset={1} stopColor='#fd5c1f' />
    </linearGradient>
    <path
      d='M86.98 122.19c.38.54.5 1.28.39 2.06-.09.63-.33 1.29-.7 1.9-.18.28-.38.55-.62.8-1.34 1.44-3.1 1.63-3.93.43-.83-1.19-.41-3.32.93-4.76 1.34-1.43 3.11-1.62 3.93-.43z'
      fill='url(#banner_svg__SVGID_19_)'
    />
    <linearGradient
      id='banner_svg__SVGID_20_'
      gradientUnits='userSpaceOnUse'
      x1={91.477}
      y1={92.291}
      x2={97.194}
      y2={92.291}
    >
      <stop offset={0} stopColor='#fd8d1f' />
      <stop offset={1} stopColor='#fd5c1f' />
    </linearGradient>
    <path
      d='M96.77 89.69c.38.54.5 1.28.39 2.06-.09.63-.33 1.29-.7 1.9-.18.28-.38.55-.62.8-1.34 1.44-3.1 1.63-3.93.43-.83-1.19-.41-3.32.93-4.76 1.34-1.43 3.1-1.62 3.93-.43z'
      fill='url(#banner_svg__SVGID_20_)'
    />
    <linearGradient
      id='banner_svg__SVGID_21_'
      gradientUnits='userSpaceOnUse'
      x1={111.009}
      y1={119.586}
      x2={116.725}
      y2={119.586}
    >
      <stop offset={0} stopColor='#fd8d1f' />
      <stop offset={1} stopColor='#fd5c1f' />
    </linearGradient>
    <path
      d='M116.3 116.99c.38.54.5 1.28.39 2.06-.09.63-.33 1.29-.7 1.9-.18.28-.38.55-.62.8-1.34 1.44-3.11 1.63-3.93.43-.83-1.19-.41-3.32.93-4.76 1.34-1.43 3.1-1.63 3.93-.43z'
      fill='url(#banner_svg__SVGID_21_)'
    />
    <linearGradient
      id='banner_svg__SVGID_22_'
      gradientUnits='userSpaceOnUse'
      x1={120.809}
      y1={75.231}
      x2={126.525}
      y2={75.231}
    >
      <stop offset={0} stopColor='#fd8d1f' />
      <stop offset={1} stopColor='#fd5c1f' />
    </linearGradient>
    <path
      d='M126.1 72.63c.38.54.5 1.28.39 2.06-.09.63-.33 1.29-.7 1.9-.18.28-.38.55-.62.8-1.34 1.44-3.1 1.63-3.93.43-.83-1.19-.41-3.32.93-4.76 1.34-1.43 3.1-1.62 3.93-.43z'
      fill='url(#banner_svg__SVGID_22_)'
    />
    <linearGradient
      id='banner_svg__SVGID_23_'
      gradientUnits='userSpaceOnUse'
      x1={135.371}
      y1={83.696}
      x2={141.088}
      y2={83.696}
    >
      <stop offset={0} stopColor='#fd8d1f' />
      <stop offset={1} stopColor='#fd5c1f' />
    </linearGradient>
    <path
      d='M140.66 81.1c.38.54.5 1.28.39 2.06-.09.63-.33 1.29-.7 1.9-.18.28-.38.55-.62.8-1.34 1.44-3.1 1.63-3.93.43-.83-1.19-.41-3.32.93-4.76 1.34-1.43 3.1-1.63 3.93-.43z'
      fill='url(#banner_svg__SVGID_23_)'
    />
    <linearGradient
      id='banner_svg__SVGID_24_'
      gradientUnits='userSpaceOnUse'
      x1={30.379}
      y1={74.207}
      x2={40.145}
      y2={74.207}
    >
      <stop offset={0} stopColor='#fd8d1f' />
      <stop offset={1} stopColor='#fd5c1f' />
    </linearGradient>
    <path
      d='M40.14 69.72v3.76c0 .49-.26.94-.69 1.19L31.6 79.2a.818.818 0 01-1.23-.71v-3.37c0-.52.28-1 .73-1.26l8.1-4.68c.42-.24.94.06.94.54z'
      fill='url(#banner_svg__SVGID_24_)'
    />
    <linearGradient
      id='banner_svg__SVGID_25_'
      gradientUnits='userSpaceOnUse'
      x1={16.963}
      y1={85.955}
      x2={40.729}
      y2={85.955}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path
      d='M17.55 93.07a.586.586 0 01-.3-1.09l22.6-13.06a.586.586 0 01.59 1.01l-22.6 13.06c-.09.05-.19.08-.29.08z'
      fill='url(#banner_svg__SVGID_25_)'
    />
    <linearGradient
      id='banner_svg__SVGID_26_'
      gradientUnits='userSpaceOnUse'
      x1={16.963}
      y1={91.47}
      x2={40.729}
      y2={91.47}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path
      d='M17.55 98.88a.582.582 0 01-.3-1.08l22.6-13.65c.28-.17.63-.08.8.2.17.28.08.63-.2.8L17.85 98.8c-.1.05-.2.08-.3.08z'
      fill='url(#banner_svg__SVGID_26_)'
    />
    <linearGradient
      id='banner_svg__SVGID_27_'
      gradientUnits='userSpaceOnUse'
      x1={185.689}
      y1={85.955}
      x2={195.455}
      y2={85.955}
    >
      <stop offset={0} stopColor='#ff0007' />
      <stop offset={1} stopColor='#ffaaac' />
    </linearGradient>
    <path
      d='M195.45 81.47v3.76c0 .49-.26.94-.69 1.19l-7.85 4.53a.818.818 0 01-1.23-.71v-3.37c0-.52.28-1 .73-1.26l8.1-4.68c.42-.24.94.06.94.54z'
      fill='url(#banner_svg__SVGID_27_)'
    />
    <linearGradient
      id='banner_svg__SVGID_28_'
      gradientUnits='userSpaceOnUse'
      x1={186.13}
      y1={89.335}
      x2={209.895}
      y2={89.335}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path
      d='M186.71 96.45a.586.586 0 01-.3-1.09l22.6-13.06a.586.586 0 01.59 1.01L187 96.37c-.09.05-.19.08-.29.08z'
      fill='url(#banner_svg__SVGID_28_)'
    />
    <linearGradient
      id='banner_svg__SVGID_29_'
      gradientUnits='userSpaceOnUse'
      x1={186.13}
      y1={94.85}
      x2={209.895}
      y2={94.85}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path
      d='M186.71 102.26a.582.582 0 01-.3-1.08l22.6-13.65c.28-.17.63-.08.8.2.17.28.08.63-.2.8l-22.6 13.65a.61.61 0 01-.3.08z'
      fill='url(#banner_svg__SVGID_29_)'
    />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_8_'
        filterUnits='userSpaceOnUse'
        x={149.48}
        y={34.33}
        width={15.31}
        height={16.01}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={149.48} y={34.33} width={15.31} height={16.01} id='banner_svg__SVGID_30_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_8_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_9_'
            filterUnits='userSpaceOnUse'
            x={149.48}
            y={34.33}
            width={15.31}
            height={16.01}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_31_'
          gradientUnits='userSpaceOnUse'
          x1={149.477}
          y1={42.337}
          x2={164.784}
          y2={42.337}
        >
          <stop offset={0.002} />
          <stop offset={0.152} stopColor='#323232' />
          <stop offset={0.386} stopColor='#7a7a7a' />
          <stop offset={0.596} stopColor='#b3b3b3' />
          <stop offset={0.775} stopColor='#dcdcdc' />
          <stop offset={0.915} stopColor='#f6f6f6' />
          <stop offset={1} stopColor='#fff' />
        </linearGradient>
        <path
          d='M164.78 35.31l.01 5.89c0 .77-.41 1.48-1.08 1.87l-12.3 7.1c-.86.5-1.93-.12-1.93-1.11v-5.29c0-.81.43-1.57 1.14-1.98l12.69-7.33c.65-.37 1.47.1 1.47.85z'
          mask='url(#banner_svg__SVGID_30_)'
          fill='url(#banner_svg__SVGID_31_)'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_32_'
      gradientUnits='userSpaceOnUse'
      x1={149.477}
      y1={42.337}
      x2={164.784}
      y2={42.337}
    >
      <stop offset={0.002} stopColor='#e9efff' />
      <stop offset={0.088} stopColor='#dfeaff' />
      <stop offset={0.49} stopColor='#b7d2ff' />
      <stop offset={0.808} stopColor='#9dc4ff' />
      <stop offset={1} stopColor='#94bfff' />
    </linearGradient>
    <path
      d='M164.78 35.31l.01 5.89c0 .77-.41 1.48-1.08 1.87l-12.3 7.1c-.86.5-1.93-.12-1.93-1.11v-5.29c0-.81.43-1.57 1.14-1.98l12.69-7.33c.65-.37 1.47.1 1.47.85z'
      fill='url(#banner_svg__SVGID_32_)'
    />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_10_'
        filterUnits='userSpaceOnUse'
        x={91.77}
        y={163.27}
        width={15.31}
        height={16.01}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={91.77} y={163.27} width={15.31} height={16.01} id='banner_svg__SVGID_33_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_10_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_11_'
            filterUnits='userSpaceOnUse'
            x={91.77}
            y={163.27}
            width={15.31}
            height={16.01}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_34_'
          gradientUnits='userSpaceOnUse'
          x1={91.773}
          y1={171.272}
          x2={107.08}
          y2={171.272}
        >
          <stop offset={0.002} />
          <stop offset={0.152} stopColor='#323232' />
          <stop offset={0.386} stopColor='#7a7a7a' />
          <stop offset={0.596} stopColor='#b3b3b3' />
          <stop offset={0.775} stopColor='#dcdcdc' />
          <stop offset={0.915} stopColor='#f6f6f6' />
          <stop offset={1} stopColor='#fff' />
        </linearGradient>
        <path
          d='M107.07 164.25l.01 5.89c0 .77-.41 1.48-1.08 1.87l-12.3 7.1c-.86.5-1.93-.12-1.93-1.11v-5.29c0-.81.43-1.57 1.14-1.98l12.69-7.33a.977.977 0 011.47.85z'
          mask='url(#banner_svg__SVGID_33_)'
          fill='url(#banner_svg__SVGID_34_)'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_35_'
      gradientUnits='userSpaceOnUse'
      x1={91.773}
      y1={171.272}
      x2={107.08}
      y2={171.272}
    >
      <stop offset={0.002} stopColor='#e9efff' />
      <stop offset={0.088} stopColor='#dfeaff' />
      <stop offset={0.49} stopColor='#b7d2ff' />
      <stop offset={0.808} stopColor='#9dc4ff' />
      <stop offset={1} stopColor='#94bfff' />
    </linearGradient>
    <path
      d='M107.07 164.25l.01 5.89c0 .77-.41 1.48-1.08 1.87l-12.3 7.1c-.86.5-1.93-.12-1.93-1.11v-5.29c0-.81.43-1.57 1.14-1.98l12.69-7.33a.977.977 0 011.47.85z'
      fill='url(#banner_svg__SVGID_35_)'
    />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_12_'
        filterUnits='userSpaceOnUse'
        x={49.09}
        y={98.29}
        width={15.31}
        height={16.01}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={49.09} y={98.29} width={15.31} height={16.01} id='banner_svg__SVGID_36_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_12_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_13_'
            filterUnits='userSpaceOnUse'
            x={49.09}
            y={98.29}
            width={15.31}
            height={16.01}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_37_'
          gradientUnits='userSpaceOnUse'
          x1={49.093}
          y1={106.299}
          x2={64.4}
          y2={106.299}
        >
          <stop offset={0.002} />
          <stop offset={0.152} stopColor='#323232' />
          <stop offset={0.386} stopColor='#7a7a7a' />
          <stop offset={0.596} stopColor='#b3b3b3' />
          <stop offset={0.775} stopColor='#dcdcdc' />
          <stop offset={0.915} stopColor='#f6f6f6' />
          <stop offset={1} stopColor='#fff' />
        </linearGradient>
        <path
          d='M64.39 99.27l.01 5.89c0 .77-.41 1.48-1.08 1.87l-12.3 7.1c-.86.5-1.93-.12-1.93-1.11v-5.29c0-.81.43-1.57 1.14-1.98l12.69-7.33c.66-.37 1.47.1 1.47.85z'
          mask='url(#banner_svg__SVGID_36_)'
          fill='url(#banner_svg__SVGID_37_)'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_38_'
      gradientUnits='userSpaceOnUse'
      x1={49.093}
      y1={106.299}
      x2={64.4}
      y2={106.299}
    >
      <stop offset={0.002} stopColor='#e9efff' />
      <stop offset={0.088} stopColor='#dfeaff' />
      <stop offset={0.49} stopColor='#b7d2ff' />
      <stop offset={0.808} stopColor='#9dc4ff' />
      <stop offset={1} stopColor='#94bfff' />
    </linearGradient>
    <path
      d='M64.39 99.27l.01 5.89c0 .77-.41 1.48-1.08 1.87l-12.3 7.1c-.86.5-1.93-.12-1.93-1.11v-5.29c0-.81.43-1.57 1.14-1.98l12.69-7.33c.66-.37 1.47.1 1.47.85z'
      fill='url(#banner_svg__SVGID_38_)'
    />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_14_'
        filterUnits='userSpaceOnUse'
        x={151.56}
        y={125.95}
        width={15.31}
        height={16.01}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={151.56} y={125.95} width={15.31} height={16.01} id='banner_svg__SVGID_39_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_14_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_15_'
            filterUnits='userSpaceOnUse'
            x={151.56}
            y={125.95}
            width={15.31}
            height={16.01}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_40_'
          gradientUnits='userSpaceOnUse'
          x1={151.559}
          y1={133.952}
          x2={166.866}
          y2={133.952}
        >
          <stop offset={0.002} />
          <stop offset={0.152} stopColor='#323232' />
          <stop offset={0.386} stopColor='#7a7a7a' />
          <stop offset={0.596} stopColor='#b3b3b3' />
          <stop offset={0.775} stopColor='#dcdcdc' />
          <stop offset={0.915} stopColor='#f6f6f6' />
          <stop offset={1} stopColor='#fff' />
        </linearGradient>
        <path
          d='M166.86 126.92l.01 5.89c0 .77-.41 1.48-1.08 1.87l-12.3 7.1c-.86.5-1.93-.12-1.93-1.11v-5.29c0-.81.43-1.57 1.14-1.98l12.69-7.33c.65-.37 1.47.1 1.47.85z'
          mask='url(#banner_svg__SVGID_39_)'
          fill='url(#banner_svg__SVGID_40_)'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_41_'
      gradientUnits='userSpaceOnUse'
      x1={151.559}
      y1={133.952}
      x2={166.866}
      y2={133.952}
    >
      <stop offset={0.002} stopColor='#e9efff' />
      <stop offset={0.088} stopColor='#dfeaff' />
      <stop offset={0.49} stopColor='#b7d2ff' />
      <stop offset={0.808} stopColor='#9dc4ff' />
      <stop offset={1} stopColor='#94bfff' />
    </linearGradient>
    <path
      d='M166.86 126.92l.01 5.89c0 .77-.41 1.48-1.08 1.87l-12.3 7.1c-.86.5-1.93-.12-1.93-1.11v-5.29c0-.81.43-1.57 1.14-1.98l12.69-7.33c.65-.37 1.47.1 1.47.85z'
      fill='url(#banner_svg__SVGID_41_)'
    />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_16_'
        filterUnits='userSpaceOnUse'
        x={93.7}
        y={34.33}
        width={15.31}
        height={16.01}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={93.7} y={34.33} width={15.31} height={16.01} id='banner_svg__SVGID_42_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_16_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_17_'
            filterUnits='userSpaceOnUse'
            x={93.7}
            y={34.33}
            width={15.31}
            height={16.01}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_43_'
          gradientUnits='userSpaceOnUse'
          x1={93.702}
          y1={42.337}
          x2={109.009}
          y2={42.337}
        >
          <stop offset={0.002} />
          <stop offset={0.152} stopColor='#323232' />
          <stop offset={0.386} stopColor='#7a7a7a' />
          <stop offset={0.596} stopColor='#b3b3b3' />
          <stop offset={0.775} stopColor='#dcdcdc' />
          <stop offset={0.915} stopColor='#f6f6f6' />
          <stop offset={1} stopColor='#fff' />
        </linearGradient>
        <path
          d='M109 35.31l.01 5.89c0 .77-.41 1.48-1.08 1.87l-12.3 7.1c-.86.5-1.93-.12-1.93-1.11v-5.29c0-.81.43-1.57 1.14-1.98l12.69-7.33c.66-.37 1.47.1 1.47.85z'
          mask='url(#banner_svg__SVGID_42_)'
          fill='url(#banner_svg__SVGID_43_)'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_44_'
      gradientUnits='userSpaceOnUse'
      x1={93.702}
      y1={42.337}
      x2={109.009}
      y2={42.337}
    >
      <stop offset={0.002} stopColor='#e9efff' />
      <stop offset={0.088} stopColor='#dfeaff' />
      <stop offset={0.49} stopColor='#b7d2ff' />
      <stop offset={0.808} stopColor='#9dc4ff' />
      <stop offset={1} stopColor='#94bfff' />
    </linearGradient>
    <path
      d='M109 35.31l.01 5.89c0 .77-.41 1.48-1.08 1.87l-12.3 7.1c-.86.5-1.93-.12-1.93-1.11v-5.29c0-.81.43-1.57 1.14-1.98l12.69-7.33c.66-.37 1.47.1 1.47.85z'
      fill='url(#banner_svg__SVGID_44_)'
    />
    <linearGradient
      id='banner_svg__SVGID_45_'
      gradientUnits='userSpaceOnUse'
      x1={277.888}
      y1={78.872}
      x2={403.052}
      y2={78.872}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M277.89 2.94c.67-.41 1.49-.57 2.3-.37.28.07.57.18.84.33l1.24.72 113.84 65.74a6.425 6.425 0 013.21 5.57v79.84c0 .51-.1 1-.26 1.43a3.89 3.89 0 01-1.1 1.54l3.13-2.06.02-.01a3.88 3.88 0 001.94-3.38V72.45c0-2.29-1.22-4.42-3.21-5.57L284.75.41a3.07 3.07 0 00-3.43.24l-3.43 2.29z'
      fill='url(#banner_svg__SVGID_45_)'
    />
    <path
      className='banner_svg__st16'
      d='M276.4 86.29c0 2.66 1.42 5.11 3.72 6.44l113.34 65.43c1.54.9 3.32.6 4.51-.42.47-.41.86-.93 1.1-1.54.17-.43.26-.92.26-1.43V74.93c0-2.29-1.22-4.42-3.21-5.57L282.27 3.62l-1.24-.72c-.27-.16-.56-.27-.84-.33-.81-.19-1.63-.04-2.3.37l-.05.04c-.42.27-.77.63-1.02 1.07-.25.44-.4.95-.4 1.51l-.02 80.73z'
    />
    <linearGradient
      id='banner_svg__SVGID_46_'
      gradientUnits='userSpaceOnUse'
      x1={364.616}
      y1={88.807}
      x2={385.861}
      y2={88.807}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path
      d='M364.62 74.15v24.63l21.24 4.69c-.7-7.34-4.19-15.3-10.02-21.52-3.5-3.75-7.38-6.36-11.22-7.8z'
      fill='url(#banner_svg__SVGID_46_)'
    />
    <linearGradient
      id='banner_svg__SVGID_47_'
      gradientUnits='userSpaceOnUse'
      x1={349.945}
      y1={110.438}
      x2={364.616}
      y2={110.438}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M364.62 122.1V98.78l-14.67 11.28a37.1 37.1 0 003.43 4.23c3.5 3.76 7.4 6.37 11.24 7.81z'
      fill='url(#banner_svg__SVGID_47_)'
    />
    <linearGradient
      id='banner_svg__SVGID_48_'
      gradientUnits='userSpaceOnUse'
      x1={343.238}
      y1={86.973}
      x2={364.616}
      y2={86.973}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path
      d='M343.53 94.05c.05.37.11.74.17 1.11l20.92 3.61-14.7-23.61c-1.32.9-2.5 2.07-3.5 3.51-2.82 4.07-3.71 9.58-2.89 15.38z'
      fill='url(#banner_svg__SVGID_48_)'
    />
    <linearGradient
      id='banner_svg__SVGID_49_'
      gradientUnits='userSpaceOnUse'
      x1={349.918}
      y1={85.806}
      x2={364.616}
      y2={85.806}
    >
      <stop offset={0} stopColor='#fd8d1f' />
      <stop offset={1} stopColor='#fd5c1f' />
    </linearGradient>
    <path d='M349.92 75.17l14.7 23.61V74.15c-5.39-2.02-10.69-1.73-14.7 1.02z' fill='url(#banner_svg__SVGID_49_)' />
    <linearGradient
      id='banner_svg__SVGID_50_'
      gradientUnits='userSpaceOnUse'
      x1={364.616}
      y1={111.088}
      x2={385.994}
      y2={111.088}
    >
      <stop offset={0} stopColor='#025' />
      <stop offset={1} stopColor='#003a7f' />
    </linearGradient>
    <path
      d='M364.62 98.78v23.32c7.16 2.68 14.15 1.26 18.19-4.55 2.61-3.76 3.57-8.75 3.06-14.08l-21.25-4.69z'
      fill='url(#banner_svg__SVGID_50_)'
    />
    <linearGradient
      id='banner_svg__SVGID_51_'
      gradientUnits='userSpaceOnUse'
      x1={343.697}
      y1={102.612}
      x2={364.616}
      y2={102.612}
    >
      <stop offset={0} stopColor='#ff0007' />
      <stop offset={1} stopColor='#ffaaac' />
    </linearGradient>
    <path
      d='M343.7 95.16c.75 4.4 2.48 8.93 5.07 13.11.37.61.76 1.2 1.18 1.78l14.67-11.28-20.92-3.61z'
      fill='url(#banner_svg__SVGID_51_)'
    />
    <linearGradient
      id='banner_svg__SVGID_52_'
      gradientUnits='userSpaceOnUse'
      x1={287.566}
      y1={38.786}
      x2={332.273}
      y2={38.786}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M332.27 47.31l-.01 7.96c0 1.35-1.46 2.19-2.63 1.52l-40.48-23.38a3.192 3.192 0 01-1.59-2.75v-8.8c0-1 1.09-1.63 1.95-1.13l41.11 23.74a3.24 3.24 0 011.65 2.84z'
      fill='url(#banner_svg__SVGID_52_)'
    />
    <linearGradient
      id='banner_svg__SVGID_53_'
      gradientUnits='userSpaceOnUse'
      x1={287.555}
      y1={74.009}
      x2={332.262}
      y2={74.009}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path
      d='M332.26 67.73v37.22c0 2.03-2.19 3.31-3.96 2.31l-38.26-21.73a4.912 4.912 0 01-2.48-4.27V42.4c0-1.53 1.66-2.48 2.98-1.72l39.16 22.61c1.58.92 2.56 2.61 2.56 4.44z'
      fill='url(#banner_svg__SVGID_53_)'
    />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_18_'
        filterUnits='userSpaceOnUse'
        x={298.3}
        y={57.88}
        width={23.22}
        height={13.76}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={298.3} y={57.88} width={23.22} height={13.76} id='banner_svg__SVGID_54_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_18_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_19_'
            filterUnits='userSpaceOnUse'
            x={298.3}
            y={57.88}
            width={23.22}
            height={13.76}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_55_'
          gradientUnits='userSpaceOnUse'
          x1={298.305}
          y1={64.76}
          x2={321.524}
          y2={64.76}
        >
          <stop offset={0} stopColor='#e6e6e6' />
          <stop offset={0.528} stopColor='#f6f6f6' />
          <stop offset={1} stopColor='#fff' />
        </linearGradient>
        <path
          d='M321.08 71.64c-.07 0-.15-.02-.22-.06L298.52 58.7a.44.44 0 01.44-.76l22.34 12.89c.21.12.28.39.16.6a.44.44 0 01-.38.21z'
          mask='url(#banner_svg__SVGID_54_)'
          fill='url(#banner_svg__SVGID_55_)'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_56_'
      gradientUnits='userSpaceOnUse'
      x1={298.305}
      y1={64.76}
      x2={321.524}
      y2={64.76}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={0.086} stopColor='#dfeaff' />
      <stop offset={0.489} stopColor='#b7d2ff' />
      <stop offset={0.808} stopColor='#9dc4ff' />
      <stop offset={1} stopColor='#94bfff' />
    </linearGradient>
    <path
      d='M321.08 71.64c-.07 0-.15-.02-.22-.06L298.52 58.7a.44.44 0 01.44-.76l22.34 12.89c.21.12.28.39.16.6a.44.44 0 01-.38.21z'
      fill='url(#banner_svg__SVGID_56_)'
    />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_20_'
        filterUnits='userSpaceOnUse'
        x={292.72}
        y={61.1}
        width={34.4}
        height={20.23}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={292.72} y={61.1} width={34.4} height={20.23} id='banner_svg__SVGID_57_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_20_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_21_'
            filterUnits='userSpaceOnUse'
            x={292.72}
            y={61.1}
            width={34.4}
            height={20.23}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_58_'
          gradientUnits='userSpaceOnUse'
          x1={292.716}
          y1={71.218}
          x2={327.119}
          y2={71.218}
        >
          <stop offset={0} stopColor='#e6e6e6' />
          <stop offset={0.528} stopColor='#f6f6f6' />
          <stop offset={1} stopColor='#fff' />
        </linearGradient>
        <path
          d='M326.68 81.33c-.07 0-.15-.02-.22-.06l-33.53-19.36a.44.44 0 01.44-.76l33.53 19.36c.21.12.28.39.16.6a.42.42 0 01-.38.22z'
          mask='url(#banner_svg__SVGID_57_)'
          fill='url(#banner_svg__SVGID_58_)'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_59_'
      gradientUnits='userSpaceOnUse'
      x1={292.716}
      y1={71.218}
      x2={327.119}
      y2={71.218}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={0.086} stopColor='#dfeaff' />
      <stop offset={0.489} stopColor='#b7d2ff' />
      <stop offset={0.808} stopColor='#9dc4ff' />
      <stop offset={1} stopColor='#94bfff' />
    </linearGradient>
    <path
      d='M326.68 81.33c-.07 0-.15-.02-.22-.06l-33.53-19.36a.44.44 0 01.44-.76l33.53 19.36c.21.12.28.39.16.6a.42.42 0 01-.38.22z'
      fill='url(#banner_svg__SVGID_59_)'
    />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_22_'
        filterUnits='userSpaceOnUse'
        x={292.71}
        y={67.54}
        width={34.41}
        height={20.24}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={292.71} y={67.54} width={34.41} height={20.24} id='banner_svg__SVGID_60_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_22_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_23_'
            filterUnits='userSpaceOnUse'
            x={292.71}
            y={67.54}
            width={34.41}
            height={20.24}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_61_'
          gradientUnits='userSpaceOnUse'
          x1={292.71}
          y1={77.663}
          x2={327.119}
          y2={77.663}
        >
          <stop offset={0} stopColor='#e6e6e6' />
          <stop offset={0.528} stopColor='#f6f6f6' />
          <stop offset={1} stopColor='#fff' />
        </linearGradient>
        <path
          d='M326.68 87.78c-.07 0-.15-.02-.22-.06l-33.53-19.36a.44.44 0 01.44-.76l33.53 19.36a.44.44 0 01-.22.82z'
          mask='url(#banner_svg__SVGID_60_)'
          fill='url(#banner_svg__SVGID_61_)'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_62_'
      gradientUnits='userSpaceOnUse'
      x1={292.71}
      y1={77.663}
      x2={327.119}
      y2={77.663}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={0.086} stopColor='#dfeaff' />
      <stop offset={0.489} stopColor='#b7d2ff' />
      <stop offset={0.808} stopColor='#9dc4ff' />
      <stop offset={1} stopColor='#94bfff' />
    </linearGradient>
    <path
      d='M326.68 87.78c-.07 0-.15-.02-.22-.06l-33.53-19.36a.44.44 0 01.44-.76l33.53 19.36a.44.44 0 01-.22.82z'
      fill='url(#banner_svg__SVGID_62_)'
    />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_24_'
        filterUnits='userSpaceOnUse'
        x={291.96}
        y={73.57}
        width={35.16}
        height={20.67}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={291.96} y={73.57} width={35.16} height={20.67} id='banner_svg__SVGID_63_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_24_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_25_'
            filterUnits='userSpaceOnUse'
            x={291.96}
            y={73.57}
            width={35.16}
            height={20.67}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_64_'
          gradientUnits='userSpaceOnUse'
          x1={291.96}
          y1={83.905}
          x2={327.119}
          y2={83.905}
        >
          <stop offset={0} stopColor='#e6e6e6' />
          <stop offset={0.528} stopColor='#f6f6f6' />
          <stop offset={1} stopColor='#fff' />
        </linearGradient>
        <path
          d='M326.68 94.24c-.07 0-.15-.02-.22-.06l-34.28-19.79a.44.44 0 01.44-.76l34.28 19.79a.44.44 0 01-.22.82z'
          mask='url(#banner_svg__SVGID_63_)'
          fill='url(#banner_svg__SVGID_64_)'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_65_'
      gradientUnits='userSpaceOnUse'
      x1={291.96}
      y1={83.905}
      x2={327.119}
      y2={83.905}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={0.086} stopColor='#dfeaff' />
      <stop offset={0.489} stopColor='#b7d2ff' />
      <stop offset={0.808} stopColor='#9dc4ff' />
      <stop offset={1} stopColor='#94bfff' />
    </linearGradient>
    <path
      d='M326.68 94.24c-.07 0-.15-.02-.22-.06l-34.28-19.79a.44.44 0 01.44-.76l34.28 19.79a.44.44 0 01-.22.82z'
      fill='url(#banner_svg__SVGID_65_)'
    />
    <linearGradient
      id='banner_svg__SVGID_66_'
      gradientUnits='userSpaceOnUse'
      x1={348.385}
      y1={43.925}
      x2={356.418}
      y2={43.925}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_66_)' d='M348.38 30.02V53.2l8.04 4.63-.01-23.18z' />
    <linearGradient
      id='banner_svg__SVGID_67_'
      gradientUnits='userSpaceOnUse'
      x1={366.036}
      y1={47.025}
      x2={374.068}
      y2={47.025}
    >
      <stop offset={0} stopColor='#ff0007' />
      <stop offset={1} stopColor='#ffaaac' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_67_)' d='M366.04 25.97v37.47l8.03 4.64-.01-37.47z' />
    <linearGradient
      id='banner_svg__SVGID_68_'
      gradientUnits='userSpaceOnUse'
      x1={383.702}
      y1={49.605}
      x2={391.735}
      y2={49.605}
    >
      <stop offset={0} stopColor='#fd8d1f' />
      <stop offset={1} stopColor='#fd5c1f' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_68_)' d='M383.7 21.41v51.76l8.03 4.63V26.04z' />
    <path
      className='banner_svg__st16'
      d='M382.79 191.43l1.31-.75-20.66-35.77-20.35 11.75-25.34-32.18-15.03 8.68-9.9-22.84-15.42-8.92-.75 1.31 14.98 8.66 10.38 23.94 15.37-8.87 25.34 32.18 20.17-11.65z'
    />
    <path className='banner_svg__st16' d='M277.78 106.62v11.7l-5.07-8.78z' />
    <linearGradient
      id='banner_svg__SVGID_69_'
      gradientUnits='userSpaceOnUse'
      x1={166.189}
      y1={183.325}
      x2={168.103}
      y2={183.325}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M168.1 190.29l-.62.64-1.3-2.1s.14-1.93.53-4.98c.39-3.05.19-6.39.19-6.39l1.04-1.75c.11 1.1.16 2.29.09 3.8-.18 3.69-.4 5.3-.22 8.26.05.88.16 1.74.29 2.52z'
      fill='url(#banner_svg__SVGID_69_)'
    />
    <path
      className='banner_svg__st84'
      d='M180.48 229.85s.64 1.56.5 3.33-1.54.86-5.34.02c-2.48-.55-4.51-1.13-4.2-1.94.31-.8 5.61-1.57 5.61-1.57l1.68-.18 1.75.34zM175.75 234.53s.65 1.58.5 3.38c-.14 1.8-1.57.87-5.42.02-2.52-.55-4.58-1.15-4.27-1.97.31-.82 5.7-1.6 5.7-1.6l1.71-.18 1.78.35z'
    />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_26_'
        filterUnits='userSpaceOnUse'
        x={174.11}
        y={185.59}
        width={10.41}
        height={44.7}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={174.11} y={185.59} width={10.41} height={44.7} id='banner_svg__SVGID_70_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_26_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_27_'
            filterUnits='userSpaceOnUse'
            x={174.11}
            y={185.59}
            width={10.41}
            height={44.7}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_71_'
          gradientUnits='userSpaceOnUse'
          x1={174.111}
          y1={207.934}
          x2={184.523}
          y2={207.934}
        >
          <stop offset={0} stopColor='#666' />
          <stop offset={0.056} stopColor='#737373' />
          <stop offset={0.284} stopColor='#a5a5a5' />
          <stop offset={0.5} stopColor='#ccc' />
          <stop offset={0.698} stopColor='#e8e8e8' />
          <stop offset={0.87} stopColor='#f9f9f9' />
          <stop offset={0.999} stopColor='#fff' />
        </linearGradient>
        <path
          d='M184.51 187.22s.19 4.03-1.13 5.98c-1.32 1.95-2.76 9.49-2.9 16.94-.14 7.45 0 19.71 0 19.71s-.88.54-1.92.41c-1.04-.13-1.51-.57-1.51-.57l-2.94-40.65 1.21-3.46 9.19 1.64z'
          mask='url(#banner_svg__SVGID_70_)'
          fill='url(#banner_svg__SVGID_71_)'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_72_'
      gradientUnits='userSpaceOnUse'
      x1={174.111}
      y1={207.934}
      x2={184.523}
      y2={207.934}
    >
      <stop offset={0} stopColor='#e9f2ff' />
      <stop offset={0.547} stopColor='#daeaff' />
      <stop offset={0.999} stopColor='#d4e7ff' />
    </linearGradient>
    <path
      d='M184.51 187.22s.19 4.03-1.13 5.98c-1.32 1.95-2.76 9.49-2.9 16.94-.14 7.45 0 19.71 0 19.71s-.88.54-1.92.41c-1.04-.13-1.51-.57-1.51-.57l-2.94-40.65 1.21-3.46 9.19 1.64z'
      fill='url(#banner_svg__SVGID_72_)'
    />
    <path
      className='banner_svg__st16'
      d='M179.84 191.27s.19 4.09-1.15 6.07c-1.34 1.98-2.8 9.63-2.94 17.19s0 20 0 20-.89.54-1.95.42c-1.05-.13-1.53-.58-1.53-.58l-2.98-41.25 1.22-3.51 9.33 1.66z'
    />
    <linearGradient
      id='banner_svg__SVGID_73_'
      gradientUnits='userSpaceOnUse'
      x1={165.847}
      y1={158.622}
      x2={170.885}
      y2={158.622}
    >
      <stop offset={0} stopColor='#ff7f83' />
      <stop offset={1} stopColor='#ffd4d5' />
    </linearGradient>
    <path
      d='M170.18 158.83c-.88.83-1 2.04-1 2.04s-.52.74-.82.7c-.29-.04-2.33-.95-2.33-.95s.61-1 .11-1.6c-.49-.61-.27-1.14-.09-1.51.1-.2.53-.65 1-1.06.6-.51 1.28-.95 1.4-.68.23.47-.88 1.73-.63 1.74h.01c.7 0 1.85-.04 3.04-.34 0-.01.19.83-.69 1.66z'
      fill='url(#banner_svg__SVGID_73_)'
    />
    <linearGradient
      id='banner_svg__SVGID_74_'
      gradientUnits='userSpaceOnUse'
      x1={165.049}
      y1={168.676}
      x2={169.516}
      y2={168.676}
    >
      <stop offset={0} stopColor='#fd8d1f' />
      <stop offset={1} stopColor='#fd5c1f' />
    </linearGradient>
    <path
      d='M166.32 176.85s-1.21-2.35-1.25-8.85c-.04-6.5 0-7.12 0-7.12s1.07-.4 2.2-.36c1.14.04 2.25.47 2.25.47l-.24 4.53-2.96 11.33z'
      fill='url(#banner_svg__SVGID_74_)'
    />
    <linearGradient
      id='banner_svg__SVGID_75_'
      gradientUnits='userSpaceOnUse'
      x1={171.233}
      y1={158.652}
      x2={175.531}
      y2={158.652}
    >
      <stop offset={0} stopColor='#ffd4d5' />
      <stop offset={1} stopColor='#ff7f83' />
    </linearGradient>
    <path
      d='M171.24 157.05s.17 1.91 0 3.1c-.17 1.19 1.78 2.15 1.85 2.1s2.44-2.49 2.44-2.49l-.57-3.59s-.07-.6-.24-.67c-.17-.07-2.58-.47-2.58-.47l-.9 2.02z'
      fill='url(#banner_svg__SVGID_75_)'
    />
    <linearGradient
      id='banner_svg__SVGID_76_'
      gradientUnits='userSpaceOnUse'
      x1={167.015}
      y1={153.262}
      x2={174.718}
      y2={153.262}
    >
      <stop offset={0} stopColor='#ffd4d5' />
      <stop offset={1} stopColor='#ff7f83' />
    </linearGradient>
    <path
      d='M174.34 154.88c-.75 1.28-2.13 1.94-3.48 2.28-1.18.3-2.34.35-3.04.34h-.01c-.25-.01.86-1.27.63-1.74-.13-.27-.8.17-1.4.68-.05-.7-.06-1.68.13-2.69.36-1.9.75-2.88.75-2.88l2.99-1.85s5.23 2.81 3.43 5.86z'
      fill='url(#banner_svg__SVGID_76_)'
    />
    <linearGradient
      id='banner_svg__SVGID_77_'
      gradientUnits='userSpaceOnUse'
      x1={167.27}
      y1={176.003}
      x2={184.535}
      y2={176.003}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path
      d='M184.53 187.5s-.66 2.39-4.48 4.3c-3.83 1.91-11.2 1.73-11.2 1.73s-.43-1.39-.75-3.24c-.13-.78-.25-1.65-.3-2.52-.18-2.96.04-4.57.22-8.26.08-1.5.02-2.7-.09-3.8-.16-1.61-.44-3.01-.63-4.9-.31-3.18 1.39-8.15 2.47-9.45s4.32-2.49 4.32-2.49 2.31-.53 4.16-.38c.75.06 1.37.54 1.83 1.37 0 0 .01.01.02.04.06.11.12.23.17.35 0 .01.01.03.02.05.47 1.09.72 2.65.72 4.59 0 .28 0 .56-.02.85-.12 3 .56 5.86 1.36 8.66.38 1.33.79 2.64 1.15 3.95 1.15 4.07 1.03 9.15 1.03 9.15z'
      fill='url(#banner_svg__SVGID_77_)'
    />
    <linearGradient
      id='banner_svg__SVGID_78_'
      gradientUnits='userSpaceOnUse'
      x1={171.555}
      y1={164.575}
      x2={166.667}
      y2={176.696}
    >
      <stop offset={0} stopColor='#fd8d1f' />
      <stop offset={1} stopColor='#fd5c1f' />
    </linearGradient>
    <path
      d='M172.8 163.62c-.55-1.34-2.24-1.8-3.35-.88-.66.55-1.36 1.51-1.95 3.16-1.66 4.66-2.4 10.61-.59 11.56 3.76 1.97 5.29-6.32 6.09-10.08.36-1.67.14-2.91-.2-3.76z'
      fill='url(#banner_svg__SVGID_78_)'
    />
    <linearGradient
      id='banner_svg__SVGID_79_'
      gradientUnits='userSpaceOnUse'
      x1={180.305}
      y1={167.361}
      x2={183.926}
      y2={167.361}
    >
      <stop offset={0} stopColor='#fd8d1f' />
      <stop offset={1} stopColor='#fd5c1f' />
    </linearGradient>
    <path
      d='M182.36 174.41c-.8-2.8-1.48-5.66-1.36-8.66.09-2.34-.16-4.2-.7-5.45.73 1.53 3.1 6.66 3.57 9.7.27 1.72-.56 3.26-1.51 4.41z'
      fill='url(#banner_svg__SVGID_79_)'
    />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_28_'
        filterUnits='userSpaceOnUse'
        x={175.91}
        y={164.9}
        width={8.63}
        height={26.9}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={175.91} y={164.9} width={8.63} height={26.9} id='banner_svg__SVGID_80_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_28_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_29_'
            filterUnits='userSpaceOnUse'
            x={175.91}
            y={164.9}
            width={8.63}
            height={26.9}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_81_'
          gradientUnits='userSpaceOnUse'
          x1={175.91}
          y1={178.351}
          x2={184.535}
          y2={178.351}
        >
          <stop offset={0} stopColor='#999' />
          <stop offset={0.202} stopColor='#b4b4b4' />
          <stop offset={0.554} stopColor='#dcdcdc' />
          <stop offset={0.832} stopColor='#f6f6f6' />
          <stop offset={1} stopColor='#fff' />
        </linearGradient>
        <path
          d='M184.53 187.5s-.66 2.39-4.48 4.3c3.56-3.34 2.36-9.02-2.22-9.74-4.57-.72.22-4.75 1.89-6.06 1.67-1.3-.09-4.51-2.6-4.6-2.51-.09 3.9-6.5 3.9-6.5 0 .28 0 .56-.02.85-.12 3 .56 5.86 1.36 8.66.38 1.33.79 2.64 1.15 3.95 1.14 4.06 1.02 9.14 1.02 9.14z'
          mask='url(#banner_svg__SVGID_80_)'
          fill='url(#banner_svg__SVGID_81_)'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_82_'
      gradientUnits='userSpaceOnUse'
      x1={175.91}
      y1={178.351}
      x2={184.535}
      y2={178.351}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={0.086} stopColor='#dfeaff' />
      <stop offset={0.489} stopColor='#b7d2ff' />
      <stop offset={0.808} stopColor='#9dc4ff' />
      <stop offset={1} stopColor='#94bfff' />
    </linearGradient>
    <path
      d='M184.53 187.5s-.66 2.39-4.48 4.3c3.56-3.34 2.36-9.02-2.22-9.74-4.57-.72.22-4.75 1.89-6.06 1.67-1.3-.09-4.51-2.6-4.6-2.51-.09 3.9-6.5 3.9-6.5 0 .28 0 .56-.02.85-.12 3 .56 5.86 1.36 8.66.38 1.33.79 2.64 1.15 3.95 1.14 4.06 1.02 9.14 1.02 9.14z'
      fill='url(#banner_svg__SVGID_82_)'
    />
    <path
      className='banner_svg__st84'
      d='M166.55 146.04s-.69 3.14 1.37 4.84c2.06 1.7 2.07 1.94 2.26 2.69.19.75-.2 1.49-.2 1.49s1.55-1.64 1.97-1.11c.42.54-.63 2.12.82 2.45 1.45.33 3.8-.58 4.55-2.19.75-1.61.46-3.13.46-3.13s.19-2.96-1.52-4.18c-1.24-.89-1.51-.15-2.52-.71-.35-.19-.04-1.32-1.34-1.32-.99 0-1.4 1.14-2.54.6-.92-.44.3-1.69-.95-2.26-1.25-.57-2.36 2.83-2.36 2.83z'
    />
    <linearGradient
      id='banner_svg__SVGID_83_'
      gradientUnits='userSpaceOnUse'
      x1={270.092}
      y1={221.136}
      x2={279.632}
      y2={221.136}
    >
      <stop offset={0} stopColor='#025' />
      <stop offset={1} stopColor='#003a7f' />
    </linearGradient>
    <path
      d='M270.5 219.33s-1.46 4.66 1.17 4.42c2.62-.24 8.21-3.25 7.96-4.42-.24-1.17-6.18-.13-6.18-.13l-1.88-.7-1.07.83z'
      fill='url(#banner_svg__SVGID_83_)'
    />
    <linearGradient
      id='banner_svg__SVGID_84_'
      gradientUnits='userSpaceOnUse'
      x1={263.367}
      y1={219.513}
      x2={272.814}
      y2={219.513}
    >
      <stop offset={0} stopColor='#025' />
      <stop offset={1} stopColor='#003a7f' />
    </linearGradient>
    <path
      d='M263.77 217.73s-1.44 4.62 1.15 4.38c2.6-.24 8.13-3.22 7.89-4.38-.24-1.15-6.12-.13-6.12-.13l-1.86-.69-1.06.82z'
      fill='url(#banner_svg__SVGID_84_)'
    />
    <linearGradient
      id='banner_svg__SVGID_85_'
      gradientUnits='userSpaceOnUse'
      x1={267.154}
      y1={203.778}
      x2={273.029}
      y2={203.778}
    >
      <stop offset={0} stopColor='#ff7f83' />
      <stop offset={1} stopColor='#ffd4d5' />
    </linearGradient>
    <path
      d='M267.15 190.56s1.46 5.01 1.29 8c-.17 2.99 2 21.41 2 21.41s.25.42 1.14.36c.89-.06 1.39-.47 1.39-.47s.28-29.32-.44-31.33c-.73-2.01-5.38-1.11-5.38-1.11v3.14z'
      fill='url(#banner_svg__SVGID_85_)'
    />
    <linearGradient
      id='banner_svg__SVGID_86_'
      gradientUnits='userSpaceOnUse'
      x1={260.61}
      y1={202.556}
      x2={266.429}
      y2={202.556}
    >
      <stop offset={0} stopColor='#ff7f83' />
      <stop offset={1} stopColor='#ffd4d5' />
    </linearGradient>
    <path
      d='M260.61 189.46s1.44 4.96 1.28 7.92c-.17 2.96 1.98 21.21 1.98 21.21s.25.41 1.13.36c.88-.06 1.38-.47 1.38-.47s.28-29.04-.44-31.03c-.72-1.99-5.32-1.1-5.32-1.1v3.11z'
      fill='url(#banner_svg__SVGID_86_)'
    />
    <linearGradient
      id='banner_svg__SVGID_87_'
      gradientUnits='userSpaceOnUse'
      x1={258.149}
      y1={181.784}
      x2={276.326}
      y2={181.784}
    >
      <stop offset={0} stopColor='#fd8d1f' />
      <stop offset={1} stopColor='#fd5c1f' />
    </linearGradient>
    <path
      d='M276.33 190.47s-2.09-.96-3.67-.91c-3.46.1-3.09 1.03-6.76 1.21-3.62.18-7.33-2.32-7.33-2.32.02-.47-.92-2.06-.04-5.19.81-2.9.45-3.98.08-5.42.65-.69 1.73-1.39 3.38-1.04 2.95.62 8.23-.95 8.23-.95-1.82.36-3.99.49-6.27-.04-.7-.16-1.32-.33-1.89-.49-1.86-.54-3.02-1.08-3.74-1.51 0 0 .92-.08 2.31-.19 1.72-.14 4.16-.33 6.42-.49 3.06-.22 5.81-.39 6.06-.3.58.2 1.58 1.52 1.58 1.52l1.64 16.12z'
      fill='url(#banner_svg__SVGID_87_)'
    />
    <linearGradient
      id='banner_svg__SVGID_88_'
      gradientUnits='userSpaceOnUse'
      x1={254.844}
      y1={154.228}
      x2={263.317}
      y2={154.228}
    >
      <stop offset={0} stopColor='#d4e3ff' />
      <stop offset={1} stopColor='#e9efff' />
    </linearGradient>
    <path
      d='M258.83 146.4s-4.23 7.79-3.98 11.01c.25 3.21 8.46 4.64 8.46 4.64l-.37-8.29-4.11-7.36z'
      fill='url(#banner_svg__SVGID_88_)'
    />
    <linearGradient
      id='banner_svg__SVGID_89_'
      gradientUnits='userSpaceOnUse'
      x1={264.673}
      y1={136.359}
      x2={272.541}
      y2={136.359}
    >
      <stop offset={0} stopColor='#ff7f83' />
      <stop offset={1} stopColor='#ffd4d5' />
    </linearGradient>
    <path
      d='M270.64 133.86s1.51 2.25 1.15 3.06c-.18.39 1.47 2.61.38 3.36-1.48 1.03-3.75.89-6.14-1.03-2.39-1.92-.89-5.39-.89-5.39l2.47-2.06 3.03 2.06z'
      fill='url(#banner_svg__SVGID_89_)'
    />
    <linearGradient
      id='banner_svg__SVGID_90_'
      gradientUnits='userSpaceOnUse'
      x1={282.738}
      y1={140.915}
      x2={286.781}
      y2={140.915}
    >
      <stop offset={0} stopColor='#ff7f83' />
      <stop offset={1} stopColor='#ffd4d5' />
    </linearGradient>
    <path
      d='M282.74 142.73s2.3-6.27 2.7-5.88-.49 2.4.23 3.23c.71.83 1.66 1.2.73 2.37-.93 1.17-3.46 2.55-3.46 2.55l-.2-2.27z'
      fill='url(#banner_svg__SVGID_90_)'
    />
    <linearGradient
      id='banner_svg__SVGID_91_'
      gradientUnits='userSpaceOnUse'
      x1={257.189}
      y1={159.158}
      x2={284.296}
      y2={159.158}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path
      d='M277.68 158.37c-3.26-1.66-4.49-3.89-4.64-4.18.1.23.73 1.78.39 2.55-.11.25-.27.48-.43.71 0 0-.01.02-.04.05-.02.02-.03.04-.05.06-.01.02-.02.03-.04.05-.33.48-.59 1.02-.34 1.87.24.82.7 1.65 1.17 3.05.32.95.64 2.16.91 3.81.66 4.1.74 7.6.74 7.6s-.24.14-.67.36c-.85.42-2.46 1.12-4.49 1.52-1.82.36-3.99.49-6.27-.04-.7-.16-1.32-.33-1.89-.49-1.86-.54-3.02-1.08-3.74-1.51-.96-.58-1.13-.98-1.13-.98s.05-.49.14-1.29c.28-2.49.91-7.95 1.42-10.31.44-2.03-.07-4.7-.43-7.5-.19-1.49-.35-3.02-.3-4.52.18-5.6 6.92-5.82 6.92-5.82s4.86-.26 7.6 3.41c1.85 2.47 3.54 4.14 4.63 4.57.52.21.91.13 1.1-.29.13-.27.36-.89.66-1.68v-.01c1.11-2.91 3.21-8.17 4.85-7.07 2.12 1.41-2.58 17.86-6.07 16.08z'
      fill='url(#banner_svg__SVGID_91_)'
    />
    <linearGradient
      id='banner_svg__SVGID_92_'
      gradientUnits='userSpaceOnUse'
      x1={268.862}
      y1={168.517}
      x2={273.114}
      y2={168.517}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M268.86 168.88s1.75-.28 2.58-.67 1.67-1.31 1.67-1.31-.4 1.56-1.1 2.35c-.71.79-.92.88-.92.88s.39-1.33-.35-1.23c-.73.1-1.88-.02-1.88-.02z'
      fill='url(#banner_svg__SVGID_92_)'
    />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_30_'
        filterUnits='userSpaceOnUse'
        x={270.04}
        y={157.63}
        width={3.67}
        height={4.93}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={270.04} y={157.63} width={3.67} height={4.93} id='banner_svg__SVGID_93_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_30_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_31_'
            filterUnits='userSpaceOnUse'
            x={270.04}
            y={157.63}
            width={3.67}
            height={4.93}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_94_'
          gradientUnits='userSpaceOnUse'
          x1={270.037}
          y1={160.09}
          x2={273.711}
          y2={160.09}
        >
          <stop offset={0.001} stopColor='#fff' />
          <stop offset={0.13} stopColor='#f9f9f9' />
          <stop offset={0.302} stopColor='#e8e8e8' />
          <stop offset={0.5} stopColor='#ccc' />
          <stop offset={0.716} stopColor='#a5a5a5' />
          <stop offset={0.944} stopColor='#737373' />
          <stop offset={1} stopColor='#666' />
        </linearGradient>
        <path
          d='M273.71 162.55c-.1-.11-1.78-1.96-2.87-2.26-1.13-.31-1.06-.4.14-1 .93-.47 1.63-1.3 1.89-1.66-.33.48-.59 1.02-.34 1.87.25.82.71 1.65 1.18 3.05z'
          mask='url(#banner_svg__SVGID_93_)'
          fill='url(#banner_svg__SVGID_94_)'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_95_'
      gradientUnits='userSpaceOnUse'
      x1={270.037}
      y1={160.09}
      x2={273.711}
      y2={160.09}
    >
      <stop offset={0.001} stopColor='#94b9ff' />
      <stop offset={0.188} stopColor='#9abdff' />
      <stop offset={0.438} stopColor='#abc8ff' />
      <stop offset={0.723} stopColor='#c7dbff' />
      <stop offset={1} stopColor='#e9f2ff' />
    </linearGradient>
    <path
      d='M273.71 162.55c-.1-.11-1.78-1.96-2.87-2.26-1.13-.31-1.06-.4.14-1 .93-.47 1.63-1.3 1.89-1.66-.33.48-.59 1.02-.34 1.87.25.82.71 1.65 1.18 3.05z'
      fill='url(#banner_svg__SVGID_95_)'
    />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_32_'
        filterUnits='userSpaceOnUse'
        x={257.19}
        y={153.7}
        width={6.95}
        height={21.6}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={257.19} y={153.7} width={6.95} height={21.6} id='banner_svg__SVGID_96_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_32_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_33_'
            filterUnits='userSpaceOnUse'
            x={257.19}
            y={153.7}
            width={6.95}
            height={21.6}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_97_'
          gradientUnits='userSpaceOnUse'
          x1={257.189}
          y1={164.504}
          x2={264.144}
          y2={164.504}
        >
          <stop offset={0.001} stopColor='#fff' />
          <stop offset={0.13} stopColor='#f9f9f9' />
          <stop offset={0.302} stopColor='#e8e8e8' />
          <stop offset={0.5} stopColor='#ccc' />
          <stop offset={0.716} stopColor='#a5a5a5' />
          <stop offset={0.944} stopColor='#737373' />
          <stop offset={1} stopColor='#666' />
        </linearGradient>
        <path
          d='M263.32 166.08c-1.53 1.29-3.09 2.81-3.78 4.16-.52 1-.56 1.91.24 2.56.3.25.59.52.86.79.56.57 1.04 1.18 1.42 1.7-1.86-.54-3.02-1.08-3.74-1.51-.96-.58-1.13-.98-1.13-.98s.05-.49.14-1.29c.28-2.49.91-7.95 1.42-10.31.44-2.03-.07-4.7-.43-7.5 0 0 3.87 2.67 2.83 3.92s-1.79 3.69-.75 4.43c1.04.74 5.58 1.79 2.92 4.03z'
          mask='url(#banner_svg__SVGID_96_)'
          fill='url(#banner_svg__SVGID_97_)'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_98_'
      gradientUnits='userSpaceOnUse'
      x1={257.189}
      y1={164.504}
      x2={264.144}
      y2={164.504}
    >
      <stop offset={0.001} stopColor='#94b9ff' />
      <stop offset={0.188} stopColor='#9abdff' />
      <stop offset={0.438} stopColor='#abc8ff' />
      <stop offset={0.723} stopColor='#c7dbff' />
      <stop offset={1} stopColor='#e9f2ff' />
    </linearGradient>
    <path
      d='M263.32 166.08c-1.53 1.29-3.09 2.81-3.78 4.16-.52 1-.56 1.91.24 2.56.3.25.59.52.86.79.56.57 1.04 1.18 1.42 1.7-1.86-.54-3.02-1.08-3.74-1.51-.96-.58-1.13-.98-1.13-.98s.05-.49.14-1.29c.28-2.49.91-7.95 1.42-10.31.44-2.03-.07-4.7-.43-7.5 0 0 3.87 2.67 2.83 3.92s-1.79 3.69-.75 4.43c1.04.74 5.58 1.79 2.92 4.03z'
      fill='url(#banner_svg__SVGID_98_)'
    />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_34_'
        filterUnits='userSpaceOnUse'
        x={277.17}
        y={149.39}
        width={1.76}
        height={2.78}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={277.17} y={149.39} width={1.76} height={2.78} id='banner_svg__SVGID_99_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_34_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_35_'
            filterUnits='userSpaceOnUse'
            x={277.17}
            y={149.39}
            width={1.76}
            height={2.78}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_100_'
          gradientUnits='userSpaceOnUse'
          x1={277.166}
          y1={150.779}
          x2={278.924}
          y2={150.779}
        >
          <stop offset={0} stopColor='#666' />
          <stop offset={0.056} stopColor='#737373' />
          <stop offset={0.284} stopColor='#a5a5a5' />
          <stop offset={0.5} stopColor='#ccc' />
          <stop offset={0.698} stopColor='#e8e8e8' />
          <stop offset={0.87} stopColor='#f9f9f9' />
          <stop offset={0.999} stopColor='#fff' />
        </linearGradient>
        <path
          d='M278.87 151.93c.08.77-1.7-.58-1.7-.58.52.21.91.13 1.1-.29.13-.27.36-.89.66-1.68-.23.6-.15 1.79-.06 2.55z'
          mask='url(#banner_svg__SVGID_99_)'
          fill='url(#banner_svg__SVGID_100_)'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_101_'
      gradientUnits='userSpaceOnUse'
      x1={277.166}
      y1={150.779}
      x2={278.924}
      y2={150.779}
    >
      <stop offset={0} stopColor='#e9f2ff' />
      <stop offset={0.277} stopColor='#c7dbff' />
      <stop offset={0.562} stopColor='#abc8ff' />
      <stop offset={0.812} stopColor='#9abdff' />
      <stop offset={0.999} stopColor='#94b9ff' />
    </linearGradient>
    <path
      d='M278.87 151.93c.08.77-1.7-.58-1.7-.58.52.21.91.13 1.1-.29.13-.27.36-.89.66-1.68-.23.6-.15 1.79-.06 2.55z'
      fill='url(#banner_svg__SVGID_101_)'
    />
    <linearGradient
      id='banner_svg__SVGID_102_'
      gradientUnits='userSpaceOnUse'
      x1={258.275}
      y1={175.817}
      x2={270.212}
      y2={175.817}
    >
      <stop offset={0} stopColor='#fd8d1f' />
      <stop offset={1} stopColor='#fd5c1f' />
    </linearGradient>
    <path
      d='M270.21 175.85s-5.27 1.58-8.23.95c-1.64-.35-2.72.35-3.38 1.04-.03-.11-.06-.22-.08-.33-.39-1.62-.2-3.72-.2-3.72.72.44 1.88.97 3.74 1.51.56.17 1.19.33 1.89.49 2.27.54 4.43.42 6.26.06z'
      fill='url(#banner_svg__SVGID_102_)'
    />
    <path
      className='banner_svg__st84'
      d='M270.64 133.86s-2.1.41-1.15 3.38c.95 2.96 1.91 4.38.45 5.42-1.45 1.04-.75 1.88-.36 2.83.9 2.17 1.24 5.7-3.56 5.32-4.79-.38-8.92-2.91-7.56-6.45.76-1.97 3.84-2.83 2.23-4.28-.84-.75-.85-3 0-5.39 1.1-3.1 2.91-5.17 6.21-4.67 3.31.5 3.74 3.84 3.74 3.84z'
    />
    <linearGradient
      id='banner_svg__SVGID_103_'
      gradientUnits='userSpaceOnUse'
      x1={440.409}
      y1={259.729}
      x2={474.114}
      y2={259.729}
      gradientTransform='matrix(-1 0 0 1 769.59 0)'
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_103_)' d='M329.18 259.72l-16.85 9.73-16.85-9.72 16.85-9.72z' />
    <path className='banner_svg__st16' d='M329.18 259.72v48.65l-16.86 9.72.01-48.64z' />
    <linearGradient
      id='banner_svg__SVGID_104_'
      gradientUnits='userSpaceOnUse'
      x1={457.257}
      y1={288.909}
      x2={474.114}
      y2={288.909}
      gradientTransform='matrix(-1 0 0 1 769.59 0)'
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_104_)' d='M312.32 318.09l-16.84-9.73v-48.63l16.85 9.72z' />
    <linearGradient
      id='banner_svg__SVGID_105_'
      gradientUnits='userSpaceOnUse'
      x1={477.453}
      y1={251.237}
      x2={511.158}
      y2={251.237}
      gradientTransform='matrix(-1 0 0 1 769.59 0)'
    >
      <stop offset={0} stopColor='#fd8d1f' />
      <stop offset={1} stopColor='#fd5c1f' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_105_)' d='M292.14 251.23l-16.85 9.73-16.86-9.72 16.86-9.72z' />
    <path className='banner_svg__st16' d='M292.14 251.23v78.64l-16.86 9.72.01-78.63z' />
    <linearGradient
      id='banner_svg__SVGID_106_'
      gradientUnits='userSpaceOnUse'
      x1={494.3}
      y1={295.414}
      x2={511.158}
      y2={295.414}
      gradientTransform='matrix(-1 0 0 1 769.59 0)'
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_106_)' d='M275.28 339.59l-16.85-9.73v-78.62l16.86 9.72z' />
    <linearGradient
      id='banner_svg__SVGID_107_'
      gradientUnits='userSpaceOnUse'
      x1={514.53}
      y1={241.65}
      x2={548.235}
      y2={241.65}
      gradientTransform='matrix(-1 0 0 1 769.59 0)'
    >
      <stop offset={0} stopColor='#ff0007' />
      <stop offset={1} stopColor='#ffaaac' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_107_)' d='M255.06 241.64l-16.85 9.73-16.85-9.72 16.85-9.72z' />
    <path className='banner_svg__st16' d='M255.06 241.65v108.63L238.2 360l.01-108.63z' />
    <linearGradient
      id='banner_svg__SVGID_108_'
      gradientUnits='userSpaceOnUse'
      x1={531.378}
      y1={300.825}
      x2={548.235}
      y2={300.825}
      gradientTransform='matrix(-1 0 0 1 769.59 0)'
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_108_)' d='M238.2 360l-16.84-9.73V241.65l16.85 9.72z' />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_36_'
        filterUnits='userSpaceOnUse'
        x={191.81}
        y={273.06}
        width={59.18}
        height={69.69}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={191.81} y={273.06} width={59.18} height={69.69} id='banner_svg__SVGID_109_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_36_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_37_'
            filterUnits='userSpaceOnUse'
            x={191.81}
            y={273.06}
            width={59.18}
            height={69.69}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_110_'
          gradientUnits='userSpaceOnUse'
          x1={518.594}
          y1={307.904}
          x2={577.777}
          y2={307.904}
          gradientTransform='matrix(-1 0 0 1 769.59 0)'
        >
          <stop offset={0} stopColor='#fff' />
          <stop offset={0.471} stopColor='#f6f6f6' />
          <stop offset={0.999} stopColor='#e6e6e6' />
        </linearGradient>
        <path
          d='M210.66 342.69c-6.01-.44-10.98-3.26-14.4-8.18-4.16-5.99-5.46-14.4-3.65-23.66 1.8-9.2 6.45-18.18 13.09-25.27 8.01-8.56 17.67-13.1 26.49-12.46 6 .44 10.97 3.26 14.38 8.16 3.76 5.42 5.2 12.97 4.04 21.27-.89 6.59-3.36 13.32-7.17 19.46a53.717 53.717 0 01-6.29 8.22c-7.51 8.03-16.47 12.52-24.82 12.52-.57 0-1.12-.02-1.67-.06zm34.56-60.48c-3.11-4.48-7.66-7.06-13.15-7.46-8.2-.6-17.62 3.87-25.17 11.94-13.25 14.15-17.42 35.18-9.29 46.88 3.12 4.49 7.67 7.08 13.17 7.48 8.2.6 17.6-3.87 25.16-11.94 2.27-2.43 4.33-5.11 6.1-7.96 3.69-5.95 6.08-12.46 6.94-18.82 1.1-7.9-.23-15.04-3.76-20.12l.67-.47-.67.47z'
          mask='url(#banner_svg__SVGID_109_)'
          fill='url(#banner_svg__SVGID_110_)'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_111_'
      gradientUnits='userSpaceOnUse'
      x1={518.594}
      y1={307.904}
      x2={577.777}
      y2={307.904}
      gradientTransform='matrix(-1 0 0 1 769.59 0)'
    >
      <stop offset={0} stopColor='#94bfff' />
      <stop offset={0.192} stopColor='#9dc4ff' />
      <stop offset={0.51} stopColor='#b7d2ff' />
      <stop offset={0.913} stopColor='#dfeaff' />
      <stop offset={0.999} stopColor='#e9efff' />
    </linearGradient>
    <path
      d='M210.66 342.69c-6.01-.44-10.98-3.26-14.4-8.18-4.16-5.99-5.46-14.4-3.65-23.66 1.8-9.2 6.45-18.18 13.09-25.27 8.01-8.56 17.67-13.1 26.49-12.46 6 .44 10.97 3.26 14.38 8.16 3.76 5.42 5.2 12.97 4.04 21.27-.89 6.59-3.36 13.32-7.17 19.46a53.717 53.717 0 01-6.29 8.22c-7.51 8.03-16.47 12.52-24.82 12.52-.57 0-1.12-.02-1.67-.06zm34.56-60.48c-3.11-4.48-7.66-7.06-13.15-7.46-8.2-.6-17.62 3.87-25.17 11.94-13.25 14.15-17.42 35.18-9.29 46.88 3.12 4.49 7.67 7.08 13.17 7.48 8.2.6 17.6-3.87 25.16-11.94 2.27-2.43 4.33-5.11 6.1-7.96 3.69-5.95 6.08-12.46 6.94-18.82 1.1-7.9-.23-15.04-3.76-20.12l.67-.47-.67.47z'
      fill='url(#banner_svg__SVGID_111_)'
    />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_38_'
        filterUnits='userSpaceOnUse'
        x={200.25}
        y={283.02}
        width={42.8}
        height={50.4}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={200.25} y={283.02} width={42.8} height={50.4} id='banner_svg__SVGID_112_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_38_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_39_'
            filterUnits='userSpaceOnUse'
            x={200.25}
            y={283.02}
            width={42.8}
            height={50.4}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_113_'
          gradientUnits='userSpaceOnUse'
          x1={526.537}
          y1={308.219}
          x2={569.341}
          y2={308.219}
          gradientTransform='matrix(-1 0 0 1 769.59 0)'
        >
          <stop offset={0} stopColor='#fff' />
          <stop offset={0.471} stopColor='#f6f6f6' />
          <stop offset={0.999} stopColor='#e6e6e6' />
        </linearGradient>
        <path
          d='M203.44 327.45c2.48 3.56 6.08 5.61 10.44 5.93.4.03.8.04 1.2.04 5.9 0 12.22-3.1 17.56-8.64l-1.16-.71c-5.33 5.42-11.82 8.39-17.5 7.98-3.94-.29-7.2-2.14-9.44-5.36-3.53-5.07-3.83-12.59-1.42-19.99l-1.16-.71c-2.62 7.89-2.31 15.99 1.48 21.46zm25.89-43.06c3.94.29 7.2 2.13 9.42 5.34 1.95 2.81 2.97 6.5 2.97 10.64 0 1.23-.09 2.5-.27 3.8-.4 2.97-1.26 5.97-2.54 8.88l1.15.69c1.37-3.07 2.29-6.25 2.71-9.38.84-6-.2-11.47-2.92-15.39-2.47-3.55-6.07-5.6-10.42-5.91-6.38-.47-13.37 2.82-19.16 9.01-.79.85-1.54 1.73-2.25 2.64l1.15.69c.65-.83 1.34-1.64 2.07-2.42 5.11-5.46 11.4-8.63 17.04-8.63.34 0 .7.02 1.05.04z'
          mask='url(#banner_svg__SVGID_112_)'
          fill='url(#banner_svg__SVGID_113_)'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_114_'
      gradientUnits='userSpaceOnUse'
      x1={526.537}
      y1={308.219}
      x2={569.341}
      y2={308.219}
      gradientTransform='matrix(-1 0 0 1 769.59 0)'
    >
      <stop offset={0} stopColor='#94bfff' />
      <stop offset={0.192} stopColor='#9dc4ff' />
      <stop offset={0.51} stopColor='#b7d2ff' />
      <stop offset={0.913} stopColor='#dfeaff' />
      <stop offset={0.999} stopColor='#e9efff' />
    </linearGradient>
    <path
      d='M203.44 327.45c2.48 3.56 6.08 5.61 10.44 5.93.4.03.8.04 1.2.04 5.9 0 12.22-3.1 17.56-8.64l-1.16-.71c-5.33 5.42-11.82 8.39-17.5 7.98-3.94-.29-7.2-2.14-9.44-5.36-3.53-5.07-3.83-12.59-1.42-19.99l-1.16-.71c-2.62 7.89-2.31 15.99 1.48 21.46zm25.89-43.06c3.94.29 7.2 2.13 9.42 5.34 1.95 2.81 2.97 6.5 2.97 10.64 0 1.23-.09 2.5-.27 3.8-.4 2.97-1.26 5.97-2.54 8.88l1.15.69c1.37-3.07 2.29-6.25 2.71-9.38.84-6-.2-11.47-2.92-15.39-2.47-3.55-6.07-5.6-10.42-5.91-6.38-.47-13.37 2.82-19.16 9.01-.79.85-1.54 1.73-2.25 2.64l1.15.69c.65-.83 1.34-1.64 2.07-2.42 5.11-5.46 11.4-8.63 17.04-8.63.34 0 .7.02 1.05.04z'
      fill='url(#banner_svg__SVGID_114_)'
    />
    <linearGradient
      id='banner_svg__SVGID_115_'
      gradientUnits='userSpaceOnUse'
      x1={540.659}
      y1={309.507}
      x2={552.369}
      y2={309.507}
      gradientTransform='matrix(-1 0 0 1 769.59 0)'
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M228.06 304.18c.77 1.11 1.02 2.62.79 4.21-.17 1.3-.67 2.65-1.44 3.9-.36.58-.78 1.13-1.26 1.65-2.75 2.94-6.36 3.34-8.06.89-1.7-2.44-.85-6.81 1.91-9.75 2.75-2.94 6.36-3.34 8.06-.9z'
      fill='url(#banner_svg__SVGID_115_)'
    />
    <linearGradient
      id='banner_svg__SVGID_116_'
      gradientUnits='userSpaceOnUse'
      x1={547.48}
      y1={306.912}
      x2={554.049}
      y2={306.912}
      gradientTransform='matrix(-1 0 0 1 769.59 0)'
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M221.62 303.93c.43.62.57 1.47.44 2.36-.1.73-.37 1.49-.81 2.19-.2.33-.44.64-.71.92-1.54 1.65-3.57 1.87-4.52.5-.95-1.37-.48-3.82 1.07-5.47s3.58-1.88 4.53-.5z'
      fill='url(#banner_svg__SVGID_116_)'
    />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_40_'
        filterUnits='userSpaceOnUse'
        x={283.26}
        y={286.26}
        width={27.72}
        height={32.64}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={283.26} y={286.26} width={27.72} height={32.64} id='banner_svg__SVGID_117_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_40_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_41_'
            filterUnits='userSpaceOnUse'
            x={283.26}
            y={286.26}
            width={27.72}
            height={32.64}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_118_'
          gradientUnits='userSpaceOnUse'
          x1={458.602}
          y1={302.583}
          x2={486.326}
          y2={302.583}
          gradientTransform='matrix(-1 0 0 1 769.59 0)'
        >
          <stop offset={0} stopColor='#fff' />
          <stop offset={0.168} stopColor='#f6f6f6' />
          <stop offset={0.446} stopColor='#dcdcdc' />
          <stop offset={0.798} stopColor='#b4b4b4' />
          <stop offset={1} stopColor='#999' />
        </linearGradient>
        <path
          d='M292.09 318.88c-2.81-.2-5.15-1.53-6.74-3.83-1.95-2.81-2.56-6.74-1.71-11.08.84-4.31 3.02-8.51 6.13-11.84 3.75-4.01 8.28-6.14 12.41-5.83 2.81.2 5.14 1.53 6.73 3.82 1.76 2.54 2.43 6.08 1.89 9.96-.41 3.09-1.58 6.24-3.36 9.12a25.18 25.18 0 01-2.95 3.85c-3.52 3.76-7.71 5.86-11.63 5.86-.25 0-.51-.01-.77-.03zm16.19-28.33c-1.46-2.1-3.59-3.31-6.16-3.49-3.84-.28-8.25 1.81-11.79 5.59-6.21 6.63-8.16 16.48-4.35 21.96 1.46 2.1 3.59 3.31 6.17 3.5 3.84.28 8.25-1.81 11.79-5.6a23.65 23.65 0 002.86-3.73c1.73-2.79 2.85-5.84 3.25-8.82.51-3.69-.11-7.03-1.77-9.41l.32-.22-.32.22z'
          mask='url(#banner_svg__SVGID_117_)'
          fill='url(#banner_svg__SVGID_118_)'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_119_'
      gradientUnits='userSpaceOnUse'
      x1={458.602}
      y1={302.583}
      x2={486.326}
      y2={302.583}
      gradientTransform='matrix(-1 0 0 1 769.59 0)'
    >
      <stop offset={0} stopColor='#94bfff' />
      <stop offset={0.192} stopColor='#9dc4ff' />
      <stop offset={0.511} stopColor='#b7d2ff' />
      <stop offset={0.914} stopColor='#dfeaff' />
      <stop offset={1} stopColor='#e9efff' />
    </linearGradient>
    <path
      d='M292.09 318.88c-2.81-.2-5.15-1.53-6.74-3.83-1.95-2.81-2.56-6.74-1.71-11.08.84-4.31 3.02-8.51 6.13-11.84 3.75-4.01 8.28-6.14 12.41-5.83 2.81.2 5.14 1.53 6.73 3.82 1.76 2.54 2.43 6.08 1.89 9.96-.41 3.09-1.58 6.24-3.36 9.12a25.18 25.18 0 01-2.95 3.85c-3.52 3.76-7.71 5.86-11.63 5.86-.25 0-.51-.01-.77-.03zm16.19-28.33c-1.46-2.1-3.59-3.31-6.16-3.49-3.84-.28-8.25 1.81-11.79 5.59-6.21 6.63-8.16 16.48-4.35 21.96 1.46 2.1 3.59 3.31 6.17 3.5 3.84.28 8.25-1.81 11.79-5.6a23.65 23.65 0 002.86-3.73c1.73-2.79 2.85-5.84 3.25-8.82.51-3.69-.11-7.03-1.77-9.41l.32-.22-.32.22z'
      fill='url(#banner_svg__SVGID_119_)'
    />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_42_'
        filterUnits='userSpaceOnUse'
        x={287.22}
        y={290.93}
        width={20.05}
        height={23.61}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={287.22} y={290.93} width={20.05} height={23.61} id='banner_svg__SVGID_120_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_42_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_43_'
            filterUnits='userSpaceOnUse'
            x={287.22}
            y={290.93}
            width={20.05}
            height={23.61}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_121_'
          gradientUnits='userSpaceOnUse'
          x1={462.323}
          y1={302.731}
          x2={482.374}
          y2={302.731}
          gradientTransform='matrix(-1 0 0 1 769.59 0)'
        >
          <stop offset={0} stopColor='#fff' />
          <stop offset={0.168} stopColor='#f6f6f6' />
          <stop offset={0.446} stopColor='#dcdcdc' />
          <stop offset={0.798} stopColor='#b4b4b4' />
          <stop offset={1} stopColor='#999' />
        </linearGradient>
        <path
          d='M288.71 311.74c1.16 1.67 2.85 2.63 4.89 2.78.19.01.38.02.56.02 2.76 0 5.72-1.45 8.23-4.05l-.55-.33c-2.5 2.54-5.54 3.93-8.2 3.74-1.84-.13-3.37-1-4.42-2.51-1.65-2.38-1.79-5.9-.66-9.37l-.54-.33c-1.23 3.69-1.09 7.49.69 10.05zm12.13-20.17c1.84.13 3.37 1 4.41 2.5.92 1.32 1.39 3.05 1.39 4.99 0 .58-.04 1.17-.13 1.78-.19 1.39-.59 2.8-1.19 4.16l.54.32c.64-1.44 1.07-2.93 1.27-4.4.39-2.81-.09-5.37-1.37-7.21-1.16-1.66-2.84-2.62-4.88-2.77-2.99-.22-6.26 1.32-8.98 4.22-.37.4-.72.81-1.05 1.24l.54.32c.3-.39.63-.77.97-1.13 2.4-2.56 5.34-4.04 7.98-4.04.17 0 .33.01.5.02z'
          mask='url(#banner_svg__SVGID_120_)'
          fill='url(#banner_svg__SVGID_121_)'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_122_'
      gradientUnits='userSpaceOnUse'
      x1={462.323}
      y1={302.731}
      x2={482.374}
      y2={302.731}
      gradientTransform='matrix(-1 0 0 1 769.59 0)'
    >
      <stop offset={0} stopColor='#94bfff' />
      <stop offset={0.192} stopColor='#9dc4ff' />
      <stop offset={0.511} stopColor='#b7d2ff' />
      <stop offset={0.914} stopColor='#dfeaff' />
      <stop offset={1} stopColor='#e9efff' />
    </linearGradient>
    <path
      d='M288.71 311.74c1.16 1.67 2.85 2.63 4.89 2.78.19.01.38.02.56.02 2.76 0 5.72-1.45 8.23-4.05l-.55-.33c-2.5 2.54-5.54 3.93-8.2 3.74-1.84-.13-3.37-1-4.42-2.51-1.65-2.38-1.79-5.9-.66-9.37l-.54-.33c-1.23 3.69-1.09 7.49.69 10.05zm12.13-20.17c1.84.13 3.37 1 4.41 2.5.92 1.32 1.39 3.05 1.39 4.99 0 .58-.04 1.17-.13 1.78-.19 1.39-.59 2.8-1.19 4.16l.54.32c.64-1.44 1.07-2.93 1.27-4.4.39-2.81-.09-5.37-1.37-7.21-1.16-1.66-2.84-2.62-4.88-2.77-2.99-.22-6.26 1.32-8.98 4.22-.37.4-.72.81-1.05 1.24l.54.32c.3-.39.63-.77.97-1.13 2.4-2.56 5.34-4.04 7.98-4.04.17 0 .33.01.5.02z'
      fill='url(#banner_svg__SVGID_122_)'
    />
    <linearGradient
      id='banner_svg__SVGID_123_'
      gradientUnits='userSpaceOnUse'
      x1={468.938}
      y1={303.334}
      x2={474.424}
      y2={303.334}
      gradientTransform='matrix(-1 0 0 1 769.59 0)'
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M300.24 300.84c.36.52.48 1.23.37 1.97-.08.61-.31 1.24-.67 1.82-.17.27-.37.53-.59.77-1.29 1.38-2.98 1.56-3.78.42-.8-1.14-.4-3.19.89-4.57 1.3-1.37 2.99-1.55 3.78-.41z'
      fill='url(#banner_svg__SVGID_123_)'
    />
    <linearGradient
      id='banner_svg__SVGID_124_'
      gradientUnits='userSpaceOnUse'
      x1={472.134}
      y1={302.119}
      x2={475.211}
      y2={302.119}
      gradientTransform='matrix(-1 0 0 1 769.59 0)'
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M297.23 300.72c.2.29.27.69.21 1.11-.05.34-.17.7-.38 1.02-.09.15-.21.3-.33.43-.72.77-1.67.88-2.12.23-.45-.64-.22-1.79.5-2.56s1.67-.87 2.12-.23z'
      fill='url(#banner_svg__SVGID_124_)'
    />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_44_'
        filterUnits='userSpaceOnUse'
        x={323.64}
        y={270.43}
        width={17.4}
        height={20.49}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={323.64} y={270.43} width={17.4} height={20.49} id='banner_svg__SVGID_125_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_44_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_45_'
            filterUnits='userSpaceOnUse'
            x={323.64}
            y={270.43}
            width={17.4}
            height={20.49}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_126_'
          gradientUnits='userSpaceOnUse'
          x1={428.547}
          y1={280.68}
          x2={445.95}
          y2={280.68}
          gradientTransform='matrix(-1 0 0 1 769.59 0)'
        >
          <stop offset={0} stopColor='#fff' />
          <stop offset={0.168} stopColor='#f6f6f6' />
          <stop offset={0.446} stopColor='#dcdcdc' />
          <stop offset={0.798} stopColor='#b4b4b4' />
          <stop offset={1} stopColor='#999' />
        </linearGradient>
        <path
          d='M329.18 290.91c-1.77-.13-3.23-.96-4.23-2.4-1.22-1.76-1.61-4.23-1.07-6.96.53-2.71 1.9-5.34 3.85-7.43 2.36-2.52 5.19-3.85 7.79-3.66 1.76.13 3.23.96 4.23 2.4 1.11 1.59 1.53 3.81 1.19 6.25-.26 1.94-.99 3.92-2.11 5.72-.54.87-1.16 1.68-1.85 2.42-2.21 2.36-4.84 3.68-7.3 3.68-.17 0-.34-.01-.5-.02zm10.17-17.78c-.91-1.32-2.25-2.07-3.87-2.19-2.41-.18-5.18 1.14-7.4 3.51-3.9 4.16-5.12 10.34-2.73 13.78.92 1.32 2.26 2.08 3.87 2.2 2.41.18 5.18-1.14 7.4-3.51.67-.71 1.27-1.5 1.79-2.34 1.08-1.75 1.79-3.66 2.04-5.53.32-2.33-.07-4.43-1.1-5.92l.2-.14-.2.14z'
          mask='url(#banner_svg__SVGID_125_)'
          fill='url(#banner_svg__SVGID_126_)'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_127_'
      gradientUnits='userSpaceOnUse'
      x1={428.547}
      y1={280.68}
      x2={445.95}
      y2={280.68}
      gradientTransform='matrix(-1 0 0 1 769.59 0)'
    >
      <stop offset={0} stopColor='#94bfff' />
      <stop offset={0.192} stopColor='#9dc4ff' />
      <stop offset={0.511} stopColor='#b7d2ff' />
      <stop offset={0.914} stopColor='#dfeaff' />
      <stop offset={1} stopColor='#e9efff' />
    </linearGradient>
    <path
      d='M329.18 290.91c-1.77-.13-3.23-.96-4.23-2.4-1.22-1.76-1.61-4.23-1.07-6.96.53-2.71 1.9-5.34 3.85-7.43 2.36-2.52 5.19-3.85 7.79-3.66 1.76.13 3.23.96 4.23 2.4 1.11 1.59 1.53 3.81 1.19 6.25-.26 1.94-.99 3.92-2.11 5.72-.54.87-1.16 1.68-1.85 2.42-2.21 2.36-4.84 3.68-7.3 3.68-.17 0-.34-.01-.5-.02zm10.17-17.78c-.91-1.32-2.25-2.07-3.87-2.19-2.41-.18-5.18 1.14-7.4 3.51-3.9 4.16-5.12 10.34-2.73 13.78.92 1.32 2.26 2.08 3.87 2.2 2.41.18 5.18-1.14 7.4-3.51.67-.71 1.27-1.5 1.79-2.34 1.08-1.75 1.79-3.66 2.04-5.53.32-2.33-.07-4.43-1.1-5.92l.2-.14-.2.14z'
      fill='url(#banner_svg__SVGID_127_)'
    />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_46_'
        filterUnits='userSpaceOnUse'
        x={326.12}
        y={273.36}
        width={12.59}
        height={14.82}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={326.12} y={273.36} width={12.59} height={14.82} id='banner_svg__SVGID_128_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_46_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_47_'
            filterUnits='userSpaceOnUse'
            x={326.12}
            y={273.36}
            width={12.59}
            height={14.82}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_129_'
          gradientUnits='userSpaceOnUse'
          x1={430.883}
          y1={280.773}
          x2={443.469}
          y2={280.773}
          gradientTransform='matrix(-1 0 0 1 769.59 0)'
        >
          <stop offset={0} stopColor='#fff' />
          <stop offset={0.168} stopColor='#f6f6f6' />
          <stop offset={0.446} stopColor='#dcdcdc' />
          <stop offset={0.798} stopColor='#b4b4b4' />
          <stop offset={1} stopColor='#999' />
        </linearGradient>
        <path
          d='M327.06 286.43c.73 1.05 1.79 1.65 3.07 1.74.12.01.24.01.35.01 1.74 0 3.59-.91 5.16-2.54l-.34-.21c-1.57 1.59-3.48 2.47-5.15 2.35-1.16-.08-2.12-.63-2.77-1.58-1.04-1.49-1.13-3.7-.42-5.88l-.34-.21c-.77 2.33-.68 4.71.44 6.32zm7.61-12.66c1.16.08 2.12.63 2.77 1.57.57.83.87 1.91.87 3.13 0 .36-.03.74-.08 1.12-.12.87-.37 1.75-.75 2.61l.34.2c.4-.9.67-1.84.8-2.76.25-1.76-.06-3.37-.86-4.53-.73-1.04-1.79-1.65-3.07-1.74-1.88-.14-3.93.83-5.64 2.65-.23.25-.45.51-.66.78l.34.2c.19-.24.39-.48.61-.71 1.5-1.61 3.35-2.54 5.01-2.54.11 0 .22.01.32.02z'
          mask='url(#banner_svg__SVGID_128_)'
          fill='url(#banner_svg__SVGID_129_)'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_130_'
      gradientUnits='userSpaceOnUse'
      x1={430.883}
      y1={280.773}
      x2={443.469}
      y2={280.773}
      gradientTransform='matrix(-1 0 0 1 769.59 0)'
    >
      <stop offset={0} stopColor='#94bfff' />
      <stop offset={0.192} stopColor='#9dc4ff' />
      <stop offset={0.511} stopColor='#b7d2ff' />
      <stop offset={0.914} stopColor='#dfeaff' />
      <stop offset={1} stopColor='#e9efff' />
    </linearGradient>
    <path
      d='M327.06 286.43c.73 1.05 1.79 1.65 3.07 1.74.12.01.24.01.35.01 1.74 0 3.59-.91 5.16-2.54l-.34-.21c-1.57 1.59-3.48 2.47-5.15 2.35-1.16-.08-2.12-.63-2.77-1.58-1.04-1.49-1.13-3.7-.42-5.88l-.34-.21c-.77 2.33-.68 4.71.44 6.32zm7.61-12.66c1.16.08 2.12.63 2.77 1.57.57.83.87 1.91.87 3.13 0 .36-.03.74-.08 1.12-.12.87-.37 1.75-.75 2.61l.34.2c.4-.9.67-1.84.8-2.76.25-1.76-.06-3.37-.86-4.53-.73-1.04-1.79-1.65-3.07-1.74-1.88-.14-3.93.83-5.64 2.65-.23.25-.45.51-.66.78l.34.2c.19-.24.39-.48.61-.71 1.5-1.61 3.35-2.54 5.01-2.54.11 0 .22.01.32.02z'
      fill='url(#banner_svg__SVGID_130_)'
    />
    <linearGradient
      id='banner_svg__SVGID_131_'
      gradientUnits='userSpaceOnUse'
      x1={435.035}
      y1={281.151}
      x2={438.479}
      y2={281.151}
      gradientTransform='matrix(-1 0 0 1 769.59 0)'
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M334.3 279.59c.23.33.3.77.23 1.24-.05.38-.2.78-.42 1.15-.11.17-.23.33-.37.48-.81.86-1.87.98-2.37.26s-.25-2 .56-2.87c.81-.86 1.87-.98 2.37-.26z'
      fill='url(#banner_svg__SVGID_131_)'
    />
    <linearGradient
      id='banner_svg__SVGID_132_'
      gradientUnits='userSpaceOnUse'
      x1={437.041}
      y1={280.388}
      x2={438.973}
      y2={280.388}
      gradientTransform='matrix(-1 0 0 1 769.59 0)'
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M332.41 279.51c.13.18.17.43.13.69-.03.21-.11.44-.24.64-.06.1-.13.19-.21.27-.45.49-1.05.55-1.33.15s-.14-1.12.31-1.61c.46-.48 1.06-.54 1.34-.14z'
      fill='url(#banner_svg__SVGID_132_)'
    />
    <linearGradient
      id='banner_svg__SVGID_133_'
      gradientUnits='userSpaceOnUse'
      x1={741.884}
      y1={241.218}
      x2={783.21}
      y2={241.218}
      gradientTransform='translate(-688.481)'
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_133_)' d='M94.73 237.26L81 229.33 53.4 245.1l13.87 8.01z' />
    <linearGradient
      id='banner_svg__SVGID_134_'
      gradientUnits='userSpaceOnUse'
      x1={746.593}
      y1={259.713}
      x2={806.812}
      y2={259.713}
      gradientTransform='translate(-688.481)'
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_134_)' d='M103.88 242.54l14.45 8.34-46.49 26.01-13.73-7.93z' />
    <linearGradient
      id='banner_svg__SVGID_135_'
      gradientUnits='userSpaceOnUse'
      x1={728.288}
      y1={225.325}
      x2={760.326}
      y2={225.325}
      gradientTransform='translate(-688.481)'
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_135_)' d='M71.85 224.04L53.4 234.53l-13.59-7.85 18.31-10.56z' />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_48_'
        filterUnits='userSpaceOnUse'
        x={84.19}
        y={332.19}
        width={73.23}
        height={25.81}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={84.19} y={332.19} width={73.23} height={25.81} id='banner_svg__SVGID_136_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_48_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_49_'
            filterUnits='userSpaceOnUse'
            x={84.19}
            y={332.19}
            width={73.23}
            height={25.81}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_137_'
          gradientUnits='userSpaceOnUse'
          x1={84.194}
          y1={345.093}
          x2={157.425}
          y2={345.093}
        >
          <stop offset={0} stopColor='#666' />
          <stop offset={0.056} stopColor='#737373' />
          <stop offset={0.284} stopColor='#a5a5a5' />
          <stop offset={0.5} stopColor='#ccc' />
          <stop offset={0.698} stopColor='#e8e8e8' />
          <stop offset={0.87} stopColor='#f9f9f9' />
          <stop offset={0.999} stopColor='#fff' />
        </linearGradient>
        <path
          d='M157.43 335.98c0 1.77-.34 3.49-1.01 5.15-.46 1.15-1.07 2.27-1.81 3.34-3.85 5.54-11.35 9.93-20.7 12.08-4.07.94-8.48 1.45-13.1 1.45-11.03 0-20.92-2.93-27.63-7.58-3.8-2.63-6.58-5.8-7.97-9.29-.66-1.65-1-3.38-1-5.15 0-1.29.18-2.56.54-3.8 2.99 10.35 18 18.22 36.07 18.22 18.06 0 33.08-7.87 36.08-18.22.34 1.24.53 2.51.53 3.8z'
          mask='url(#banner_svg__SVGID_136_)'
          fill='url(#banner_svg__SVGID_137_)'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_138_'
      gradientUnits='userSpaceOnUse'
      x1={84.194}
      y1={345.093}
      x2={157.425}
      y2={345.093}
    >
      <stop offset={0} stopColor='#e9f2ff' />
      <stop offset={0.999} stopColor='#e9f1ff' />
    </linearGradient>
    <path
      d='M157.43 335.98c0 1.77-.34 3.49-1.01 5.15-.46 1.15-1.07 2.27-1.81 3.34-3.85 5.54-11.35 9.93-20.7 12.08-4.07.94-8.48 1.45-13.1 1.45-11.03 0-20.92-2.93-27.63-7.58-3.8-2.63-6.58-5.8-7.97-9.29-.66-1.65-1-3.38-1-5.15 0-1.29.18-2.56.54-3.8 2.99 10.35 18 18.22 36.07 18.22 18.06 0 33.08-7.87 36.08-18.22.34 1.24.53 2.51.53 3.8z'
      fill='url(#banner_svg__SVGID_138_)'
    />
    <path
      d='M157.43 318.11v10.28c0 1.29-.18 2.56-.54 3.8-2.99 10.35-18.01 18.22-36.08 18.22-18.07 0-33.08-7.87-36.07-18.22-.36-1.23-.54-2.5-.54-3.8v-10.28c0 1.77.34 3.49 1 5.14.53 1.32 1.24 2.59 2.14 3.8 5.7 7.71 18.54 13.07 33.46 13.07 14.92 0 27.77-5.37 33.47-13.08.9-1.21 1.61-2.48 2.14-3.8a13.49 13.49 0 001.02-5.13z'
      fill='#335089'
    />
    <linearGradient
      id='banner_svg__SVGID_139_'
      gradientUnits='userSpaceOnUse'
      x1={84.192}
      y1={318.109}
      x2={157.423}
      y2={318.109}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path
      d='M157.42 318.11c0 1.77-.34 3.49-1.01 5.15-.46 1.15-1.07 2.27-1.81 3.34-3.85 5.54-11.35 9.93-20.7 12.08-4.07.94-8.48 1.45-13.1 1.45-11.03 0-20.92-2.93-27.63-7.58-3.8-2.63-6.58-5.8-7.97-9.29-.66-1.65-1-3.38-1-5.15 0-3.54 1.39-6.89 3.87-9.86 6.01-7.21 18.42-12.15 32.74-12.15 9.37 0 17.93 2.12 24.41 5.6 7.49 4.03 12.2 9.89 12.2 16.41z'
      fill='url(#banner_svg__SVGID_139_)'
    />
    <linearGradient
      id='banner_svg__SVGID_140_'
      gradientUnits='userSpaceOnUse'
      x1={71.84}
      y1={314.321}
      x2={132.86}
      y2={314.321}
    >
      <stop offset={0} stopColor='#ff0007' />
      <stop offset={1} stopColor='#ffaaac' />
    </linearGradient>
    <path
      d='M145.21 301.7l-25.27 14.98-26.77 15.87c-3.8-2.63-6.58-5.8-7.97-9.29-.66-1.65-1-3.38-1-5.15 0-3.54 1.39-6.89 3.87-9.86 6.01-7.21 18.42-12.15 32.74-12.15 9.37 0 17.92 2.11 24.4 5.6z'
      fill='url(#banner_svg__SVGID_140_)'
    />
    <linearGradient
      id='banner_svg__SVGID_141_'
      gradientUnits='userSpaceOnUse'
      x1={119.938}
      y1={314.145}
      x2={157.423}
      y2={314.145}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path
      d='M157.42 318.11c0 1.77-.34 3.49-1.01 5.15-.46 1.15-1.07 2.27-1.81 3.34l-34.67-9.91 25.27-14.98c7.51 4.02 12.22 9.88 12.22 16.4z'
      fill='url(#banner_svg__SVGID_141_)'
    />
    <linearGradient
      id='banner_svg__SVGID_142_'
      gradientUnits='userSpaceOnUse'
      x1={88.064}
      y1={306.389}
      x2={145.211}
      y2={306.389}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M145.21 301.7l-25.27 14.98-31.87-8.43c6.01-7.21 18.42-12.15 32.74-12.15 9.37 0 17.92 2.11 24.4 5.6z'
      fill='url(#banner_svg__SVGID_142_)'
    />
    <linearGradient
      id='banner_svg__SVGID_143_'
      gradientUnits='userSpaceOnUse'
      x1={119.938}
      y1={327.677}
      x2={154.603}
      y2={327.677}
    >
      <stop offset={0} stopColor='#fd8d1f' />
      <stop offset={1} stopColor='#fd5c1f' />
    </linearGradient>
    <path
      d='M154.6 326.59c-3.85 5.54-11.35 9.93-20.7 12.08l-13.97-21.99 34.67 9.91z'
      fill='url(#banner_svg__SVGID_143_)'
    />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_50_'
        filterUnits='userSpaceOnUse'
        x={77.43}
        y={188.7}
        width={1.69}
        height={95.73}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={77.43} y={188.7} width={1.69} height={95.73} id='banner_svg__SVGID_144_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_50_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_51_'
            filterUnits='userSpaceOnUse'
            x={77.43}
            y={188.7}
            width={1.69}
            height={95.73}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_145_'
          gradientUnits='userSpaceOnUse'
          x1={77.429}
          y1={236.567}
          x2={79.115}
          y2={236.567}
        >
          <stop offset={0} stopColor='#666' />
          <stop offset={0.056} stopColor='#737373' />
          <stop offset={0.284} stopColor='#a5a5a5' />
          <stop offset={0.5} stopColor='#ccc' />
          <stop offset={0.698} stopColor='#e8e8e8' />
          <stop offset={0.87} stopColor='#f9f9f9' />
          <stop offset={0.999} stopColor='#fff' />
        </linearGradient>
        <path
          d='M77.43 188.7s.58 49.36.84 95.73c0 0 .59-74.69.84-95.73h-1.68z'
          mask='url(#banner_svg__SVGID_144_)'
          fill='url(#banner_svg__SVGID_145_)'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_146_'
      gradientUnits='userSpaceOnUse'
      x1={77.429}
      y1={236.567}
      x2={79.115}
      y2={236.567}
    >
      <stop offset={0} stopColor='#e9f2ff' />
      <stop offset={0.277} stopColor='#c7dbff' />
      <stop offset={0.562} stopColor='#abc8ff' />
      <stop offset={0.812} stopColor='#9abdff' />
      <stop offset={0.999} stopColor='#94b9ff' />
    </linearGradient>
    <path d='M77.43 188.7s.58 49.36.84 95.73c0 0 .59-74.69.84-95.73h-1.68z' fill='url(#banner_svg__SVGID_146_)' />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_52_'
        filterUnits='userSpaceOnUse'
        x={72.03}
        y={177.53}
        width={12.48}
        height={12.48}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={72.03} y={177.53} width={12.48} height={12.48} id='banner_svg__SVGID_147_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_52_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_53_'
            filterUnits='userSpaceOnUse'
            x={72.03}
            y={177.53}
            width={12.48}
            height={12.48}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_148_'
          gradientUnits='userSpaceOnUse'
          x1={84.51}
          y1={183.766}
          x2={72.035}
          y2={183.766}
        >
          <stop offset={0} stopColor='#666' />
          <stop offset={0.056} stopColor='#737373' />
          <stop offset={0.284} stopColor='#a5a5a5' />
          <stop offset={0.5} stopColor='#ccc' />
          <stop offset={0.698} stopColor='#e8e8e8' />
          <stop offset={0.87} stopColor='#f9f9f9' />
          <stop offset={0.999} stopColor='#fff' />
        </linearGradient>
        <circle
          cx={78.27}
          cy={183.77}
          r={6.24}
          mask='url(#banner_svg__SVGID_147_)'
          fill='url(#banner_svg__SVGID_148_)'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_149_'
      gradientUnits='userSpaceOnUse'
      x1={84.51}
      y1={183.766}
      x2={72.035}
      y2={183.766}
    >
      <stop offset={0} stopColor='#e9f2ff' />
      <stop offset={0.277} stopColor='#c7dbff' />
      <stop offset={0.562} stopColor='#abc8ff' />
      <stop offset={0.812} stopColor='#9abdff' />
      <stop offset={0.999} stopColor='#94b9ff' />
    </linearGradient>
    <circle cx={78.27} cy={183.77} r={6.24} fill='url(#banner_svg__SVGID_149_)' />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_54_'
        filterUnits='userSpaceOnUse'
        x={237.36}
        y={66.54}
        width={1.69}
        height={95.73}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={237.36} y={66.54} width={1.69} height={95.73} id='banner_svg__SVGID_150_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_54_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_55_'
            filterUnits='userSpaceOnUse'
            x={237.36}
            y={66.54}
            width={1.69}
            height={95.73}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_151_'
          gradientUnits='userSpaceOnUse'
          x1={237.36}
          y1={114.4}
          x2={239.046}
          y2={114.4}
        >
          <stop offset={0} stopColor='#666' />
          <stop offset={0.056} stopColor='#737373' />
          <stop offset={0.284} stopColor='#a5a5a5' />
          <stop offset={0.5} stopColor='#ccc' />
          <stop offset={0.698} stopColor='#e8e8e8' />
          <stop offset={0.87} stopColor='#f9f9f9' />
          <stop offset={0.999} stopColor='#fff' />
        </linearGradient>
        <path
          d='M237.36 66.54s.58 49.36.84 95.73c0 0 .59-74.69.84-95.73h-1.68z'
          mask='url(#banner_svg__SVGID_150_)'
          fill='url(#banner_svg__SVGID_151_)'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_152_'
      gradientUnits='userSpaceOnUse'
      x1={237.36}
      y1={114.4}
      x2={239.046}
      y2={114.4}
    >
      <stop offset={0} stopColor='#e9f2ff' />
      <stop offset={0.277} stopColor='#c7dbff' />
      <stop offset={0.562} stopColor='#abc8ff' />
      <stop offset={0.812} stopColor='#9abdff' />
      <stop offset={0.999} stopColor='#94b9ff' />
    </linearGradient>
    <path d='M237.36 66.54s.58 49.36.84 95.73c0 0 .59-74.69.84-95.73h-1.68z' fill='url(#banner_svg__SVGID_152_)' />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_56_'
        filterUnits='userSpaceOnUse'
        x={231.97}
        y={55.36}
        width={12.48}
        height={12.48}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={231.97} y={55.36} width={12.48} height={12.48} id='banner_svg__SVGID_153_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_56_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_57_'
            filterUnits='userSpaceOnUse'
            x={231.97}
            y={55.36}
            width={12.48}
            height={12.48}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_154_'
          gradientUnits='userSpaceOnUse'
          x1={244.44}
          y1={61.598}
          x2={231.965}
          y2={61.598}
        >
          <stop offset={0} stopColor='#666' />
          <stop offset={0.056} stopColor='#737373' />
          <stop offset={0.284} stopColor='#a5a5a5' />
          <stop offset={0.5} stopColor='#ccc' />
          <stop offset={0.698} stopColor='#e8e8e8' />
          <stop offset={0.87} stopColor='#f9f9f9' />
          <stop offset={0.999} stopColor='#fff' />
        </linearGradient>
        <circle cx={238.2} cy={61.6} r={6.24} mask='url(#banner_svg__SVGID_153_)' fill='url(#banner_svg__SVGID_154_)' />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_155_'
      gradientUnits='userSpaceOnUse'
      x1={244.44}
      y1={61.598}
      x2={231.965}
      y2={61.598}
    >
      <stop offset={0} stopColor='#e9f2ff' />
      <stop offset={0.277} stopColor='#c7dbff' />
      <stop offset={0.562} stopColor='#abc8ff' />
      <stop offset={0.812} stopColor='#9abdff' />
      <stop offset={0.999} stopColor='#94b9ff' />
    </linearGradient>
    <circle cx={238.2} cy={61.6} r={6.24} fill='url(#banner_svg__SVGID_155_)' />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_58_'
        filterUnits='userSpaceOnUse'
        x={359.66}
        y={186.11}
        width={1.69}
        height={95.73}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={359.66} y={186.11} width={1.69} height={95.73} id='banner_svg__SVGID_156_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_58_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_59_'
            filterUnits='userSpaceOnUse'
            x={359.66}
            y={186.11}
            width={1.69}
            height={95.73}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_157_'
          gradientUnits='userSpaceOnUse'
          x1={359.655}
          y1={233.974}
          x2={361.341}
          y2={233.974}
        >
          <stop offset={0} stopColor='#666' />
          <stop offset={0.056} stopColor='#737373' />
          <stop offset={0.284} stopColor='#a5a5a5' />
          <stop offset={0.5} stopColor='#ccc' />
          <stop offset={0.698} stopColor='#e8e8e8' />
          <stop offset={0.87} stopColor='#f9f9f9' />
          <stop offset={0.999} stopColor='#fff' />
        </linearGradient>
        <path
          d='M359.66 186.11s.58 49.36.84 95.73c0 0 .59-74.69.84-95.73h-1.68z'
          mask='url(#banner_svg__SVGID_156_)'
          fill='url(#banner_svg__SVGID_157_)'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_158_'
      gradientUnits='userSpaceOnUse'
      x1={359.655}
      y1={233.974}
      x2={361.341}
      y2={233.974}
    >
      <stop offset={0} stopColor='#e9f2ff' />
      <stop offset={0.277} stopColor='#c7dbff' />
      <stop offset={0.562} stopColor='#abc8ff' />
      <stop offset={0.812} stopColor='#9abdff' />
      <stop offset={0.999} stopColor='#94b9ff' />
    </linearGradient>
    <path d='M359.66 186.11s.58 49.36.84 95.73c0 0 .59-74.69.84-95.73h-1.68z' fill='url(#banner_svg__SVGID_158_)' />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_60_'
        filterUnits='userSpaceOnUse'
        x={354.26}
        y={174.94}
        width={12.48}
        height={12.48}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={354.26} y={174.94} width={12.48} height={12.48} id='banner_svg__SVGID_159_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_60_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_61_'
            filterUnits='userSpaceOnUse'
            x={354.26}
            y={174.94}
            width={12.48}
            height={12.48}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_160_'
          gradientUnits='userSpaceOnUse'
          x1={366.736}
          y1={181.173}
          x2={354.26}
          y2={181.173}
        >
          <stop offset={0} stopColor='#666' />
          <stop offset={0.056} stopColor='#737373' />
          <stop offset={0.284} stopColor='#a5a5a5' />
          <stop offset={0.5} stopColor='#ccc' />
          <stop offset={0.698} stopColor='#e8e8e8' />
          <stop offset={0.87} stopColor='#f9f9f9' />
          <stop offset={0.999} stopColor='#fff' />
        </linearGradient>
        <circle
          cx={360.5}
          cy={181.17}
          r={6.24}
          mask='url(#banner_svg__SVGID_159_)'
          fill='url(#banner_svg__SVGID_160_)'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_161_'
      gradientUnits='userSpaceOnUse'
      x1={366.736}
      y1={181.173}
      x2={354.26}
      y2={181.173}
    >
      <stop offset={0} stopColor='#e9f2ff' />
      <stop offset={0.277} stopColor='#c7dbff' />
      <stop offset={0.562} stopColor='#abc8ff' />
      <stop offset={0.812} stopColor='#9abdff' />
      <stop offset={0.999} stopColor='#94b9ff' />
    </linearGradient>
    <circle cx={360.5} cy={181.17} r={6.24} fill='url(#banner_svg__SVGID_161_)' />
  </svg>
)

export default SvgBanner
