import React from 'react'

const SvgTv = props => (
  <svg id='tv_svg__\u56FE\u5C42_1' x={0} y={0} viewBox='0 0 150 100' xmlSpace='preserve' {...props}>
    <style>
      {
        '.tv_svg__st0{fill:none;stroke:#334966;stroke-width:4;stroke-linecap:square;stroke-linejoin:bevel;stroke-miterlimit:10}'
      }
    </style>
    <path
      className='tv_svg__st0'
      d='M123.17 81.28h-96c-3.31 0-6-2.69-6-6v-56c0-3.31 2.69-6 6-6h96c3.31 0 6 2.69 6 6v56c0 3.31-2.69 6-6 6zM57.17 91.28h36'
    />
    <path fill='#334966' d='M84.3 92.28H66.37l2.39-9h13.15z' />
  </svg>
)

export default SvgTv
