import React from 'react'

const SvgIpv6Feature4 = props => (
  <svg id='ipv6-feature4_svg__\u56FE\u5C42_1' x={0} y={0} viewBox='0 0 50 50' xmlSpace='preserve' {...props}>
    <style>
      {
        '.ipv6-feature4_svg__st0{fill:#fd5c1f}.ipv6-feature4_svg__st2{fill:#2b4b67}.ipv6-feature4_svg__st3{fill:none;stroke:#2b4b67;stroke-width:2;stroke-miterlimit:10}'
      }
    </style>
    <path
      className='ipv6-feature4_svg__st0'
      d='M12.41 31.89c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2a2 2 0 012 2zM14 30h1v4h-1zM16 30h1v4h-1zM18 30h1v4h-1zM20 30h1v4h-1zM22 30h1v4h-1z'
    />
    <path
      className='ipv6-feature4_svg__st2'
      d='M10 28c-.55 0-1-.45-1-1V6c0-.55.45-1 1-1s1 .45 1 1v21c0 .55-.45 1-1 1zM40 28c-.55 0-1-.45-1-1V6c0-.55.45-1 1-1s1 .45 1 1v21c0 .55-.45 1-1 1z'
    />
    <path
      className='ipv6-feature4_svg__st3'
      d='M47 37H3c-1.1 0-2-.9-2-2v-6c0-1.1.9-2 2-2h44c1.1 0 2 .9 2 2v6c0 1.1-.9 2-2 2zM38 45H12c-1.1 0-2-.9-2-2v-6h30v6c0 1.1-.9 2-2 2z'
    />
    <path
      className='ipv6-feature4_svg__st0'
      d='M18.72 16.95c1.74-1.71 3.84-2.57 6.3-2.57s4.55.86 6.26 2.57l-1.77 1.81c-1.24-1.24-2.74-1.86-4.51-1.86-1.77 0-3.27.62-4.51 1.86l-1.77-1.81zm3.58 3.63c.73-.73 1.63-1.1 2.7-1.1 1.07 0 1.97.37 2.7 1.1l-2.7 2.7-2.7-2.7zm-7.21-7.21c2.76-2.73 6.07-4.09 9.93-4.09s7.16 1.36 9.89 4.09l-1.81 1.81c-2.25-2.22-4.95-3.33-8.1-3.33-3.15 0-5.85 1.11-8.1 3.33l-1.81-1.81zm0 0'
    />
  </svg>
)

export default SvgIpv6Feature4
