import React from 'react'
import intl from 'react-intl-universal'
import { SolutionBanner, NetworkInviteBanner } from '../../components/banners'
import { CommonTitle } from '../../components/titles'
import { Swiper, Wrapper, H3 } from '@raysync/common/components'
import Layout from '../../components/layout'

import {
  SectionDesc,
  FeatureSection,
  Section,
  ApplicationSection,
  ApplicationContainer,
  ApplicationItem,
  ArchSection,
} from './atoms'

import List from '../../components/list'

import Feature1 from '@raysync/common/components/svg/network/ipv6/ipv6-feature1'
import Feature2 from '@raysync/common/components/svg/network/ipv6/ipv6-feature2'
import Feature3 from '@raysync/common/components/svg/network/ipv6/ipv6-feature3'
import Feature4 from '@raysync/common/components/svg/network/ipv6/ipv6-feature4'

import App1 from '@raysync/common/components/svg/network/ipv6/tv'
import App2 from '@raysync/common/components/svg/network/ipv6/computer'
import App3 from '@raysync/common/components/svg/network/ipv6/phone'
import App4 from '@raysync/common/components/svg/network/ipv6/settopbox'

import Arch from '@raysync/common/components/svg/network/ipv6/networkarchitecture'

import Banner from '@raysync/common/components/svg/network/ipv6/banner'

class IPV extends React.PureComponent {
  state = {
    featureList: [
      {
        title: intl.get('network1.feature.item1.title'),
        desc: intl.get('network1.feature.item1.desc'),
        svg: <Feature1 />,
      },
      {
        title: intl.get('network1.feature.item2.title'),
        desc: intl.get('network1.feature.item2.desc'),
        svg: <Feature2 />,
      },
      {
        title: intl.get('network1.feature.item3.title'),
        desc: intl.get('network1.feature.item3.desc'),
        svg: <Feature3 />,
      },
      {
        title: intl.get('network1.feature.item4.title'),
        desc: intl.get('network1.feature.item4.desc'),
        svg: <Feature4 />,
      },
    ],
    applicationList: [
      {
        point: intl.get('network1.scenes.point1'),
        svg: <App1 />,
      },
      {
        point: intl.get('network1.scenes.point2'),
        svg: <App2 />,
      },
      {
        point: intl.get('network1.scenes.point3'),
        svg: <App3 />,
      },
      {
        point: intl.get('network1.scenes.point4'),
        svg: <App4 />,
      },
    ],
  }
  render() {
    const { location } = this.props
    const { featureList, applicationList } = this.state
    const ArchPic = <Arch />

    return (
      <Layout pageType='network1' location={location} scaled>
        {/* 第一屏(为企业提供优质的CTS测试服务) */}
        <Swiper>
          <SolutionBanner type='network1' pic={<Banner />} />
        </Swiper>
        {/* 第二屏(CTS基础概念......) */}
        <Section>
          <H3>{intl.get('network1.section.title1')}</H3>
          <SectionDesc top={20} bottom={20}>
            {intl.get('network1.section.desc1')}
          </SectionDesc>
          <SectionDesc bottom={20}>{intl.get('network1.section.desc2')}</SectionDesc>
          <SectionDesc bottom={20}>{intl.get('network1.section.desc3')}</SectionDesc>
          <SectionDesc bottom={50}>{intl.get('network1.section.desc4')}</SectionDesc>
          <H3>{intl.get('network1.section.title2')}</H3>
          <SectionDesc top={16} bottom={55}>
            {intl.get('network1.section.desc5')}
          </SectionDesc>
          <H3>{intl.get('network1.section.title3')}</H3>
          <SectionDesc top={15} bottom={65}>
            {intl.get('network1.section.desc6')}
          </SectionDesc>
        </Section>
        {/* 第三屏(方案特点) */}
        <FeatureSection>
          <Wrapper>
            <CommonTitle bottom={40}>{intl.get('network1.feature.title')}</CommonTitle>
            <List data={featureList} />
          </Wrapper>
        </FeatureSection>
        {/* 第四屏(网络架构) */}
        <ArchSection>
          <Wrapper>
            <CommonTitle>{intl.get('network1.arch.title')}</CommonTitle>
            <div style={{ margin: '0 50px 0 0' }}>{ArchPic}</div>
          </Wrapper>
        </ArchSection>
        {/* 第五屏(适用适配类型) */}
        <ApplicationSection>
          <Wrapper>
            <CommonTitle>{intl.get('network1.scenes.title')}</CommonTitle>
            <ApplicationContainer>
              {applicationList.map((l, i) => (
                <ApplicationItem key={i}>
                  <div>{l.svg}</div>
                  <p>{l.point}</p>
                </ApplicationItem>
              ))}
            </ApplicationContainer>
          </Wrapper>
        </ApplicationSection>
        {/* 第六屏(申请免费试用) */}
        <Swiper>
          <NetworkInviteBanner type='network1' />
        </Swiper>
      </Layout>
    )
  }
}

export default IPV
