import React from 'react'

const SvgPhone = props => (
  <svg id='phone_svg__\u56FE\u5C42_1' x={0} y={0} viewBox='0 0 80 120' xmlSpace='preserve' {...props}>
    <style>
      {
        '.phone_svg__st0{fill:none;stroke:#334966;stroke-width:4;stroke-linecap:square;stroke-linejoin:bevel;stroke-miterlimit:10}'
      }
    </style>
    <path
      className='phone_svg__st0'
      d='M62 110H18c-3.31 0-6-2.69-6-6V16c0-3.31 2.69-6 6-6h44c3.31 0 6 2.69 6 6v88c0 3.31-2.69 6-6 6zM12 91h56'
    />
    <circle cx={39} cy={100} r={4} fill='#334966' />
  </svg>
)

export default SvgPhone
