import React from 'react'

const SvgIpv6Feature2 = props => (
  <svg id='ipv6-feature2_svg__\u56FE\u5C42_1' x={0} y={0} viewBox='0 0 50 50' xmlSpace='preserve' {...props}>
    <style>{'.ipv6-feature2_svg__st0{fill:#334966}'}</style>
    <g id='ipv6-feature2_svg__\u7B80\u5355_x3001_\u5F3A\u5927\u6613\u7528-48x48'>
      <path
        id='ipv6-feature2_svg__Oval-7_3_'
        className='ipv6-feature2_svg__st0'
        d='M25 49C11.75 49 1 38.25 1 25S11.75 1 25 1s24 10.75 24 24-10.75 24-24 24zm0-2c12.15 0 22-9.85 22-22S37.15 3 25 3 3 12.85 3 25s9.85 22 22 22z'
      />
      <path
        id='ipv6-feature2_svg__Rectangle-89'
        d='M25 22c4.42 0 8 3.58 8 8s-3.58 8-8 8-8-3.58-8-8 3.58-8 8-8z'
        fill='#fd5c1f'
      />
      <path
        id='ipv6-feature2_svg__Combined-Shape_17_'
        className='ipv6-feature2_svg__st0'
        d='M25 14c-3.31 0-6 2.69-6 6v10c0 3.31 2.69 6 6 6s6-2.69 6-6V20c0-3.31-2.69-6-6-6zm0-2c4.42 0 8 3.58 8 8v10c0 4.42-3.58 8-8 8s-8-3.58-8-8V20c0-4.42 3.58-8 8-8zm0 5c-.55 0-1 .45-1 1v1c0 .55.45 1 1 1s1-.45 1-1v-1c0-.55-.45-1-1-1z'
      />
    </g>
  </svg>
)

export default SvgIpv6Feature2
