import React from 'react'

const SvgSettopbox = props => (
  <svg id='settopbox_svg__\u56FE\u5C42_1' x={0} y={0} viewBox='0 0 160 80' xmlSpace='preserve' {...props}>
    <style>
      {
        '.settopbox_svg__st0{fill:none;stroke:#334966;stroke-width:4;stroke-miterlimit:10}.settopbox_svg__st2{fill:#334966}'
      }
    </style>
    <path className='settopbox_svg__st0' d='M15.94 50.63h68' />
    <path
      fill='none'
      stroke='#334966'
      strokeWidth={4}
      strokeLinecap='square'
      strokeLinejoin='bevel'
      strokeMiterlimit={10}
      d='M157 31.63H3.12l19.99-26h113.91z'
    />
    <path
      className='settopbox_svg__st0'
      d='M150.81 71.63H9.06C5.71 71.63 3 68.92 3 65.57V32.6c0-.54.43-.97.97-.97H155.9c.54 0 .98.44.98.98v32.96c0 3.35-2.72 6.06-6.07 6.06z'
    />
    <circle className='settopbox_svg__st2' cx={106.94} cy={50.63} r={5} />
    <circle className='settopbox_svg__st2' cx={127.94} cy={50.63} r={5} />
  </svg>
)

export default SvgSettopbox
