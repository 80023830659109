import React from 'react'

const SvgNetworkarchitecture = props => (
  <svg id='networkarchitecture_svg___-s-1' x={0} y={0} viewBox='0 0 1200 300' xmlSpace='preserve' {...props}>
    <style>
      {
        '.networkarchitecture_svg__st0,.networkarchitecture_svg__st1{opacity:.4;fill:none;stroke:#3586eb;enable-background:new}.networkarchitecture_svg__st1{opacity:.6}.networkarchitecture_svg__st13{fill:#1766d4}.networkarchitecture_svg__st15{fill:none;stroke:#8796aa;stroke-dasharray:6,4}.networkarchitecture_svg__st16{fill:#0e68d9}.networkarchitecture_svg__st17{font-family:&apos;SourceHanSansCN-Normal-GBpc-EUC-H&apos;}.networkarchitecture_svg__st18{font-size:14px}.networkarchitecture_svg__st72{opacity:.8;fill:none;stroke:#fbf8ff;stroke-width:.5618;stroke-miterlimit:10;enable-background:new}'
      }
    </style>
    <path
      id='networkarchitecture_svg__\u77E9\u5F62_6_\u62F7\u8D1D-2'
      className='networkarchitecture_svg__st0'
      d='M614.99 164.01l94.33-59.83c7.45-4.72 19.74-4.97 27.38-.56l97.42 56.21c7.1 4.1 6.74 10.93-.74 15.24l-94.99 54.81c-6.91 3.99-18.18 4.18-25.25.44l-96.77-51.19c-7.58-4.01-8.23-10.78-1.38-15.12z'
    />
    <path
      id='networkarchitecture_svg__\u77E9\u5F62_6-2'
      className='networkarchitecture_svg__st1'
      d='M640.77 164.65l72.19-45.76c5.69-3.6 15.1-3.8 20.95-.42l74.54 42.98c5.44 3.14 5.16 8.36-.56 11.66l-72.69 41.91c-5.29 3.05-13.92 3.2-19.33.34l-74.05-39.15c-5.8-3.07-6.29-8.24-1.05-11.56z'
    />
    <linearGradient
      id='networkarchitecture_svg__\u77E9\u5F62_1239_1_'
      gradientUnits='userSpaceOnUse'
      x1={3164.106}
      y1={-1628.581}
      x2={3270.389}
      y2={-1734.864}
      gradientTransform='matrix(1 0 0 -1 -2798 -1504)'
    >
      <stop offset={0.005} stopColor='#00c6fb' />
      <stop offset={1} stopColor='#005bea' />
    </linearGradient>
    <path
      id='networkarchitecture_svg__\u77E9\u5F62_1239'
      d='M344.25 148.92h150c3.31 0 6 2.69 6 6v45.6c0 3.31-2.69 6-6 6h-150c-3.31 0-6-2.67-6-5.98V154.92c0-3.31 2.69-6 6-6z'
      fillRule='evenodd'
      clipRule='evenodd'
      fill='url(#networkarchitecture_svg__\u77E9\u5F62_1239_1_)'
      stroke='#1766d4'
      strokeWidth={2}
    />
    <linearGradient
      id='networkarchitecture_svg__\u77E9\u5F62_1274_1_'
      gradientUnits='userSpaceOnUse'
      x1={3164.15}
      y1={-1627.72}
      x2={3270.35}
      y2={-1733.92}
      gradientTransform='matrix(1 0 0 -1 -2798 -1504)'
    >
      <stop offset={0.005} stopColor='#00c6fb' />
      <stop offset={1} stopColor='#005bea' />
    </linearGradient>
    <path
      id='networkarchitecture_svg__\u77E9\u5F62_1274'
      d='M329.25 160.62h180v32.4h-180v-32.4z'
      fillRule='evenodd'
      clipRule='evenodd'
      fill='url(#networkarchitecture_svg__\u77E9\u5F62_1274_1_)'
      stroke='#1766d4'
      strokeWidth={2}
    />
    <linearGradient
      id='networkarchitecture_svg__\u77E9\u5F62_1245_1_'
      gradientUnits='userSpaceOnUse'
      x1={344.46}
      y1={-73.23}
      x2={358.86}
      y2={-73.23}
      gradientTransform='translate(0 250)'
    >
      <stop offset={0.006} stopColor='#55ff94' />
      <stop offset={1} stopColor='#e9fff9' />
    </linearGradient>
    <path
      id='networkarchitecture_svg__\u77E9\u5F62_1245'
      d='M344.46 173.17h14.4v7.2h-14.4v-7.2z'
      fillRule='evenodd'
      clipRule='evenodd'
      fill='url(#networkarchitecture_svg__\u77E9\u5F62_1245_1_)'
    />
    <linearGradient
      id='networkarchitecture_svg__\u77E9\u5F62_1245_\u62F7\u8D1D_1_'
      gradientUnits='userSpaceOnUse'
      x1={368.45}
      y1={-73.23}
      x2={382.85}
      y2={-73.23}
      gradientTransform='translate(0 250)'
    >
      <stop offset={0.006} stopColor='#55ff94' />
      <stop offset={1} stopColor='#e9fff9' />
    </linearGradient>
    <path
      id='networkarchitecture_svg__\u77E9\u5F62_1245_\u62F7\u8D1D'
      d='M368.45 173.17h14.4v7.2h-14.4v-7.2z'
      fillRule='evenodd'
      clipRule='evenodd'
      fill='url(#networkarchitecture_svg__\u77E9\u5F62_1245_\u62F7\u8D1D_1_)'
    />
    <linearGradient
      id='networkarchitecture_svg__\u77E9\u5F62_1245_\u62F7\u8D1D_2_1_'
      gradientUnits='userSpaceOnUse'
      x1={394.22}
      y1={-73.23}
      x2={408.61}
      y2={-73.23}
      gradientTransform='translate(0 250)'
    >
      <stop offset={0.006} stopColor='#55ff94' />
      <stop offset={1} stopColor='#e9fff9' />
    </linearGradient>
    <path
      id='networkarchitecture_svg__\u77E9\u5F62_1245_\u62F7\u8D1D_2'
      d='M394.22 173.17h14.39v7.2h-14.39v-7.2z'
      fillRule='evenodd'
      clipRule='evenodd'
      fill='url(#networkarchitecture_svg__\u77E9\u5F62_1245_\u62F7\u8D1D_2_1_)'
    />
    <linearGradient
      id='networkarchitecture_svg__\u77E9\u5F62_1248_1_'
      gradientUnits='userSpaceOnUse'
      x1={3240.28}
      y1={-1684.38}
      x2={3240.28}
      y2={-1677.16}
      gradientTransform='matrix(1 0 0 -1 -2798 -1504)'
    >
      <stop offset={0.006} stopColor='#55ff94' />
      <stop offset={1} stopColor='#e9fff9' />
    </linearGradient>
    <path
      id='networkarchitecture_svg__\u77E9\u5F62_1248'
      d='M442.28 173.17c1.99 0 3.6 1.61 3.6 3.6v.01c0 1.98-1.61 3.59-3.59 3.59h-.02c-1.98 0-3.59-1.61-3.59-3.59v-.01c0-1.99 1.61-3.6 3.6-3.6z'
      fillRule='evenodd'
      clipRule='evenodd'
      fill='url(#networkarchitecture_svg__\u77E9\u5F62_1248_1_)'
    />
    <linearGradient
      id='networkarchitecture_svg__\u77E9\u5F62_1248_\u62F7\u8D1D_3_1_'
      gradientUnits='userSpaceOnUse'
      x1={3256.48}
      y1={-1677.16}
      x2={3256.48}
      y2={-1684.38}
      gradientTransform='matrix(1 0 0 -1 -2798 -1504)'
    >
      <stop offset={0.005} stopColor='#d4fff1' />
      <stop offset={0.322} stopColor='#e5c6c0' />
      <stop offset={0.633} stopColor='#f39496' />
      <stop offset={0.868} stopColor='#fc757c' />
      <stop offset={1} stopColor='#ff6a72' />
    </linearGradient>
    <path
      id='networkarchitecture_svg__\u77E9\u5F62_1248_\u62F7\u8D1D_3'
      d='M458.48 173.17c1.99 0 3.6 1.61 3.6 3.6v.01c0 1.98-1.61 3.59-3.59 3.59h-.02c-1.98 0-3.59-1.61-3.59-3.59v-.01c0-1.99 1.61-3.6 3.6-3.6z'
      fillRule='evenodd'
      clipRule='evenodd'
      fill='url(#networkarchitecture_svg__\u77E9\u5F62_1248_\u62F7\u8D1D_3_1_)'
    />
    <linearGradient
      id='networkarchitecture_svg__\u77E9\u5F62_1248_\u62F7\u8D1D_4_1_'
      gradientUnits='userSpaceOnUse'
      x1={3272.68}
      y1={-1684.38}
      x2={3272.68}
      y2={-1677.16}
      gradientTransform='matrix(1 0 0 -1 -2798 -1504)'
    >
      <stop offset={0.006} stopColor='#55ff94' />
      <stop offset={1} stopColor='#e9fff9' />
    </linearGradient>
    <path
      id='networkarchitecture_svg__\u77E9\u5F62_1248_\u62F7\u8D1D_4'
      d='M474.68 173.17c1.99 0 3.6 1.61 3.6 3.6v.01c0 1.98-1.61 3.59-3.59 3.59h-.02c-1.98 0-3.59-1.61-3.59-3.59v-.01c0-1.99 1.61-3.6 3.6-3.6z'
      fillRule='evenodd'
      clipRule='evenodd'
      fill='url(#networkarchitecture_svg__\u77E9\u5F62_1248_\u62F7\u8D1D_4_1_)'
    />
    <linearGradient
      id='networkarchitecture_svg__\u77E9\u5F62_1248_\u62F7\u8D1D_4-2_1_'
      gradientUnits='userSpaceOnUse'
      x1={3288.88}
      y1={-1677.16}
      x2={3288.88}
      y2={-1684.38}
      gradientTransform='matrix(1 0 0 -1 -2798 -1504)'
    >
      <stop offset={0.005} stopColor='#d4fff1' />
      <stop offset={0.322} stopColor='#e5c6c0' />
      <stop offset={0.633} stopColor='#f39496' />
      <stop offset={0.868} stopColor='#fc757c' />
      <stop offset={1} stopColor='#ff6a72' />
    </linearGradient>
    <path
      id='networkarchitecture_svg__\u77E9\u5F62_1248_\u62F7\u8D1D_4-2'
      d='M490.88 173.17c1.99 0 3.6 1.61 3.6 3.6v.01c0 1.98-1.61 3.59-3.59 3.59h-.02c-1.98 0-3.59-1.61-3.59-3.59v-.01c0-1.99 1.61-3.6 3.6-3.6z'
      fillRule='evenodd'
      clipRule='evenodd'
      fill='url(#networkarchitecture_svg__\u77E9\u5F62_1248_\u62F7\u8D1D_4-2_1_)'
    />
    <path
      id='networkarchitecture_svg__\u77E9\u5F62_1271_\u62F7\u8D1D_4'
      d='M372.12 150.59h3v9h-3v-9zm9.03 0h2.97v9h-2.97v-9zm9.03 0h2.94v9h-2.94v-9zm9.03 0h2.91v9h-2.91v-9zm8.91 0h3v9h-3v-9zm9 0h3v9h-3v-9zm9 0h3v9h-3v-9zm9 0h3v9h-3v-9zm9 0h3v9h-3v-9zm9 0h3v9h-3v-9zm9 0h3v9h-3v-9z'
      opacity={0.29}
      fillRule='evenodd'
      clipRule='evenodd'
      fill='#fff'
    />
    <linearGradient
      id='networkarchitecture_svg__\u77E9\u5F62_1236_\u62F7\u8D1D_3_1_'
      gradientUnits='userSpaceOnUse'
      x1={360.345}
      y1={409}
      x2={360.345}
      y2={496}
      gradientTransform='matrix(1 0 0 -1 0 550)'
    >
      <stop offset={0} stopColor='#0396ff' />
      <stop offset={0.1} stopColor='#109cff' />
      <stop offset={0.285} stopColor='#33acff' />
      <stop offset={0.536} stopColor='#6bc6ff' />
      <stop offset={0.838} stopColor='#b7eaff' />
      <stop offset={1} stopColor='#e3feff' />
    </linearGradient>
    <path
      id='networkarchitecture_svg__\u77E9\u5F62_1236_\u62F7\u8D1D_3'
      d='M360.34 54h.01c4.47 0 8.09 3.62 8.09 8.09v70.82c0 4.47-3.62 8.09-8.09 8.09h-.01c-4.47 0-8.09-3.62-8.09-8.09V62.09c0-4.47 3.62-8.09 8.09-8.09z'
      fill='url(#networkarchitecture_svg__\u77E9\u5F62_1236_\u62F7\u8D1D_3_1_)'
      stroke='#1766d4'
      strokeWidth={2}
    />
    <path
      id='networkarchitecture_svg__\u77E9\u5F62_1237_\u62F7\u8D1D_3'
      className='networkarchitecture_svg__st13'
      d='M356.25 139.89h2v10h-2z'
    />
    <path
      id='networkarchitecture_svg__\u77E9\u5F62_1237_\u62F7\u8D1D_3-2'
      className='networkarchitecture_svg__st13'
      d='M361.25 140.15l2-.02v9.76h-2z'
    />
    <linearGradient
      id='networkarchitecture_svg__\u77E9\u5F62_1236_\u62F7\u8D1D_4_1_'
      gradientUnits='userSpaceOnUse'
      x1={476.155}
      y1={409}
      x2={476.155}
      y2={496}
      gradientTransform='matrix(1 0 0 -1 0 550)'
    >
      <stop offset={0} stopColor='#0396ff' />
      <stop offset={0.1} stopColor='#109cff' />
      <stop offset={0.285} stopColor='#33acff' />
      <stop offset={0.536} stopColor='#6bc6ff' />
      <stop offset={0.838} stopColor='#b7eaff' />
      <stop offset={1} stopColor='#e3feff' />
    </linearGradient>
    <path
      id='networkarchitecture_svg__\u77E9\u5F62_1236_\u62F7\u8D1D_4'
      d='M476.16 54h0c4.47 0 8.08 3.62 8.08 8.08v70.85c0 4.46-3.62 8.07-8.08 8.07h-.01c-4.46 0-8.08-3.62-8.08-8.07V62.08c.02-4.46 3.64-8.08 8.09-8.08z'
      fill='url(#networkarchitecture_svg__\u77E9\u5F62_1236_\u62F7\u8D1D_4_1_)'
      stroke='#1766d4'
      strokeWidth={2}
    />
    <path
      id='networkarchitecture_svg__\u77E9\u5F62_1237_\u62F7\u8D1D_4'
      className='networkarchitecture_svg__st13'
      d='M473.25 140.17h2v9.72h-2z'
    />
    <path
      id='networkarchitecture_svg__\u77E9\u5F62_1237_\u62F7\u8D1D_4-2'
      className='networkarchitecture_svg__st13'
      d='M478.25 139.89h2v10h-2z'
    />
    <g id='networkarchitecture_svg__LINE'>
      <path
        id='networkarchitecture_svg__\u5F62\u72B6_787'
        className='networkarchitecture_svg__st15'
        d='M89.25 71s46.9 39.99 93 63c42.89 21.41 143.62 35.52 143.62 35.52'
      />
      <path
        id='networkarchitecture_svg__\u5F62\u72B6_788'
        className='networkarchitecture_svg__st15'
        d='M83.25 197s16.61-15.4 55-30c51.4-19.55 151.32-2.65 187.62 2.52'
      />
      <path
        id='networkarchitecture_svg__\u5F62\u72B6_789'
        className='networkarchitecture_svg__st15'
        d='M202.25 67s19.12 41.59 37 61c21.88 23.76 59.49 41.25 84 41'
      />
      <path
        id='networkarchitecture_svg__\u5F62\u72B6_790'
        className='networkarchitecture_svg__st15'
        d='M209.25 195s19.42-.2 47-11c25.86-10.13 32.48-14.96 69.62-14.48'
      />
      <path
        id='networkarchitecture_svg__\u5F62\u72B6_792'
        className='networkarchitecture_svg__st15'
        d='M514.25 169h100'
      />
      <path
        id='networkarchitecture_svg__\u5F62\u72B6_792_\u62F7\u8D1D'
        className='networkarchitecture_svg__st15'
        d='M841.25 168h100'
      />
    </g>
    <text
      id='networkarchitecture_svg__Android-CTS'
      transform='translate(1016.498 261.001)'
      className='networkarchitecture_svg__st16 networkarchitecture_svg__st17 networkarchitecture_svg__st18'
    >
      {'Android-CTS'}
    </text>
    <text
      id='networkarchitecture_svg__\u6D4B\u8BD5\u670D\u52A1\u5668'
      transform='translate(1023.25 284)'
      className='networkarchitecture_svg__st16 networkarchitecture_svg__st17 networkarchitecture_svg__st18'
    >
      {'\u6D4B\u8BD5\u670D\u52A1\u5668'}
    </text>
    <text
      transform='translate(701.255 261.001)'
      className='networkarchitecture_svg__st16 networkarchitecture_svg__st17 networkarchitecture_svg__st18'
    >
      {'\u956D\u901F\u4E91'}
    </text>
    <text
      transform='translate(701.255 284.001)'
      className='networkarchitecture_svg__st16 networkarchitecture_svg__st17 networkarchitecture_svg__st18'
    >
      {'\u9AA8\u5E72\u7F51'}
    </text>
    <text
      transform='translate(395.244 261.001)'
      className='networkarchitecture_svg__st16 networkarchitecture_svg__st17 networkarchitecture_svg__st18'
    >
      {'\u956D\u901F\u4E91'}
    </text>
    <text
      transform='translate(388.788 284.001)'
      className='networkarchitecture_svg__st16 networkarchitecture_svg__st17 networkarchitecture_svg__st18'
    >
      {'CTS\u76D2\u5B50'}
    </text>
    <path
      className='networkarchitecture_svg__st16'
      d='M73.25 210v-20.4c0-1.44-1.16-2.6-2.6-2.6h-32.8c-1.44 0-2.6 1.16-2.6 2.6V210h-3v1.56c0 .44 0 .44.5.44h43.5c.94 0 1 0 1-.44V210h-4zm-13 1h-11v-1h11v1zm11-2h-34v-19.4c0-.33.27-.6.6-.6h32.8c.33 0 .6.27.6.6V209zM35.25 70h20v2h-20zM77.5 66H30.75l-.82-1.57 5.56-8 .82-.43h35.05l.79.39 6.15 8-.8 1.61zm-44.84-2h42.81l-4.61-6H36.83l-4.17 6z'
    />
    <path
      className='networkarchitecture_svg__st16'
      d='M76.23 78H33.29c-1.68 0-3.04-1.36-3.04-3.04v-9.32c0-.9.73-1.64 1.64-1.64h45.38c1.09 0 1.98.89 1.98 1.98v9c0 1.67-1.35 3.02-3.02 3.02zM32.25 66.02v8.94c0 .57.47 1.04 1.04 1.04h42.94c.56 0 1.02-.46 1.02-1.02V66l-45 .02z'
    />
    <circle className='networkarchitecture_svg__st16' cx={62.75} cy={70.5} r={1.5} />
    <circle className='networkarchitecture_svg__st16' cx={68.75} cy={70.5} r={1.5} />
    <path
      className='networkarchitecture_svg__st16'
      d='M195.26 186h-29.02c-1.65 0-2.99 1.34-2.99 2.99v17.02c0 1.65 1.34 2.99 2.99 2.99h11.87l-.8 3h-3.06v2h12v-2h-2.96l-.8-3h12.77c1.65 0 2.99-1.34 2.99-2.99v-17.02c0-1.65-1.34-2.99-2.99-2.99zm0 21h-29.02c-.55 0-.99-.44-.99-.99v-17.02c0-.55.44-.99.99-.99h29.02c.55 0 .99.44.99.99v17.02c0 .55-.44.99-.99.99zM190.28 53h-18.05a2.97 2.97 0 00-2.97 2.97v36.05c0 1.64 1.33 2.98 2.98 2.98h18.05c1.64 0 2.98-1.33 2.98-2.98V55.97a3 3 0 00-2.99-2.97zm0 40h-18.05c-.54 0-.98-.44-.98-.98V87h20v5.03c0 .53-.44.97-.97.97zm-19.03-8V55.97c0-.54.44-.97.97-.97h18.05c.54 0 .98.44.98.98V85h-20z'
    />
    <circle className='networkarchitecture_svg__st16' cx={181.06} cy={90.25} r={2} />
    <path
      id='networkarchitecture_svg__\u77E9\u5F62_6_\u62F7\u8D1D-2_1_'
      className='networkarchitecture_svg__st0'
      d='M944.97 162.35l94.33-59.83c7.45-4.72 19.74-4.97 27.38-.56l97.42 56.21c7.1 4.1 6.74 10.93-.74 15.24l-94.99 54.81c-6.91 3.99-18.18 4.18-25.25.44l-96.77-51.19c-7.58-4.01-8.23-10.78-1.38-15.12z'
    />
    <path
      id='networkarchitecture_svg__\u77E9\u5F62_6-2_1_'
      className='networkarchitecture_svg__st1'
      d='M970.75 162.99l72.19-45.76c5.69-3.6 15.1-3.8 20.95-.42l74.54 42.98c5.44 3.14 5.16 8.36-.56 11.66l-72.69 41.91c-5.29 3.05-13.92 3.2-19.33.34l-74.05-39.15c-5.8-3.07-6.29-8.24-1.05-11.56z'
    />
    <g>
      <g id='networkarchitecture_svg__XMLID_45_'>
        <linearGradient
          id='networkarchitecture_svg__XMLID_48_'
          gradientUnits='userSpaceOnUse'
          x1={524.019}
          y1={-24.112}
          x2={475.664}
          y2={-138.458}
          gradientTransform='translate(523.46 250)'
        >
          <stop offset={0.005} stopColor='#00c6fb' />
          <stop offset={1} stopColor='#005bea' />
        </linearGradient>
        <path
          id='networkarchitecture_svg__XMLID_46_'
          d='M1056.49 152.29l3.48 52.2a6.875 6.875 0 01-6.97.02l-70.68-41.14c-2-1.16-3.23-3.3-3.23-5.61v-48.47l77.4 43z'
          fill='url(#networkarchitecture_svg__XMLID_48_)'
        />
      </g>
      <g id='networkarchitecture_svg__XMLID_43_'>
        <linearGradient
          id='networkarchitecture_svg__XMLID_49_'
          gradientUnits='userSpaceOnUse'
          x1={1043.469}
          y1={-148.802}
          x2={1091.375}
          y2={-94.016}
          gradientTransform='translate(0 250)'
        >
          <stop offset={0.005} stopColor='#00c6fb' />
          <stop offset={1} stopColor='#005bea' />
        </linearGradient>
        <path
          id='networkarchitecture_svg__XMLID_44_'
          d='M1128.99 111.61v48.41c0 2.3-1.21 4.42-3.19 5.59l-65.94 38.95c-1.49.88-3.37-.19-3.37-1.92v-52.5l72.5-38.53z'
          fill='url(#networkarchitecture_svg__XMLID_49_)'
        />
      </g>
      <linearGradient
        id='networkarchitecture_svg__XMLID_50_'
        gradientUnits='userSpaceOnUse'
        x1={1010.086}
        y1={-173.149}
        x2={1186.658}
        y2={-36.067}
        gradientTransform='translate(0 250)'
      >
        <stop offset={0.006} stopColor='#55ff94' />
        <stop offset={1} stopColor='#e9fff9' />
      </linearGradient>
      <path
        id='networkarchitecture_svg__XMLID_42_'
        d='M1127.92 113.72l-67.23 39.8a9.682 9.682 0 01-9.85.02l-70.26-41.2c-1.99-1.17-1.95-4.06.07-5.17l71.09-39.25a9.704 9.704 0 019.86.28l66.39 41.77c1.37.87 1.34 2.91-.07 3.75z'
        fill='url(#networkarchitecture_svg__XMLID_50_)'
      />
      <linearGradient
        id='networkarchitecture_svg__SVGID_1_'
        gradientUnits='userSpaceOnUse'
        x1={3873.419}
        y1={-73.963}
        x2={3884.407}
        y2={-17.134}
        gradientTransform='matrix(-1 0 0 1 4941.616 250)'
      >
        <stop offset={0.005} stopColor='#00c6fb' />
        <stop offset={1} stopColor='#005bea' />
      </linearGradient>
      <path
        d='M1068.22 194.55l.6-.35c2.14-1.23 3.87-4.54 3.87-7.39V157.1c0-2.85-1.73-4.16-3.87-2.92l-.6.35c-2.14 1.23-3.87 4.54-3.87 7.39v29.71c0 2.84 1.73 4.15 3.87 2.92z'
        fill='url(#networkarchitecture_svg__SVGID_1_)'
      />
      <linearGradient
        id='networkarchitecture_svg__SVGID_2_'
        gradientUnits='userSpaceOnUse'
        x1={3857.855}
        y1={-102.077}
        x2={3879.675}
        y2={-78.134}
        gradientTransform='matrix(-1 0 0 1 4941.616 250)'
      >
        <stop offset={0.006} stopColor='#55ff94' />
        <stop offset={1} stopColor='#e9fff9' />
      </linearGradient>
      <path
        d='M1066.69 165.49c0 1.42 1 1.99 2.24 1.28s2.24-2.45 2.24-3.87-1-1.99-2.24-1.28c-1.24.72-2.24 2.45-2.24 3.87z'
        fill='url(#networkarchitecture_svg__SVGID_2_)'
      />
      <linearGradient
        id='networkarchitecture_svg__SVGID_3_'
        gradientUnits='userSpaceOnUse'
        x1={3857.858}
        y1={-91.753}
        x2={3879.679}
        y2={-67.81}
        gradientTransform='matrix(-1 0 0 1 4941.616 250)'
      >
        <stop offset={0.006} stopColor='#55ff94' />
        <stop offset={1} stopColor='#e9fff9' />
      </linearGradient>
      <path
        d='M1066.69 175.81c0 1.42 1 1.99 2.24 1.28s2.24-2.45 2.24-3.87-1-1.99-2.24-1.28c-1.24.72-2.24 2.45-2.24 3.87z'
        fill='url(#networkarchitecture_svg__SVGID_3_)'
      />
      <linearGradient
        id='networkarchitecture_svg__SVGID_4_'
        gradientUnits='userSpaceOnUse'
        x1={3857.858}
        y1={-81.734}
        x2={3879.678}
        y2={-57.79}
        gradientTransform='matrix(-1 0 0 1 4941.616 250)'
      >
        <stop offset={0.005} stopColor='#d4fff1' />
        <stop offset={0.322} stopColor='#e5c6c0' />
        <stop offset={0.633} stopColor='#f39496' />
        <stop offset={0.868} stopColor='#fc757c' />
        <stop offset={1} stopColor='#ff6a72' />
      </linearGradient>
      <path
        d='M1066.69 185.83c0 1.42 1 1.99 2.24 1.28s2.24-2.45 2.24-3.87-1-1.99-2.24-1.28c-1.24.72-2.24 2.45-2.24 3.87z'
        fill='url(#networkarchitecture_svg__SVGID_4_)'
      />
      <linearGradient
        id='networkarchitecture_svg__SVGID_5_'
        gradientUnits='userSpaceOnUse'
        x1={1026.207}
        y1={-47.699}
        x2={1065.558}
        y2={47.652}
        gradientTransform='translate(0 250)'
      >
        <stop offset={0.005} stopColor='#00c6fb' />
        <stop offset={1} stopColor='#005bea' />
      </linearGradient>
      <path
        d='M1036.88 195.07l-45.5-26.45v-13.08c0-7.44 4.52-10.85 10.1-7.63l25.3 14.61c5.58 3.22 10.1 11.86 10.1 19.29v13.26z'
        fill='url(#networkarchitecture_svg__SVGID_5_)'
      />
      <linearGradient
        id='networkarchitecture_svg__SVGID_6_'
        gradientUnits='userSpaceOnUse'
        x1={3159.334}
        y1={-1308.474}
        x2={3180.8}
        y2={-1284.92}
        gradientTransform='matrix(-.433 1.0833 .75 .433 3349.05 -2717.12)'
      >
        <stop offset={0.006} stopColor='#55ff94' />
        <stop offset={1} stopColor='#e9fff9' />
      </linearGradient>
      <path
        d='M1005.4 164.16c1.24.71 2.24.14 2.24-1.28s-1-3.15-2.24-3.87c-1.24-.71-2.24-.14-2.24 1.28s1 3.16 2.24 3.87z'
        fill='url(#networkarchitecture_svg__SVGID_6_)'
      />
      <linearGradient
        id='networkarchitecture_svg__SVGID_7_'
        gradientUnits='userSpaceOnUse'
        x1={3159.334}
        y1={-1296.475}
        x2={3180.8}
        y2={-1272.92}
        gradientTransform='matrix(-.433 1.0833 .75 .433 3349.05 -2717.12)'
      >
        <stop offset={0.006} stopColor='#55ff94' />
        <stop offset={1} stopColor='#e9fff9' />
      </linearGradient>
      <path
        d='M1014.4 169.36c1.24.71 2.24.14 2.24-1.28s-1-3.15-2.24-3.87c-1.24-.71-2.24-.14-2.24 1.28s1 3.15 2.24 3.87z'
        fill='url(#networkarchitecture_svg__SVGID_7_)'
      />
      <g id='networkarchitecture_svg__XMLID_33_'>
        <linearGradient
          id='networkarchitecture_svg__XMLID_51_'
          gradientUnits='userSpaceOnUse'
          x1={3576.361}
          y1={-360.839}
          x2={3638.285}
          y2={-320.278}
          gradientTransform='matrix(-.866 .5 0 1.1547 4205.217 -1231.902)'
        >
          <stop offset={0.005} stopColor='#00c6fb' />
          <stop offset={1} stopColor='#005bea' />
        </linearGradient>
        <path
          id='networkarchitecture_svg__XMLID_41_'
          fill='url(#networkarchitecture_svg__XMLID_51_)'
          d='M1077.75 168l47.31-27.31v-10.07l-47.31 27.32z'
        />
        <linearGradient
          id='networkarchitecture_svg__XMLID_52_'
          gradientUnits='userSpaceOnUse'
          x1={3601.291}
          y1={-360.184}
          x2={3614.383}
          y2={-351.191}
          gradientTransform='matrix(-.866 .5 0 1.1547 4205.217 -1231.902)'
        >
          <stop offset={0.006} stopColor='#55ff94' />
          <stop offset={1} stopColor='#e9fff9' />
        </linearGradient>
        <path
          id='networkarchitecture_svg__XMLID_40_'
          fill='url(#networkarchitecture_svg__XMLID_52_)'
          d='M1079.38 164.94l3.59-2.07v-6.24l-3.59 2.08z'
        />
        <linearGradient
          id='networkarchitecture_svg__XMLID_53_'
          gradientUnits='userSpaceOnUse'
          x1={3595.394}
          y1={-360.181}
          x2={3608.486}
          y2={-351.188}
          gradientTransform='matrix(-.866 .5 0 1.1547 4205.217 -1231.902)'
        >
          <stop offset={0.006} stopColor='#55ff94' />
          <stop offset={1} stopColor='#e9fff9' />
        </linearGradient>
        <path
          id='networkarchitecture_svg__XMLID_39_'
          fill='url(#networkarchitecture_svg__XMLID_53_)'
          d='M1084.49 161.99l3.59-2.07v-6.23l-3.59 2.07z'
        />
        <linearGradient
          id='networkarchitecture_svg__XMLID_54_'
          gradientUnits='userSpaceOnUse'
          x1={3550.575}
          y1={-371.712}
          x2={3593.073}
          y2={-342.521}
          gradientTransform='matrix(-.866 .5 0 1.1547 4205.217 -1231.902)'
        >
          <stop offset={0.006} stopColor='#55ff94' />
          <stop offset={1} stopColor='#e9fff9' />
        </linearGradient>
        <path
          id='networkarchitecture_svg__XMLID_38_'
          fill='url(#networkarchitecture_svg__XMLID_54_)'
          d='M1102.51 146.8l21.33-12.31v-1.45l-21.33 12.31z'
        />
        <linearGradient
          id='networkarchitecture_svg__XMLID_55_'
          gradientUnits='userSpaceOnUse'
          x1={3551.959}
          y1={-364.92}
          x2={3586.227}
          y2={-341.382}
          gradientTransform='matrix(-.866 .5 0 1.1547 4205.217 -1231.902)'
        >
          <stop offset={0.006} stopColor='#55ff94' />
          <stop offset={1} stopColor='#e9fff9' />
        </linearGradient>
        <path
          id='networkarchitecture_svg__XMLID_37_'
          fill='url(#networkarchitecture_svg__XMLID_55_)'
          d='M1106.79 149.12l17.05-9.85v-1.45l-17.05 9.85z'
        />
        <linearGradient
          id='networkarchitecture_svg__XMLID_56_'
          gradientUnits='userSpaceOnUse'
          x1={3584.544}
          y1={-354.909}
          x2={3588.393}
          y2={-352.265}
          gradientTransform='matrix(-.866 .5 0 1.1547 4205.217 -1231.902)'
        >
          <stop offset={0.006} stopColor='#55ff94' />
          <stop offset={1} stopColor='#e9fff9' />
        </linearGradient>
        <path
          id='networkarchitecture_svg__XMLID_36_'
          d='M1098.57 153.4c0 .55.39.77.86.49.48-.27.86-.94.86-1.48 0-.55-.39-.77-.86-.49-.48.27-.86.94-.86 1.48z'
          fill='url(#networkarchitecture_svg__XMLID_56_)'
        />
        <linearGradient
          id='networkarchitecture_svg__XMLID_57_'
          gradientUnits='userSpaceOnUse'
          x1={3588.281}
          y1={-354.91}
          x2={3592.13}
          y2={-352.266}
          gradientTransform='matrix(-.866 .5 0 1.1547 4205.217 -1231.902)'
        >
          <stop offset={0.005} stopColor='#d4fff1' />
          <stop offset={0.322} stopColor='#e5c6c0' />
          <stop offset={0.633} stopColor='#f39496' />
          <stop offset={0.868} stopColor='#fc757c' />
          <stop offset={1} stopColor='#ff6a72' />
        </linearGradient>
        <path
          id='networkarchitecture_svg__XMLID_35_'
          d='M1095.33 155.27c0 .55.39.77.86.49.48-.27.86-.94.86-1.48s-.39-.77-.86-.49c-.47.27-.86.94-.86 1.48z'
          fill='url(#networkarchitecture_svg__XMLID_57_)'
        />
        <linearGradient
          id='networkarchitecture_svg__XMLID_58_'
          gradientUnits='userSpaceOnUse'
          x1={3591.893}
          y1={-354.91}
          x2={3595.742}
          y2={-352.266}
          gradientTransform='matrix(-.866 .5 0 1.1547 4205.217 -1231.902)'
        >
          <stop offset={0.006} stopColor='#55ff94' />
          <stop offset={1} stopColor='#e9fff9' />
        </linearGradient>
        <path
          id='networkarchitecture_svg__XMLID_34_'
          d='M1092.2 157.08c0 .55.39.77.86.49.48-.27.86-.94.86-1.48s-.39-.77-.86-.49c-.47.27-.86.93-.86 1.48z'
          fill='url(#networkarchitecture_svg__XMLID_58_)'
        />
      </g>
      <g id='networkarchitecture_svg__XMLID_24_'>
        <linearGradient
          id='networkarchitecture_svg__XMLID_59_'
          gradientUnits='userSpaceOnUse'
          x1={3576.361}
          y1={-349.848}
          x2={3638.286}
          y2={-309.288}
          gradientTransform='matrix(-.866 .5 0 1.1547 4205.217 -1231.902)'
        >
          <stop offset={0.005} stopColor='#00c6fb' />
          <stop offset={1} stopColor='#005bea' />
        </linearGradient>
        <path
          id='networkarchitecture_svg__XMLID_32_'
          fill='url(#networkarchitecture_svg__XMLID_59_)'
          d='M1077.75 180.69l47.31-27.31v-10.07l-47.31 27.32z'
        />
        <linearGradient
          id='networkarchitecture_svg__XMLID_60_'
          gradientUnits='userSpaceOnUse'
          x1={3601.291}
          y1={-349.193}
          x2={3614.383}
          y2={-340.201}
          gradientTransform='matrix(-.866 .5 0 1.1547 4205.217 -1231.902)'
        >
          <stop offset={0.005} stopColor='#d4fff1' />
          <stop offset={0.322} stopColor='#e5c6c0' />
          <stop offset={0.633} stopColor='#f39496' />
          <stop offset={0.868} stopColor='#fc757c' />
          <stop offset={1} stopColor='#ff6a72' />
        </linearGradient>
        <path
          id='networkarchitecture_svg__XMLID_31_'
          fill='url(#networkarchitecture_svg__XMLID_60_)'
          d='M1079.38 177.63l3.59-2.07v-6.24l-3.59 2.08z'
        />
        <linearGradient
          id='networkarchitecture_svg__XMLID_61_'
          gradientUnits='userSpaceOnUse'
          x1={3595.394}
          y1={-349.191}
          x2={3608.486}
          y2={-340.198}
          gradientTransform='matrix(-.866 .5 0 1.1547 4205.217 -1231.902)'
        >
          <stop offset={0.005} stopColor='#d4fff1' />
          <stop offset={0.322} stopColor='#e5c6c0' />
          <stop offset={0.633} stopColor='#f39496' />
          <stop offset={0.868} stopColor='#fc757c' />
          <stop offset={1} stopColor='#ff6a72' />
        </linearGradient>
        <path
          id='networkarchitecture_svg__XMLID_30_'
          fill='url(#networkarchitecture_svg__XMLID_61_)'
          d='M1084.49 174.68l3.59-2.07v-6.23l-3.59 2.07z'
        />
        <linearGradient
          id='networkarchitecture_svg__XMLID_62_'
          gradientUnits='userSpaceOnUse'
          x1={3552.9}
          y1={-356.275}
          x2={3581.574}
          y2={-336.58}
          gradientTransform='matrix(-.866 .5 0 1.1547 4205.217 -1231.902)'
        >
          <stop offset={0.006} stopColor='#55ff94' />
          <stop offset={1} stopColor='#e9fff9' />
        </linearGradient>
        <path
          id='networkarchitecture_svg__XMLID_29_'
          fill='url(#networkarchitecture_svg__XMLID_62_)'
          d='M1109.69 155.35l14.15-8.17v-1.45l-14.15 8.17z'
        />
        <linearGradient
          id='networkarchitecture_svg__XMLID_63_'
          gradientUnits='userSpaceOnUse'
          x1={3554.029}
          y1={-349.974}
          x2={3575.997}
          y2={-334.885}
          gradientTransform='matrix(-.866 .5 0 1.1547 4205.217 -1231.902)'
        >
          <stop offset={0.006} stopColor='#55ff94' />
          <stop offset={1} stopColor='#e9fff9' />
        </linearGradient>
        <path
          id='networkarchitecture_svg__XMLID_28_'
          fill='url(#networkarchitecture_svg__XMLID_63_)'
          d='M1113.18 158.12l10.66-6.16v-1.45l-10.66 6.16z'
        />
        <linearGradient
          id='networkarchitecture_svg__XMLID_64_'
          gradientUnits='userSpaceOnUse'
          x1={3584.545}
          y1={-343.918}
          x2={3588.394}
          y2={-341.274}
          gradientTransform='matrix(-.866 .5 0 1.1547 4205.217 -1231.902)'
        >
          <stop offset={0.005} stopColor='#d4fff1' />
          <stop offset={0.322} stopColor='#e5c6c0' />
          <stop offset={0.633} stopColor='#f39496' />
          <stop offset={0.868} stopColor='#fc757c' />
          <stop offset={1} stopColor='#ff6a72' />
        </linearGradient>
        <path
          id='networkarchitecture_svg__XMLID_27_'
          d='M1098.57 166.09c0 .55.39.77.86.49.48-.27.86-.94.86-1.48s-.39-.77-.86-.49c-.48.27-.86.94-.86 1.48z'
          fill='url(#networkarchitecture_svg__XMLID_64_)'
        />
        <linearGradient
          id='networkarchitecture_svg__XMLID_65_'
          gradientUnits='userSpaceOnUse'
          x1={3588.281}
          y1={-343.919}
          x2={3592.13}
          y2={-341.276}
          gradientTransform='matrix(-.866 .5 0 1.1547 4205.217 -1231.902)'
        >
          <stop offset={0.006} stopColor='#55ff94' />
          <stop offset={1} stopColor='#e9fff9' />
        </linearGradient>
        <path
          id='networkarchitecture_svg__XMLID_26_'
          d='M1095.33 167.96c0 .55.39.77.86.49.48-.27.86-.94.86-1.48 0-.55-.39-.77-.86-.49-.47.27-.86.94-.86 1.48z'
          fill='url(#networkarchitecture_svg__XMLID_65_)'
        />
        <linearGradient
          id='networkarchitecture_svg__XMLID_66_'
          gradientUnits='userSpaceOnUse'
          x1={3591.894}
          y1={-343.92}
          x2={3595.743}
          y2={-341.276}
          gradientTransform='matrix(-.866 .5 0 1.1547 4205.217 -1231.902)'
        >
          <stop offset={0.006} stopColor='#55ff94' />
          <stop offset={1} stopColor='#e9fff9' />
        </linearGradient>
        <path
          id='networkarchitecture_svg__XMLID_25_'
          d='M1092.2 169.77c0 .55.39.77.86.49.48-.27.86-.94.86-1.48s-.39-.77-.86-.49c-.47.27-.86.93-.86 1.48z'
          fill='url(#networkarchitecture_svg__XMLID_66_)'
        />
      </g>
      <g id='networkarchitecture_svg__XMLID_22_'>
        <linearGradient
          id='networkarchitecture_svg__XMLID_67_'
          gradientUnits='userSpaceOnUse'
          x1={524.021}
          y1={-85.378}
          x2={475.666}
          y2={-199.724}
          gradientTransform='translate(523.46 250)'
        >
          <stop offset={0.005} stopColor='#00c6fb' />
          <stop offset={1} stopColor='#005bea' />
        </linearGradient>
        <path
          id='networkarchitecture_svg__XMLID_23_'
          d='M1056.49 91.02l3.48 52.2a6.875 6.875 0 01-6.97.02l-70.68-41.14c-2-1.16-3.23-3.3-3.23-5.61V48.02l77.4 43z'
          fill='url(#networkarchitecture_svg__XMLID_67_)'
        />
      </g>
      <g id='networkarchitecture_svg__XMLID_20_'>
        <linearGradient
          id='networkarchitecture_svg__XMLID_68_'
          gradientUnits='userSpaceOnUse'
          x1={1041.602}
          y1={-206.331}
          x2={1093.33}
          y2={-153.073}
          gradientTransform='translate(0 250)'
        >
          <stop offset={0.005} stopColor='#00c6fb' />
          <stop offset={1} stopColor='#005bea' />
        </linearGradient>
        <path
          id='networkarchitecture_svg__XMLID_21_'
          d='M1128.99 50.35v48.41c0 2.3-1.21 4.42-3.19 5.59l-65.94 38.95c-1.49.88-3.37-.19-3.37-1.92v-52.5l72.5-38.53z'
          fill='url(#networkarchitecture_svg__XMLID_68_)'
        />
      </g>
      <linearGradient
        id='networkarchitecture_svg__XMLID_69_'
        gradientUnits='userSpaceOnUse'
        x1={429.416}
        y1={-198.677}
        x2={649.129}
        y2={-202.509}
        gradientTransform='translate(523.46 250)'
      >
        <stop offset={0} stopColor='#0396ff' />
        <stop offset={0.167} stopColor='#36aeff' />
        <stop offset={0.385} stopColor='#73caff' />
        <stop offset={0.586} stopColor='#a3e0ff' />
        <stop offset={0.762} stopColor='#c6f1ff' />
        <stop offset={0.906} stopColor='#dbfaff' />
        <stop offset={1} stopColor='#e3feff' />
      </linearGradient>
      <path
        id='networkarchitecture_svg__XMLID_19_'
        d='M1127.92 52.45l-67.23 39.8a9.682 9.682 0 01-9.85.02l-70.26-41.2c-1.99-1.17-1.95-4.06.07-5.17l71.09-39.25a9.704 9.704 0 019.86.28l66.39 41.77c1.37.88 1.34 2.91-.07 3.75z'
        fill='url(#networkarchitecture_svg__XMLID_69_)'
      />
      <linearGradient
        id='networkarchitecture_svg__SVGID_8_'
        gradientUnits='userSpaceOnUse'
        x1={3874.778}
        y1={-134.343}
        x2={3885.766}
        y2={-77.513}
        gradientTransform='matrix(-1 0 0 1 4941.616 250)'
      >
        <stop offset={0.005} stopColor='#00c6fb' />
        <stop offset={1} stopColor='#005bea' />
      </linearGradient>
      <path
        d='M1066.86 134.17l.6-.35c2.14-1.23 3.87-4.54 3.87-7.39v-29.7c0-2.85-1.73-4.16-3.87-2.92l-.6.35c-2.14 1.23-3.87 4.54-3.87 7.39v29.71c0 2.83 1.73 4.14 3.87 2.91z'
        fill='url(#networkarchitecture_svg__SVGID_8_)'
      />
      <g>
        <linearGradient
          id='networkarchitecture_svg__SVGID_9_'
          gradientUnits='userSpaceOnUse'
          x1={3859.216}
          y1={-162.456}
          x2={3881.036}
          y2={-138.513}
          gradientTransform='matrix(-1 0 0 1 4941.616 250)'
        >
          <stop offset={0.006} stopColor='#55ff94' />
          <stop offset={1} stopColor='#e9fff9' />
        </linearGradient>
        <path
          d='M1065.33 105.11c0 1.42 1 1.99 2.24 1.28s2.24-2.45 2.24-3.87-1-1.99-2.24-1.28c-1.24.72-2.24 2.45-2.24 3.87z'
          fill='url(#networkarchitecture_svg__SVGID_9_)'
        />
        <linearGradient
          id='networkarchitecture_svg__SVGID_10_'
          gradientUnits='userSpaceOnUse'
          x1={3859.215}
          y1={-152.129}
          x2={3881.036}
          y2={-128.185}
          gradientTransform='matrix(-1 0 0 1 4941.616 250)'
        >
          <stop offset={0.006} stopColor='#55ff94' />
          <stop offset={1} stopColor='#e9fff9' />
        </linearGradient>
        <path
          d='M1065.33 115.44c0 1.42 1 1.99 2.24 1.28s2.24-2.45 2.24-3.87-1-1.99-2.24-1.28-2.24 2.45-2.24 3.87z'
          fill='url(#networkarchitecture_svg__SVGID_10_)'
        />
        <linearGradient
          id='networkarchitecture_svg__SVGID_11_'
          gradientUnits='userSpaceOnUse'
          x1={3859.218}
          y1={-142.113}
          x2={3881.039}
          y2={-118.169}
          gradientTransform='matrix(-1 0 0 1 4941.616 250)'
        >
          <stop offset={0.005} stopColor='#d4fff1' />
          <stop offset={0.322} stopColor='#e5c6c0' />
          <stop offset={0.633} stopColor='#f39496' />
          <stop offset={0.868} stopColor='#fc757c' />
          <stop offset={1} stopColor='#ff6a72' />
        </linearGradient>
        <path
          d='M1065.33 125.45c0 1.42 1 1.99 2.24 1.28s2.24-2.45 2.24-3.87-1-1.99-2.24-1.28c-1.24.72-2.24 2.45-2.24 3.87z'
          fill='url(#networkarchitecture_svg__SVGID_11_)'
        />
      </g>
      <g id='networkarchitecture_svg__XMLID_10_'>
        <linearGradient
          id='networkarchitecture_svg__XMLID_70_'
          gradientUnits='userSpaceOnUse'
          x1={3577.639}
          y1={-414.343}
          x2={3639.564}
          y2={-373.782}
          gradientTransform='matrix(-.866 .5 0 1.1547 4205.217 -1231.902)'
        >
          <stop offset={0.005} stopColor='#00c6fb' />
          <stop offset={1} stopColor='#005bea' />
        </linearGradient>
        <path
          id='networkarchitecture_svg__XMLID_18_'
          fill='url(#networkarchitecture_svg__XMLID_70_)'
          d='M1076.64 106.86l47.32-27.32V69.48l-47.32 27.32z'
        />
        <linearGradient
          id='networkarchitecture_svg__XMLID_71_'
          gradientUnits='userSpaceOnUse'
          x1={3602.566}
          y1={-413.684}
          x2={3615.658}
          y2={-404.691}
          gradientTransform='matrix(-.866 .5 0 1.1547 4205.217 -1231.902)'
        >
          <stop offset={0.006} stopColor='#55ff94' />
          <stop offset={1} stopColor='#e9fff9' />
        </linearGradient>
        <path
          id='networkarchitecture_svg__XMLID_17_'
          fill='url(#networkarchitecture_svg__XMLID_71_)'
          d='M1078.28 103.8l3.59-2.07v-6.24l-3.59 2.07z'
        />
        <linearGradient
          id='networkarchitecture_svg__XMLID_72_'
          gradientUnits='userSpaceOnUse'
          x1={3596.673}
          y1={-413.687}
          x2={3609.765}
          y2={-404.694}
          gradientTransform='matrix(-.866 .5 0 1.1547 4205.217 -1231.902)'
        >
          <stop offset={0.006} stopColor='#55ff94' />
          <stop offset={1} stopColor='#e9fff9' />
        </linearGradient>
        <path
          id='networkarchitecture_svg__XMLID_16_'
          fill='url(#networkarchitecture_svg__XMLID_72_)'
          d='M1083.38 100.85l3.59-2.07v-6.23l-3.59 2.07z'
        />
        <linearGradient
          id='networkarchitecture_svg__XMLID_73_'
          gradientUnits='userSpaceOnUse'
          x1={3551.848}
          y1={-425.209}
          x2={3594.346}
          y2={-396.018}
          gradientTransform='matrix(-.866 .5 0 1.1547 4205.217 -1231.902)'
        >
          <stop offset={0.006} stopColor='#55ff94' />
          <stop offset={1} stopColor='#e9fff9' />
        </linearGradient>
        <path
          id='networkarchitecture_svg__XMLID_15_'
          fill='url(#networkarchitecture_svg__XMLID_73_)'
          d='M1101.41 85.66l21.33-12.31V71.9l-21.33 12.31z'
        />
        <linearGradient
          id='networkarchitecture_svg__XMLID_74_'
          gradientUnits='userSpaceOnUse'
          x1={3553.235}
          y1={-418.421}
          x2={3587.503}
          y2={-394.883}
          gradientTransform='matrix(-.866 .5 0 1.1547 4205.217 -1231.902)'
        >
          <stop offset={0.006} stopColor='#55ff94' />
          <stop offset={1} stopColor='#e9fff9' />
        </linearGradient>
        <path
          id='networkarchitecture_svg__XMLID_14_'
          fill='url(#networkarchitecture_svg__XMLID_74_)'
          d='M1105.68 87.98l17.06-9.85v-1.45l-17.06 9.85z'
        />
        <linearGradient
          id='networkarchitecture_svg__XMLID_75_'
          gradientUnits='userSpaceOnUse'
          x1={3585.823}
          y1={-408.414}
          x2={3589.672}
          y2={-405.77}
          gradientTransform='matrix(-.866 .5 0 1.1547 4205.217 -1231.902)'
        >
          <stop offset={0.006} stopColor='#55ff94' />
          <stop offset={1} stopColor='#e9fff9' />
        </linearGradient>
        <path
          id='networkarchitecture_svg__XMLID_13_'
          d='M1097.46 92.26c0 .55.39.77.86.49s.86-.94.86-1.48c0-.55-.39-.77-.86-.49s-.86.94-.86 1.48z'
          fill='url(#networkarchitecture_svg__XMLID_75_)'
        />
        <linearGradient
          id='networkarchitecture_svg__XMLID_76_'
          gradientUnits='userSpaceOnUse'
          x1={3589.555}
          y1={-408.408}
          x2={3593.404}
          y2={-405.764}
          gradientTransform='matrix(-.866 .5 0 1.1547 4205.217 -1231.902)'
        >
          <stop offset={0.005} stopColor='#d4fff1' />
          <stop offset={0.322} stopColor='#e5c6c0' />
          <stop offset={0.633} stopColor='#f39496' />
          <stop offset={0.868} stopColor='#fc757c' />
          <stop offset={1} stopColor='#ff6a72' />
        </linearGradient>
        <path
          id='networkarchitecture_svg__XMLID_12_'
          d='M1094.23 94.13c0 .55.39.77.86.49.48-.27.86-.94.86-1.48 0-.55-.39-.77-.86-.49-.48.27-.86.94-.86 1.48z'
          fill='url(#networkarchitecture_svg__XMLID_76_)'
        />
        <linearGradient
          id='networkarchitecture_svg__XMLID_77_'
          gradientUnits='userSpaceOnUse'
          x1={3593.168}
          y1={-408.409}
          x2={3597.017}
          y2={-405.765}
          gradientTransform='matrix(-.866 .5 0 1.1547 4205.217 -1231.902)'
        >
          <stop offset={0.006} stopColor='#55ff94' />
          <stop offset={1} stopColor='#e9fff9' />
        </linearGradient>
        <path
          id='networkarchitecture_svg__XMLID_11_'
          d='M1091.1 95.94c0 .55.39.77.86.49.48-.27.86-.94.86-1.48 0-.55-.39-.77-.86-.49-.48.27-.86.93-.86 1.48z'
          fill='url(#networkarchitecture_svg__XMLID_77_)'
        />
      </g>
      <g id='networkarchitecture_svg__XMLID_1_'>
        <linearGradient
          id='networkarchitecture_svg__XMLID_78_'
          gradientUnits='userSpaceOnUse'
          x1={3577.637}
          y1={-403.35}
          x2={3639.562}
          y2={-362.789}
          gradientTransform='matrix(-.866 .5 0 1.1547 4205.217 -1231.902)'
        >
          <stop offset={0.005} stopColor='#00c6fb' />
          <stop offset={1} stopColor='#005bea' />
        </linearGradient>
        <path
          id='networkarchitecture_svg__XMLID_9_'
          fill='url(#networkarchitecture_svg__XMLID_78_)'
          d='M1076.64 119.55l47.32-27.31V82.17l-47.32 27.32z'
        />
        <linearGradient
          id='networkarchitecture_svg__XMLID_79_'
          gradientUnits='userSpaceOnUse'
          x1={3602.564}
          y1={-402.69}
          x2={3615.656}
          y2={-393.698}
          gradientTransform='matrix(-.866 .5 0 1.1547 4205.217 -1231.902)'
        >
          <stop offset={0.005} stopColor='#d4fff1' />
          <stop offset={0.322} stopColor='#e5c6c0' />
          <stop offset={0.633} stopColor='#f39496' />
          <stop offset={0.868} stopColor='#fc757c' />
          <stop offset={1} stopColor='#ff6a72' />
        </linearGradient>
        <path
          id='networkarchitecture_svg__XMLID_8_'
          fill='url(#networkarchitecture_svg__XMLID_79_)'
          d='M1078.28 116.49l3.59-2.07v-6.24l-3.59 2.08z'
        />
        <linearGradient
          id='networkarchitecture_svg__XMLID_80_'
          gradientUnits='userSpaceOnUse'
          x1={3596.674}
          y1={-402.696}
          x2={3609.765}
          y2={-393.704}
          gradientTransform='matrix(-.866 .5 0 1.1547 4205.217 -1231.902)'
        >
          <stop offset={0.005} stopColor='#d4fff1' />
          <stop offset={0.322} stopColor='#e5c6c0' />
          <stop offset={0.633} stopColor='#f39496' />
          <stop offset={0.868} stopColor='#fc757c' />
          <stop offset={1} stopColor='#ff6a72' />
        </linearGradient>
        <path
          id='networkarchitecture_svg__XMLID_7_'
          fill='url(#networkarchitecture_svg__XMLID_80_)'
          d='M1083.38 113.54l3.59-2.07v-6.23l-3.59 2.07z'
        />
        <linearGradient
          id='networkarchitecture_svg__XMLID_81_'
          gradientUnits='userSpaceOnUse'
          x1={3554.173}
          y1={-409.772}
          x2={3582.847}
          y2={-390.076}
          gradientTransform='matrix(-.866 .5 0 1.1547 4205.217 -1231.902)'
        >
          <stop offset={0.006} stopColor='#55ff94' />
          <stop offset={1} stopColor='#e9fff9' />
        </linearGradient>
        <path
          id='networkarchitecture_svg__XMLID_6_'
          fill='url(#networkarchitecture_svg__XMLID_81_)'
          d='M1108.59 94.21l14.15-8.17v-1.45l-14.15 8.17z'
        />
        <linearGradient
          id='networkarchitecture_svg__XMLID_82_'
          gradientUnits='userSpaceOnUse'
          x1={3555.305}
          y1={-403.475}
          x2={3577.273}
          y2={-388.386}
          gradientTransform='matrix(-.866 .5 0 1.1547 4205.217 -1231.902)'
        >
          <stop offset={0.006} stopColor='#55ff94' />
          <stop offset={1} stopColor='#e9fff9' />
        </linearGradient>
        <path
          id='networkarchitecture_svg__XMLID_5_'
          fill='url(#networkarchitecture_svg__XMLID_82_)'
          d='M1112.07 96.98l10.67-6.16v-1.45l-10.67 6.16z'
        />
        <linearGradient
          id='networkarchitecture_svg__XMLID_83_'
          gradientUnits='userSpaceOnUse'
          x1={3585.823}
          y1={-397.422}
          x2={3589.672}
          y2={-394.778}
          gradientTransform='matrix(-.866 .5 0 1.1547 4205.217 -1231.902)'
        >
          <stop offset={0.005} stopColor='#d4fff1' />
          <stop offset={0.322} stopColor='#e5c6c0' />
          <stop offset={0.633} stopColor='#f39496' />
          <stop offset={0.868} stopColor='#fc757c' />
          <stop offset={1} stopColor='#ff6a72' />
        </linearGradient>
        <path
          id='networkarchitecture_svg__XMLID_4_'
          d='M1097.46 104.95c0 .55.39.77.86.49.48-.27.86-.94.86-1.48 0-.55-.39-.77-.86-.49-.47.27-.86.94-.86 1.48z'
          fill='url(#networkarchitecture_svg__XMLID_83_)'
        />
        <linearGradient
          id='networkarchitecture_svg__XMLID_84_'
          gradientUnits='userSpaceOnUse'
          x1={3589.556}
          y1={-397.418}
          x2={3593.405}
          y2={-394.774}
          gradientTransform='matrix(-.866 .5 0 1.1547 4205.217 -1231.902)'
        >
          <stop offset={0.006} stopColor='#55ff94' />
          <stop offset={1} stopColor='#e9fff9' />
        </linearGradient>
        <path
          id='networkarchitecture_svg__XMLID_3_'
          d='M1094.23 106.82c0 .55.39.77.86.49.48-.27.86-.94.86-1.48 0-.55-.39-.77-.86-.49-.48.27-.86.94-.86 1.48z'
          fill='url(#networkarchitecture_svg__XMLID_84_)'
        />
        <linearGradient
          id='networkarchitecture_svg__XMLID_85_'
          gradientUnits='userSpaceOnUse'
          x1={3593.168}
          y1={-397.418}
          x2={3597.017}
          y2={-394.774}
          gradientTransform='matrix(-.866 .5 0 1.1547 4205.217 -1231.902)'
        >
          <stop offset={0.006} stopColor='#55ff94' />
          <stop offset={1} stopColor='#e9fff9' />
        </linearGradient>
        <path
          id='networkarchitecture_svg__XMLID_2_'
          d='M1091.1 108.63c0 .55.39.77.86.49.48-.27.86-.94.86-1.48 0-.55-.39-.77-.86-.49-.48.27-.86.93-.86 1.48z'
          fill='url(#networkarchitecture_svg__XMLID_85_)'
        />
      </g>
      <linearGradient
        id='networkarchitecture_svg__SVGID_12_'
        gradientUnits='userSpaceOnUse'
        x1={1026.517}
        y1={-108.728}
        x2={1065.868}
        y2={-13.377}
        gradientTransform='translate(0 250)'
      >
        <stop offset={0.005} stopColor='#00c6fb' />
        <stop offset={1} stopColor='#005bea' />
      </linearGradient>
      <path
        d='M1037.19 134.04l-45.5-26.45V94.51c0-7.44 4.52-10.85 10.1-7.63l25.3 14.61c5.58 3.22 10.1 11.86 10.1 19.29v13.26z'
        fill='url(#networkarchitecture_svg__SVGID_12_)'
      />
      <g>
        <linearGradient
          id='networkarchitecture_svg__SVGID_13_'
          gradientUnits='userSpaceOnUse'
          x1={3113.427}
          y1={-1334.563}
          x2={3134.892}
          y2={-1311.008}
          gradientTransform='matrix(-.433 1.0833 .75 .433 3349.05 -2717.12)'
        >
          <stop offset={0.006} stopColor='#55ff94' />
          <stop offset={1} stopColor='#e9fff9' />
        </linearGradient>
        <path
          d='M1005.71 103.13c1.24.71 2.24.14 2.24-1.28s-1-3.15-2.24-3.87c-1.24-.71-2.24-.14-2.24 1.28s1.01 3.15 2.24 3.87z'
          fill='url(#networkarchitecture_svg__SVGID_13_)'
        />
        <linearGradient
          id='networkarchitecture_svg__SVGID_14_'
          gradientUnits='userSpaceOnUse'
          x1={3113.427}
          y1={-1322.564}
          x2={3134.893}
          y2={-1299.009}
          gradientTransform='matrix(-.433 1.0833 .75 .433 3349.05 -2717.12)'
        >
          <stop offset={0.006} stopColor='#55ff94' />
          <stop offset={1} stopColor='#e9fff9' />
        </linearGradient>
        <path
          d='M1014.71 108.33c1.24.71 2.24.14 2.24-1.28s-1-3.15-2.24-3.87c-1.24-.71-2.24-.14-2.24 1.28s1.01 3.15 2.24 3.87z'
          fill='url(#networkarchitecture_svg__SVGID_14_)'
        />
        <linearGradient
          id='networkarchitecture_svg__SVGID_15_'
          gradientUnits='userSpaceOnUse'
          x1={3113.421}
          y1={-1310.916}
          x2={3134.887}
          y2={-1287.362}
          gradientTransform='matrix(-.433 1.0833 .75 .433 3349.05 -2717.12)'
        >
          <stop offset={0.005} stopColor='#d4fff1' />
          <stop offset={0.322} stopColor='#e5c6c0' />
          <stop offset={0.633} stopColor='#f39496' />
          <stop offset={0.868} stopColor='#fc757c' />
          <stop offset={1} stopColor='#ff6a72' />
        </linearGradient>
        <path
          d='M1023.45 113.37c1.24.71 2.24.14 2.24-1.28s-1-3.15-2.24-3.87c-1.24-.71-2.24-.14-2.24 1.28s1 3.15 2.24 3.87z'
          fill='url(#networkarchitecture_svg__SVGID_15_)'
        />
      </g>
    </g>
    <path className='networkarchitecture_svg__st72' d='M706.18 177.01v14.33' />
    <g>
      <path
        d='M689.01 169.38c-.88 0-1.73-.45-2.2-1.26-.71-1.22-.3-2.78.91-3.49l95.51-55.99c1.22-.71 2.78-.31 3.49.91.71 1.22.3 2.78-.91 3.49l-95.51 55.99c-.4.24-.85.35-1.29.35z'
        fill='#e2feff'
      />
      <linearGradient
        id='networkarchitecture_svg__SVGID_16_'
        gradientUnits='userSpaceOnUse'
        x1={630.835}
        y1={-86.18}
        x2={770.846}
        y2={-86.18}
        gradientTransform='translate(0 250)'
      >
        <stop offset={0.005} stopColor='#00c6fb' />
        <stop offset={1} stopColor='#005bea' />
      </linearGradient>
      <path fill='url(#networkarchitecture_svg__SVGID_16_)' d='M728.45 205.45l-80.01-46.2.02-37.06 80.02 46.19z' />
      <linearGradient
        id='networkarchitecture_svg__SVGID_17_'
        gradientUnits='userSpaceOnUse'
        x1={712.176}
        y1={-86.18}
        x2={856.482}
        y2={-86.18}
        gradientTransform='translate(0 250)'
      >
        <stop offset={0.005} stopColor='#00c6fb' />
        <stop offset={1} stopColor='#005bea' />
      </linearGradient>
      <path fill='url(#networkarchitecture_svg__SVGID_17_)' d='M728.45 205.45l80.02-46.2.02-37.06-80.01 46.19z' />
      <linearGradient
        id='networkarchitecture_svg__SVGID_18_'
        gradientUnits='userSpaceOnUse'
        x1={587.163}
        y1={-127.815}
        x2={747.193}
        y2={-127.815}
        gradientTransform='translate(0 250)'
      >
        <stop offset={0.005} stopColor='#00c6fb' />
        <stop offset={1} stopColor='#005bea' />
      </linearGradient>
      <path fill='url(#networkarchitecture_svg__SVGID_18_)' d='M728.48 168.38l-80.02-46.19 80.02-46.2 80.01 46.2z' />
      <linearGradient
        id='networkarchitecture_svg__SVGID_19_'
        gradientUnits='userSpaceOnUse'
        x1={622.552}
        y1={-227.326}
        x2={746.118}
        y2={-49.637}
        gradientTransform='translate(0 250)'
      >
        <stop offset={0} stopColor='#0396ff' />
        <stop offset={0.1} stopColor='#109cff' />
        <stop offset={0.285} stopColor='#33acff' />
        <stop offset={0.536} stopColor='#6bc6ff' />
        <stop offset={0.838} stopColor='#b7eaff' />
        <stop offset={1} stopColor='#e3feff' />
      </linearGradient>
      <path fill='url(#networkarchitecture_svg__SVGID_19_)' d='M728.46 159.09l-80.01-46.19.03-37.07 80.01 46.2z' />
      <linearGradient
        id='networkarchitecture_svg__SVGID_20_'
        gradientUnits='userSpaceOnUse'
        x1={729.034}
        y1={-162.003}
        x2={894.469}
        y2={-38.437}
        gradientTransform='translate(0 250)'
      >
        <stop offset={0} stopColor='#0396ff' />
        <stop offset={0.167} stopColor='#36aeff' />
        <stop offset={0.385} stopColor='#73caff' />
        <stop offset={0.586} stopColor='#a3e0ff' />
        <stop offset={0.762} stopColor='#c6f1ff' />
        <stop offset={0.906} stopColor='#dbfaff' />
        <stop offset={1} stopColor='#e3feff' />
      </linearGradient>
      <path fill='url(#networkarchitecture_svg__SVGID_20_)' d='M728.46 159.09l80.02-46.19.02-37.07-80.01 46.2z' />
      <linearGradient
        id='networkarchitecture_svg__SVGID_21_'
        gradientUnits='userSpaceOnUse'
        x1={683.023}
        y1={-213.155}
        x2={850.16}
        y2={-69.846}
        gradientTransform='translate(0 250)'
      >
        <stop offset={0} stopColor='#0396ff' />
        <stop offset={0.167} stopColor='#36aeff' />
        <stop offset={0.385} stopColor='#73caff' />
        <stop offset={0.586} stopColor='#a3e0ff' />
        <stop offset={0.762} stopColor='#c6f1ff' />
        <stop offset={0.906} stopColor='#dbfaff' />
        <stop offset={1} stopColor='#e3feff' />
      </linearGradient>
      <path fill='url(#networkarchitecture_svg__SVGID_21_)' d='M728.49 122.03l-80.01-46.2 80.01-46.19 80.01 46.19z' />
      <path
        className='networkarchitecture_svg__st72'
        d='M743.7 150.29v-9.45c0-2.6 1.38-5 3.62-6.3l23.5-13.69a9.346 9.346 0 004.64-8.07V94.91M793.49 147.71l-.11-7.65c0-.8-.87-1.3-1.56-.9l-26.38 15.2c-.77.44-1.72-.11-1.72-1v-2.9c0-1.48-.86-2.83-2.2-3.45l-6.72-3.12'
      />
      <linearGradient
        id='networkarchitecture_svg__SVGID_22_'
        gradientUnits='userSpaceOnUse'
        x1={3243.668}
        y1={-100.98}
        x2={3362.55}
        y2={-100.98}
        gradientTransform='matrix(-1 0 0 1 4299.492 250)'
      >
        <stop offset={0.005} stopColor='#d4fff1' />
        <stop offset={0.322} stopColor='#e5c6c0' />
        <stop offset={0.633} stopColor='#f39496' />
        <stop offset={0.868} stopColor='#fc757c' />
        <stop offset={1} stopColor='#ff6a72' />
      </linearGradient>
      <path fill='url(#networkarchitecture_svg__SVGID_22_)' d='M787.83 155.35l11.33-6.54v-6.12l-11.33 6.55z' />
      <path className='networkarchitecture_svg__st72' d='M661.27 147.48v-15.72c0-1.64.9-3.15 2.34-3.93l5.96-3.23' />
      <linearGradient
        id='networkarchitecture_svg__SVGID_23_'
        gradientUnits='userSpaceOnUse'
        x1={821.161}
        y1={-100.34}
        x2={940.043}
        y2={-100.34}
        gradientTransform='translate(-165.56 250)'
      >
        <stop offset={0.006} stopColor='#55ff94' />
        <stop offset={1} stopColor='#e9fff9' />
      </linearGradient>
      <path fill='url(#networkarchitecture_svg__SVGID_23_)' d='M666.94 155.99l-11.34-6.54v-6.12l11.34 6.54z' />
      <path className='networkarchitecture_svg__st72' d='M708.18 178.86v15.18' />
      <linearGradient
        id='networkarchitecture_svg__SVGID_24_'
        gradientUnits='userSpaceOnUse'
        x1={868.071}
        y1={-73.38}
        x2={986.953}
        y2={-73.38}
        gradientTransform='translate(-165.56 250)'
      >
        <stop offset={0.006} stopColor='#55ff94' />
        <stop offset={1} stopColor='#e9fff9' />
      </linearGradient>
      <path fill='url(#networkarchitecture_svg__SVGID_24_)' d='M713.84 182.95l-11.33-6.55v-6.11l11.33 6.54z' />
      <linearGradient
        id='networkarchitecture_svg__SVGID_25_'
        gradientUnits='userSpaceOnUse'
        x1={691.99}
        y1={-205.466}
        x2={826.166}
        y2={-90.42}
        gradientTransform='translate(0 250)'
      >
        <stop offset={0.006} stopColor='#55ff94' />
        <stop offset={1} stopColor='#e9fff9' />
      </linearGradient>
      <path fill='url(#networkarchitecture_svg__SVGID_25_)' d='M728.49 112.92l-64.23-37.09 64.23-37.08 64.23 37.08z' />
    </g>
    <g>
      <linearGradient
        id='networkarchitecture_svg__SVGID_26_'
        gradientUnits='userSpaceOnUse'
        x1={418.62}
        y1={-124.53}
        x2={418.62}
        y2={-174.528}
        gradientTransform='translate(0 250)'
      >
        <stop offset={0} stopColor='#0396ff' />
        <stop offset={0.1} stopColor='#109cff' />
        <stop offset={0.285} stopColor='#33acff' />
        <stop offset={0.536} stopColor='#6bc6ff' />
        <stop offset={0.838} stopColor='#b7eaff' />
        <stop offset={1} stopColor='#e3feff' />
      </linearGradient>
      <path
        d='M386.8 88.65a2.698 2.698 0 000 3.81 2.698 2.698 0 003.81 0c15.47-15.47 40.55-15.47 56.02 0 1.05 1.05 2.76 1.05 3.81 0s1.05-2.76 0-3.81c-17.57-17.57-46.07-17.57-63.64 0zm52.11 8.46c-11.42-11.42-29.92-11.42-41.34 0a2.698 2.698 0 000 3.81 2.698 2.698 0 003.81 0c9.31-9.31 24.41-9.31 33.72 0a2.698 2.698 0 003.81 0 2.698 2.698 0 000-3.81zm-9.59 8.3c-6.23-6.23-16.34-6.23-22.58 0a2.698 2.698 0 000 3.81 2.698 2.698 0 003.81 0c4.13-4.13 10.83-4.13 14.96 0 1.05 1.05 2.76 1.05 3.81 0s1.05-2.76 0-3.81zm-5.62 14.29c0-3.19-2.58-5.77-5.77-5.77s-5.77 2.58-5.77 5.77 2.58 5.77 5.77 5.77c3.19 0 5.77-2.58 5.77-5.77z'
        fill='url(#networkarchitecture_svg__SVGID_26_)'
      />
    </g>
  </svg>
)

export default SvgNetworkarchitecture
